import ThinkingNode from "./ThinkingNode";

// 左上角ICON为消息的节点
class MindMapNode extends ThinkingNode.view {
  getImageHref() {
    return require("@/assets/tools/12思维导图.png");
  }
}

export default {
  type: "icon-mindMap",
  view: MindMapNode,
  model: ThinkingNode.model,
};
