<template>
  <div style="width: 100%; height: 100%">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  watch: {},
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      document.domain = "cocorobo.cn";
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      document.domain = "cocorobo.hk";
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      document.domain = "cocorobo.com";
    }
  },
  methods: {},
};
</script>

<style>
html,
body {
  height: 100%;
  transform-origin: 0 0;
}

body {
  padding: 0;
  margin: 0;
}

.bodyClass {
  width: 1920px;
  height: 937px;
}

#app {
  height: 100%;
}
</style>
