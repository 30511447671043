import EvaluationNode from "./EvaluationNode";

// 左上角ICON为消息的节点
class ChoiceQuestionNode extends EvaluationNode.view {
  getImageHref() {
    return require("@/assets/tools/10问卷调查.png");
  }
}

export default {
  type: "icon-choice-question",
  view: ChoiceQuestionNode,
  model: EvaluationNode.model,
};
