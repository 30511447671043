import ThinkingNode from "./ThinkingNode";

// 左上角ICON为消息的节点
class NoteNode extends ThinkingNode.view {
  getImageHref() {
    return require("@/assets/tools/11便签分类.png");
  }
}

export default {
  type: "icon-note",
  view: NoteNode,
  model: ThinkingNode.model,
};
