import { v4 as uuidv4 } from "uuid";
import store from "../store";
let timeout = null;
function add_8h(time) {
  let new_time = new Date(time).getTime();
  return formatDateTime(new_time);
}
//时间戳转时间格式
function formatDateTime(timestamp) {
  const date = new Date(timestamp); // 根据时间戳创建Date对象
  const year = date.getFullYear(); // 获取年份
  const month = date.getMonth() + 1; // 获取月份，需要加1
  const day = date.getDate(); // 获取日期
  const hour = date.getHours(); // 获取小时
  const minute = date.getMinutes(); // 获取分钟
  const second = date.getSeconds(); // 获取秒数
  const formattedDate = `${year}-${handlerZero(month)}-${handlerZero(
    day
  )} ${handlerZero(hour)}:${handlerZero(minute)}:${handlerZero(second)}`; // 拼接成格式化后的日期字符串
  return formattedDate;
}
//时间戳转时间格式
function formatDateTime2(timestamp) {
  const date = new Date(timestamp); // 根据时间戳创建Date对象
  const year = date.getFullYear(); // 获取年份
  const month = date.getMonth() + 1; // 获取月份，需要加1
  const day = date.getDate(); // 获取日期
  const formattedDate = `${year}-${handlerZero(month)}-${handlerZero(day)}`; // 拼接成格式化后的日期字符串
  return formattedDate;
}
function getFirstLetter(str) {
  var unicode = str.charCodeAt(0);
  if (unicode >= 19968 && unicode <= 40869) {
    var index = parseInt((unicode - 19968) / 408) + 1;
    return String.fromCharCode(64 + index);
  } else {
    return str.charAt(0);
  }
}
//防抖
function debounce(fn, wait) {
  if (timeout !== null) clearTimeout(timeout);
  timeout = setTimeout(fn, wait);
}
function handlerZero(param) {
  return (param = param < 10 ? "0" + param : param);
}
//监听localstroge
function dispatchEventStroage() {
  const signSetItem = localStorage.setItem;
  localStorage.setItem = function (key, val) {
    let setEvent = new Event("setItemEvent");
    setEvent.key = key;
    setEvent.newValue = val;
    window.dispatchEvent(setEvent);
    signSetItem.apply(this, arguments);
  };
}
function init_map(id, lat, lng, map_type) {
  var map = new TMap.Map(id, {
    center: new TMap.LatLng(lat, lng), //设置地图中心点坐标
    zoom: 11, //设置地图缩放级别
    pitch: 0, //设置俯仰角度（0~45）
    viewMode: "2D",
  });
  //创建并初始化MultiMarker
  var markerLayer = new TMap.MultiMarker({
    map: map, //指定地图容器
    //样式定义
    styles: {
      //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
      myStyle: new TMap.MarkerStyle({
        width: 25, // 点标记样式宽度（像素）
        height: 35, // 点标记样式高度（像素）
        src: "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/marker.png", //图片路径
        //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
        anchor: {
          x: 16,
          y: 32,
        },
      }),
    },
    //点标记数据数组
    geometries: [
      {
        id: id, //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
        styleId: "myStyle", //指定样式id
        position: new TMap.LatLng(lat, lng), //点标记坐标位置
        properties: {
          //自定义属性
          title: "我的位置",
        },
      },
    ],
  });
  map.setBaseMap(map_type);
  map.on("tilesloaded", function () {
    store.commit("changeRemap", { map: map, markerLayer: markerLayer });
  });
}
function reset_map(id, map, markerLayer, lat, lng) {
  map.setCenter(new TMap.LatLng(lat, lng));
  markerLayer.updateGeometries([
    {
      styleId: "myStyle",
      id: id,
      position: new TMap.LatLng(lat, lng),
    },
  ]);
}
//去掉字符串的html标签
function removeHTMLTags(text) {
  if (text) {
    return text.replace(/<[^>]+>/g, "");
  }
}

function find_max_x(data) {
  let max = data.reduce((prev, curr) => {
    return prev.x > curr.x ? prev : curr;
  });
  return max;
}

function find_min_x(data) {
  let min = data.reduce((prev, curr) => {
    return prev.x < curr.x ? prev : curr;
  });
  return min;
}

function find_max_y(data) {
  let max = data.reduce((prev, curr) => {
    return prev.y > curr.y ? prev : curr;
  });
  return max;
}

function find_min_y(data) {
  let min = data.reduce((prev, curr) => {
    return prev.y < curr.y ? prev : curr;
  });
  return min;
}

function replace_all_x(data, replace_x, min_node_x, a) {
  let nodes = data.nodes;
  let edges = data.edges;
  for (let i = 0; i < nodes.length; i++) {
    nodes[i].x = replace_x - min_node_x + nodes[i].x + a;
  }
  for (let i = 0; i < edges.length; i++) {
    edges[i].startPoint.x = replace_x - min_node_x + edges[i].startPoint.x + a;
    edges[i].endPoint.x = replace_x - min_node_x + edges[i].endPoint.x + a;
    if (edges[i].pointsList) {
      for (let j = 0; j < edges[i].pointsList.length; j++) {
        edges[i].pointsList[j].x =
          replace_x - min_node_x + edges[i].pointsList[j].x + a;
      }
    }
  }
  let newData = {};
  newData.nodes = nodes;
  newData.edges = edges;
  newData.backgroundUrl = data.backgroundUrl;
  return newData;
}

function replace_all_y(data, replace_y, min_node_y, a) {
  let nodes = data.nodes;
  let edges = data.edges;
  for (let i = 0; i < nodes.length; i++) {
    nodes[i].y = replace_y - min_node_y + nodes[i].y + a;
  }
  for (let i = 0; i < edges.length; i++) {
    edges[i].startPoint.y = replace_y - min_node_y + edges[i].startPoint.y + a;
    edges[i].endPoint.y = replace_y - min_node_y + edges[i].endPoint.y + a;
    if (edges[i].pointsList) {
      for (let j = 0; j < edges[i].pointsList.length; j++) {
        edges[i].pointsList[j].y =
          replace_y - min_node_y + edges[i].pointsList[j].y + a;
      }
    }
  }
  let newData = {};
  newData.nodes = nodes;
  newData.edges = edges;
  newData.backgroundUrl = data.backgroundUrl;
  return newData;
}

function replaceAllCardId(data) {
  let nodes = data.nodes;
  let edges = data.edges;
  let oldCardIds = [];
  let newCardIds = [];
  for (let i = 0; i < nodes.length; i++) {
    oldCardIds.push(nodes[i].id);
    nodes[i].id = uuidv4();
    newCardIds.push(nodes[i].id);
  }
  for (let i = 0; i < edges.length; i++) {
    edges[i].id = uuidv4();
    for (let j = 0; j < oldCardIds.length; j++) {
      if (edges[i].sourceNodeId == oldCardIds[j]) {
        edges[i].sourceNodeId = newCardIds[j];
      }
      if (edges[i].targetNodeId == oldCardIds[j]) {
        edges[i].targetNodeId = newCardIds[j];
      }
    }
  }
  let newData = {};
  newData.nodes = nodes;
  newData.edges = edges;
  newData.backgroundUrl = data.backgroundUrl;
  return newData;
}

/*
  生成树状结构json
*/
function convertToTreeData(data, pid) {
  const result = [];
  let temp = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].sourceNodeId === pid) {
      const obj = data[i].targetNode;
      temp = convertToTreeData(data, data[i].targetNodeId);
      if (temp.length > 0) {
        obj.children = temp;
      }
      result.push(obj);
    }
  }
  return result;
}

/*
  递归生成所需树状结构数据
*/
function deepFilter(list, ids, lastIds) {
  console.log(list, ids, lastIds);
  // 使用filter 过滤当前层的数组
  return list.filter((item) => {
    // filter其实也是遍历
    // 把当前遍历的节点的children 也调用一次 deepFilter 函数，返回过滤后的数组重新赋值
    if (lastIds.indexOf(item.id) != -1) {
      item.children = [];
      // 最后判断当前节点是否符合过滤要求
    } else {
      item.children = deepFilter(item.children, ids, lastIds);
      // 最后判断当前节点是否符合过滤要求
    }
    return ids.indexOf(item.id) != -1;
  });
}

var EleResize = {
  _handleResize: function (e) {
    var ele = e.target || e.srcElement;
    var trigger = ele.__resizeTrigger__;
    if (trigger) {
      var handlers = trigger.__z_resizeListeners;
      if (handlers) {
        var size = handlers.length;
        for (var i = 0; i < size; i++) {
          var h = handlers[i];
          var handler = h.handler;
          var context = h.context;
          handler.apply(context, [e]);
        }
      }
    }
  },
  _removeHandler: function (ele, handler, context) {
    var handlers = ele.__z_resizeListeners;
    if (handlers) {
      var size = handlers.length;
      for (var i = 0; i < size; i++) {
        var h = handlers[i];
        if (h.handler === handler && h.context === context) {
          handlers.splice(i, 1);
          return;
        }
      }
    }
  },
  _createResizeTrigger: function (ele) {
    var obj = document.createElement("object");
    obj.setAttribute(
      "style",
      "display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden;opacity: 0; pointer-events: none; z-index: -1;"
    );
    obj.onload = EleResize._handleObjectLoad;
    obj.type = "text/html";
    ele.appendChild(obj);
    obj.data = "about:blank";
    return obj;
  },
  _handleObjectLoad: function () {
    this.contentDocument.defaultView.__resizeTrigger__ = this.__resizeElement__;
    this.contentDocument.defaultView.addEventListener(
      "resize",
      EleResize._handleResize
    );
  },
};
if (document.attachEvent) {
  // ie9-10
  EleResize.on = function (ele, handler, context) {
    var handlers = ele.__z_resizeListeners;
    if (!handlers) {
      handlers = [];
      ele.__z_resizeListeners = handlers;
      ele.__resizeTrigger__ = ele;
      ele.attachEvent("onresize", EleResize._handleResize);
    }
    handlers.push({
      handler: handler,
      context: context,
    });
  };
  EleResize.off = function (ele, handler, context) {
    var handlers = ele.__z_resizeListeners;
    if (handlers) {
      EleResize._removeHandler(ele, handler, context);
      if (handlers.length === 0) {
        ele.detachEvent("onresize", EleResize._handleResize);
        delete ele.__z_resizeListeners;
      }
    }
  };
} else {
  EleResize.on = function (ele, handler, context) {
    var handlers = ele.__z_resizeListeners;
    if (!handlers) {
      handlers = [];
      ele.__z_resizeListeners = handlers;

      if (getComputedStyle(ele, null).position === "static") {
        ele.style.position = "relative";
      }
      var obj = EleResize._createResizeTrigger(ele);
      ele.__resizeTrigger__ = obj;
      obj.__resizeElement__ = ele;
    }
    handlers.push({
      handler: handler,
      context: context,
    });
  };
  EleResize.off = function (ele, handler, context) {
    var handlers = ele.__z_resizeListeners;
    if (handlers) {
      EleResize._removeHandler(ele, handler, context);
      if (handlers.length === 0) {
        var trigger = ele.__resizeTrigger__;
        if (trigger) {
          trigger.contentDocument.defaultView.removeEventListener(
            "resize",
            EleResize._handleResize
          );
          ele.removeChild(trigger);
          delete ele.__resizeTrigger__;
        }
        delete ele.__z_resizeListeners;
      }
    }
  };
}
export {
  debounce,
  dispatchEventStroage,
  init_map,
  reset_map,
  removeHTMLTags,
  find_max_x,
  find_min_x,
  replace_all_x,
  find_max_y,
  find_min_y,
  replace_all_y,
  replaceAllCardId,
  EleResize,
  add_8h,
  formatDateTime,
  formatDateTime2,
  getFirstLetter,
  deepFilter,
  convertToTreeData
};
