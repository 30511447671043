import axios from "axios";

export function sendUpdate(newNodes, newEdges, str, type) {
  if (
    localStorage.getItem("network") == "true" ||
    localStorage.getItem("network") == true
  ) {
    let mindinfo = {
      "us.cscl": [
        {
          sendId:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
              : window.parent.US.userInfo.userid, //发送人id
          receiveId: localStorage.getItem("roomId"), //文件id
          pageId:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "d7300d31-8cf6-38dc-e295-2c71622b4df1"
              : window.parent.US.pageId,
          type: str, //消息类型
          messageInfo: {
            nodes: newNodes,
            edges: newEdges,
            backgroundUrl: localStorage.getItem("background"),
            userName:
              window.location.href.indexOf("localhost") != -1 ||
              window.location.href.indexOf("192.168") != -1
                ? "潘炜桐"
                : window.parent.US.userInfo.username,
            userId:
              window.location.href.indexOf("localhost") != -1 ||
              window.location.href.indexOf("192.168") != -1
                ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                : window.parent.US.userInfo.userid,
            roomId: localStorage.getItem("roomId"),
            type: type,
          },
        },
      ],
    };
    let params =
      "mode=" +
      [
        "send",
        encodeURIComponent(encodeURIComponent(JSON.stringify(mindinfo))),
      ].join(",");
    axios
      .post("https://poll.cocorobo.cn", params)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        if (str == "update") {
          let data = JSON.parse(localStorage.getItem("offlineUpdateData"));
          let index = [];
          if (newNodes.length != 0) {
            for (let i = 0; i < newNodes.length; i++) {
              for (let j = 0; j < data.nodes.length; j++) {
                if (newNodes[i].id == data.nodes[j].id) {
                  data.nodes[j] = newNodes[i];
                  index.push(j);
                  break;
                }
              }
            }
            if (index.length == 0) {
              for (let j = 0; j < newNodes.length; j++) {
                data.nodes.push(newNodes[j]);
              }
            }
          }
          if (newEdges.length != 0) {
            for (let i = 0; i < newEdges.length; i++) {
              for (let j = 0; j < data.edges.length; j++) {
                if (newEdges[i].id == data.edges[j].id) {
                  data.edges[j] = newEdges[i];
                  index.push(j);
                  break;
                }
              }
            }
            if (index.length == 0) {
              for (let j = 0; j < newEdges.length; j++) {
                data.edges.push(newEdges[j]);
              }
            }
          }
          if (data.backgroundUrl != localStorage.getItem("background")) {
            data.backgroundUrl = localStorage.getItem("background");
          }
          localStorage.setItem("offlineUpdateData", JSON.stringify(data));
        }
        if (str == "delete") {
          let data = JSON.parse(localStorage.getItem("offlineUpdateData"));
          if (newNodes.length != 0) {
            for (let i = 0; i < newNodes.length; i++) {
              for (let j = 0; j < data.nodes.length; j++) {
                if (newNodes[i].id == data.nodes[j].id) {
                  data.nodes.splice(j, 1);
                  break;
                }
              }
            }
          }
          if (newEdges.length != 0) {
            for (let i = 0; i < newEdges.length; i++) {
              for (let j = 0; j < data.edges.length; j++) {
                if (newEdges[i].id == data.edges[j].id) {
                  data.edges.splice(j, 1);
                  break;
                }
              }
            }
          }
          localStorage.setItem("offlineUpdateData", JSON.stringify(data));
          let offlineDeleteData = JSON.parse(
            localStorage.getItem("offlineDeleteData")
          );
          if (newNodes.length != 0) {
            for (let i = 0; i < newNodes.length; i++) {
              offlineDeleteData.nodes.push(newNodes[i]);
            }
          }
          if (newEdges.length != 0) {
            for (let i = 0; i < newEdges.length; i++) {
              offlineDeleteData.edges.push(newEdges[i]);
            }
          }
          localStorage.setItem(
            "offlineDeleteData",
            JSON.stringify(offlineDeleteData)
          );
        }
      });
  } else {
    if (str == "update") {
      let data = JSON.parse(localStorage.getItem("offlineUpdateData"));
      let index = [];
      if (newNodes.length != 0) {
        for (let i = 0; i < newNodes.length; i++) {
          for (let j = 0; j < data.nodes.length; j++) {
            if (newNodes[i].id == data.nodes[j].id) {
              data.nodes[j] = newNodes[i];
              index.push(j);
              break;
            }
          }
        }
        if (index.length == 0) {
          for (let j = 0; j < newNodes.length; j++) {
            data.nodes.push(newNodes[j]);
          }
        }
      }
      if (newEdges.length != 0) {
        for (let i = 0; i < newEdges.length; i++) {
          for (let j = 0; j < data.edges.length; j++) {
            if (newEdges[i].id == data.edges[j].id) {
              data.edges[j] = newEdges[i];
              index.push(j);
              break;
            }
          }
        }
        if (index.length == 0) {
          for (let j = 0; j < newEdges.length; j++) {
            data.edges.push(newEdges[j]);
          }
        }
      }
      if (data.backgroundUrl != localStorage.getItem("background")) {
        data.backgroundUrl = localStorage.getItem("background");
      }
      localStorage.setItem("offlineUpdateData", JSON.stringify(data));
    }
    if (str == "delete") {
      let data = JSON.parse(localStorage.getItem("offlineUpdateData"));
      if (newNodes.length != 0) {
        for (let i = 0; i < newNodes.length; i++) {
          for (let j = 0; j < data.nodes.length; j++) {
            if (newNodes[i].id == data.nodes[j].id) {
              data.nodes.splice(j, 1);
              break;
            }
          }
        }
      }
      if (newEdges.length != 0) {
        for (let i = 0; i < newEdges.length; i++) {
          for (let j = 0; j < data.edges.length; j++) {
            if (newEdges[i].id == data.edges[j].id) {
              data.edges.splice(j, 1);
              break;
            }
          }
        }
      }
      localStorage.setItem("offlineUpdateData", JSON.stringify(data));
      let offlineDeleteData = JSON.parse(
        localStorage.getItem("offlineDeleteData")
      );
      if (newNodes.length != 0) {
        for (let i = 0; i < newNodes.length; i++) {
          offlineDeleteData.nodes.push(newNodes[i]);
        }
      }
      if (newEdges.length != 0) {
        for (let i = 0; i < newEdges.length; i++) {
          offlineDeleteData.edges.push(newEdges[i]);
        }
      }
      localStorage.setItem(
        "offlineDeleteData",
        JSON.stringify(offlineDeleteData)
      );
    }
  }
}

export function add_Operation(data, str) {
  if (
    localStorage.getItem("network") == "true" ||
    localStorage.getItem("network") == true
  ) {
    let newData = {
      type: str,
      data: data,
    };
    let params =
      window.location.href.indexOf("localhost") != -1 ||
      window.location.href.indexOf("192.168") != -1
        ? "mode=" +
          [
            "1cf9dc4b-d95f-11ea-af4c-52540005ab01",
            localStorage.getItem("roomId"),
            "cscl",
            encodeURIComponent(encodeURIComponent(JSON.stringify(newData))),
          ].join(",")
        : "mode=" +
          [
            window.parent.US.userInfo.userid,
            localStorage.getItem("roomId"),
            "cscl",
            encodeURIComponent(encodeURIComponent(JSON.stringify(newData))),
          ].join(",");
    axios
      .post("https://pbl.cocorobo.cn/api/pbl/add_OperationCSCL", params)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
