<template>
  <div id="shareDialog">
    <el-dialog
      :model-value="dialogVisible"
      width="60%"
      :destroy-on-close="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header>
        <div style="width: 100%; position: relative">
          <span class="el-dialog__title">{{ languageData.share3 }}</span>
          <button type="button" class="close" style="top: 4px; right: 0">
            <img
              v-if="hoverClose"
              src="../../assets/close_pre.png"
              width="16"
              height="16"
              @mouseout="blurClose"
              @click="handleClose"
            />
            <img
              v-else
              src="../../assets/close_nor.png"
              width="16"
              height="16"
              @mouseover="focusClose"
            />
          </button>
        </div>
      </template>
      <span
        style="
          display: flex;
          height: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          v-if="themeId != '111' && themeId != ''"
          style="width: 80%; margin-top: 30px"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              margin-left: 25px;
            "
          >
            <span>{{ languageData.shareContent }}</span>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 35%;
              margin-top: 15px;
              margin-left: 25px;
            "
          >
            <el-radio
              v-model="radio"
              label="1"
              value="1"
              style="margin-bottom: 0"
              @change="setSelect"
              >{{ languageData.runResult }}</el-radio
            >
            <el-radio
              v-model="radio"
              label="2"
              value="2"
              style="margin-bottom: 0"
              @change="setSelect"
              >{{ languageData.tip92 }}</el-radio
            >
          </div>
        </div>
        <div style="width: 80%; margin-top: 30px">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              margin-left: 25px;
            "
          >
            <span>{{ languageData.tip99 }}</span>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 35%;
              margin-top: 15px;
              margin-left: 25px;
            "
          >
            <el-radio
              v-model="radio_1"
              label="1"
              value="1"
              style="margin-bottom: 0"
              @change="setRadioSelect"
              >{{ languageData.tip101 }}</el-radio
            >
            <el-radio
              v-model="radio_1"
              label="2"
              value="2"
              style="margin-bottom: 0"
              @change="setRadioSelect"
              >{{ languageData.tip100 }}</el-radio
            >
          </div>
        </div>
        <div
          v-loading="loading"
          style="width: 80%; margin-top: 36px"
          :element-loading-text="languageData.loading"
          element-loading-background="rgba(255, 255, 255, 0.8)"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              margin-left: 25px;
              margin-bottom: 15px;
            "
          >
            <span>{{ languageData.tip93 }}</span>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center">
            <div class="text-3">
              {{ codeurl }}
            </div>
            <div
              style="
                background: #017cfe;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                line-height: 36px;
                height: 36px;
                margin-left: 2%;
                cursor: pointer;
              "
              @click="createShare"
            >
              <span class="aaa">{{ languageData.tip94 }}</span>
            </div>
          </div>
          <div
            v-if="radio_1 == '2'"
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              margin-left: 25px;
              margin-top: 36px;
              margin-bottom: 15px;
            "
          >
            <span>{{ languageData.tip95 }}</span>
          </div>
          <div
            v-if="radio_1 == '2'"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <div class="text-3" style="width: 52%">
              {{ inventCode }}
            </div>
            <div
              style="
                background: #017cfe;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                line-height: 36px;
                height: 36px;
                margin-left: 1%;
                cursor: pointer;
              "
              @click="regenerateInvent"
            >
              <span class="aaa">{{ languageData.tip102 }}</span>
            </div>
            <div
              style="
                background: #017cfe;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                line-height: 36px;
                height: 36px;
                margin-left: 1%;
                cursor: pointer;
              "
              @click="createInvent"
            >
              <span class="aaa">{{ languageData.tip96 }}</span>
            </div>
          </div>
          <div style="width: 80%; margin-top: 36px; margin-bottom: 30px">
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                margin-left: 25px;
                margin-bottom: 15px;
              "
            >
              <span>{{ languageData.tip97 }}</span>
            </div>
            <div
              style="
                margin-left: 25px;
                border-radius: 0px 0px 0px 0px;
                display: flex;
                flex-direction: row;
                align-items: center;
              "
            >
              <img
                v-if="QRImgUrl != ''"
                :src="QRImgUrl"
                width="164"
                height="164"
              />
              <div
                style="
                  background: #017cfe;
                  border-radius: 4px 4px 4px 4px;
                  opacity: 1;
                  line-height: 36px;
                  height: 36px;
                  margin-left: 5%;
                  cursor: pointer;
                "
                @click="handDownInage"
              >
                <span class="aaa">{{ languageData.tip98 }}</span>
              </div>
            </div>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import QRCode from "qrcode";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    themeId: {
      type: String,
      default: ''
    },
  },
  emits: ["info"],
  data() {
    return {
      radio: "1",
      radio_1: "1",
      languageData: {},
      hoverClose: false,
      url: "",
      codeurl: "",
      QRImgUrl: "",
      inventCode: "",
      loading: false,
      id: "",
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          $(".el-dialog").css("width", "60%");
          $(".el-dialog").css({
            height: "auto",
          });
        });
      }
    },
    themeId(newVal) {
      if (newVal != "" && newVal != "111") {
        let that = this;
        that.loading = true;
        that.url =
          window.location.href.split("/?")[0] +
          "/share?type=0&tid=" +
          newVal +
          "&cid=" +
          localStorage.getItem("roomId");
        this.codeurl =
          window.location.href.split("/?")[0] +
          "/share?type=0&tid=" +
          newVal +
          "&cid=" +
          localStorage.getItem("roomId");
        that.getQRcode();
        that.loading = false;
      } else if (newVal == "111") {
        let that = this;
        that.loading = true;
        that.url =
          window.location.href.split("/?")[0] +
          "/share?type=4&cid=" +
          localStorage.getItem("roomId");
        this.codeurl =
          window.location.href.split("/?")[0] +
          "/share?type=4&cid=" +
          localStorage.getItem("roomId");
        that.getQRcode();
        that.loading = false;
      }
    },
  },
  created() {
    this.$nextTick(() => {
      $(".el-dialog").css("width", "60%");
      $(".el-dialog").css({
        height: "auto",
      });
    });
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    regenerateInvent() {
      let that = this;
      let code = that.generateRandomNumber();
      that.$ajax
        .post("https://gpt.cocorobo.cn/update_invitation_code", {
          uid: that.id,
          icode: code,
        })
        .then(function (response) {
          console.log(response);
          that.inventCode = code;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAllData(newVal, type) {
      let that = this;
      that.$ajax
        .post("https://gpt.cocorobo.cn/get_invitation_code", {
          uid: localStorage.getItem("roomId"),
          tid: newVal,
          type: type,
        })
        .then(function (response) {
          console.log(response);
          if (JSON.parse(response.data.FunctionResponse).length == 0) {
            let code = that.generateRandomNumber();
            that.$ajax
              .post("https://gpt.cocorobo.cn/insert_invitation_code", {
                uid: localStorage.getItem("roomId"),
                tid: newVal,
                type: type,
                icode: code,
              })
              .then(function (response) {
                console.log(response);
                that.id = JSON.parse(response.data.FunctionResponse)[0].id;
                if (newVal != "" && newVal != "111") {
                  that.codeurl =
                    window.location.href.split("/?")[0] +
                    "/share?type=" +
                    type +
                    "&tid=" +
                    newVal +
                    "&cid=" +
                    localStorage.getItem("roomId");
                  that.inventCode = code;
                  that.getQRcode();
                  that.loading = false;
                } else if (newVal == "111") {
                  that.codeurl =
                    window.location.href.split("/?")[0] +
                    "/share?type=" +
                    type +
                    "&cid=" +
                    localStorage.getItem("roomId");
                  that.inventCode = code;
                  that.getQRcode();
                  that.loading = false;
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            let code = JSON.parse(response.data.FunctionResponse)[0]
              .invitationCode;
            that.id = JSON.parse(response.data.FunctionResponse)[0].id;
            if (newVal != "" && newVal != "111") {
              that.codeurl =
                window.location.href.split("/?")[0] +
                "/share?type=" +
                type +
                "&tid=" +
                newVal +
                "&cid=" +
                localStorage.getItem("roomId");
              that.inventCode = code;
              that.getQRcode();
              that.loading = false;
            } else if (newVal == "111") {
              that.codeurl =
                window.location.href.split("/?")[0] +
                "/share?type=" +
                type +
                "&cid=" +
                localStorage.getItem("roomId");
              that.inventCode = code;
              that.getQRcode();
              that.loading = false;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    generateRandomNumber() {
      return Math.floor(100000 + Math.random() * 900000);
    },
    getQRcode() {
      QRCode.toDataURL(
        this.url,
        { errorCorrectionLevel: "L", margin: 2, width: 128 },
        (err, url) => {
          if (err) throw err;
          this.QRImgUrl = url;
        }
      );
    },
    setSelect(e) {
      this.url = "";
      this.codeurl = "";
      this.QRImgUrl = "";
      this.inventCode = "";
      this.radio = e;
      this.id = "";
      if (e == "1") {
        this.loading = true;
        if (this.radio_1 == "1") {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=0&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.codeurl =
            window.location.href.split("/?")[0] +
            "/share?type=0&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getQRcode();
          this.loading = false;
        } else {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=2&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getAllData(this.themeId, 2);
        }
      } else {
        this.loading = true;
        if (this.radio_1 == "1") {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=1&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.codeurl =
            window.location.href.split("/?")[0] +
            "/share?type=1&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getQRcode();
          this.loading = false;
        } else {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=3&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getAllData(this.themeId, 3);
        }
      }
    },
    setRadioSelect(e) {
      this.url = "";
      this.codeurl = "";
      this.QRImgUrl = "";
      this.inventCode = "";
      this.radio_1 = e;
      this.id = "";
      if (e == "1") {
        this.loading = true;
        if (this.radio == "1" && this.themeId != "111" && this.themeId != "") {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=0&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.codeurl =
            window.location.href.split("/?")[0] +
            "/share?type=0&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getQRcode();
          this.loading = false;
        } else if (
          this.radio == "2" &&
          this.themeId != "111" &&
          this.themeId != ""
        ) {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=1&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.codeurl =
            window.location.href.split("/?")[0] +
            "/share?type=1&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getQRcode();
          this.loading = false;
        } else if (this.themeId == "111") {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=4&cid=" +
            localStorage.getItem("roomId");
          this.codeurl =
            window.location.href.split("/?")[0] +
            "/share?type=4&cid=" +
            localStorage.getItem("roomId");
          this.getQRcode();
          this.loading = false;
        }
      } else {
        this.loading = true;
        if (this.radio == "1" && this.themeId != "111" && this.themeId != "") {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=2&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getAllData(this.themeId, 2);
        } else if (
          this.radio == "2" &&
          this.themeId != "111" &&
          this.themeId != ""
        ) {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=3&tid=" +
            this.themeId +
            "&cid=" +
            localStorage.getItem("roomId");
          this.getAllData(this.themeId, 3);
        } else if (this.themeId == "111") {
          this.url =
            window.location.href.split("/?")[0] +
            "/share?type=5&cid=" +
            localStorage.getItem("roomId");
          this.getAllData(this.themeId, 5);
        }
      }
    },
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handleClose() {
      this.hoverClose = false;
      this.url = "";
      this.codeurl = "";
      this.QRImgUrl = "";
      this.inventCode = "";
      this.radio = "1";
      this.radio_1 = "1";
      this.id = "";
      this.$emit("info", false);
    },
    copyText(text) {
      const textArea = document.createElement("textArea");
      textArea.value = text;
      textArea.style.width = 0;
      textArea.style.position = "fixed";
      textArea.style.left = "-999px";
      textArea.style.top = "10px";
      textArea.setAttribute("readonly", "readonly");
      document.body.appendChild(textArea);
      textArea.select();
      var flag = null;
      try {
        flag = document.execCommand("copy");
      } catch {
        flag = false;
      }
      document.body.removeChild(textArea);
      return flag;
    },
    createShare() {
      let url = this.codeurl;
      let flag = this.copyText(url);
      if (flag) {
        this.$message.success(this.languageData.fzcg);
      } else {
        this.$message.error(this.languageData.fz);
      }
    },
    createInvent() {
      let url = this.inventCode;
      let flag = this.copyText(url);
      if (flag) {
        this.$message.success(this.languageData.tip91);
      } else {
        this.$message.error(this.languageData.fz);
      }
    },
    handDownInage() {
      let txid = this.url;
      if (txid) {
        this.$nextTick(() => {
          // 此时可以确认已经有ref对象了
          let image = QRCode.toDataURL(txid);
          image.then((res) => {
            console.log("下载", res);
            this.downloadImg(res);
          });
        });
      }
    },
    //下载图片
    downloadImg(value) {
      //实例化一个img对象
      const img = new Image();
      //设置img的图片路径
      img.src = value;
      //设置跨域解决
      img.setAttribute("crossOrigin", "Anonymous");
      //img加载完后处理
      img.onload = function () {
        //创建一个canvas对象
        const canvas = document.createElement("canvas");
        //把图片的宽度设为canves的宽度
        canvas.width = 230;
        //把图片的高度设为canves的高度
        canvas.height = 230;
        //创建一个2d画布
        const ctx = canvas.getContext("2d");
        // 将img中的内容画到画布上
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // 将画布内容转换为Blob
        canvas.toBlob((blob) => {
          console.log("blob", blob);
          // blob转为同源url
          let blobUrl = window.URL.createObjectURL(blob);
          // 创建a链接
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = "二维码";
          // 触发a链接点击事件，浏览器开始下载文件
          a.click();
        });
      };
    },
  },
};
</script>

<style>
#shareDialog .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
  padding: 0;
}

#shareDialog .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

#shareDialog .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  background: var(--font-icon-white, #fff);
  padding: 8px 24px;
  width: calc(100% - 48px);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

#shareDialog .el-dialog__footer {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 8px 24px;
  background: var(--font-icon-white, #fff);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: calc(100% - 48px);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  box-sizing: unset;
}

#shareDialog .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#shareDialog .text-3 {
  width: 70%;
  margin-left: 25px;
  height: 36px;
  background: #f0f4fa;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  line-height: 36px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 10px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

#shareDialog .aaa {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
