<template>
  <div id="addImage">
    <el-dialog
      :model-value="dialogVisible"
      width="50%"
      :destroy-on-close="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header>
        <div style="width: 100%; position: relative">
          <span class="el-dialog__title">{{ languageData.searchImages }}</span>
          <button type="button" class="close" style="top: 4px; right: 0">
            <img
              v-if="hoverClose"
              src="../../assets/close_pre.png"
              width="16"
              height="16"
              @mouseout="blurClose"
              @click="handleClose"
            />
            <img
              v-else
              src="../../assets/close_nor.png"
              width="16"
              height="16"
              @mouseover="focusClose"
            />
          </button>
        </div>
      </template>
      <span
        style="
          display: flex;
          height: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          :style="
            imageList.length != 0
              ? 'width: 100%;height: 640px;display: flex;flex-direction: row;'
              : 'width: 100%;'
          "
        >
          <div>
            <div style="width: 100%; display: flex; align-items: center">
              <el-input
                v-model="input"
                :placeholder="languageData.searchImagesDesc"
                class="input-with-select"
                @keyup.enter="searchImage"
              >
                <template #suffix>
                  <el-button icon="Search" @click="searchImage" />
                </template>
              </el-input>
            </div>
            <div v-if="imageList.length != 0" style="text-align: right">
              <el-button
                size="small"
                style="background: none; border: none; margin-top: 10px"
                @click="switchPage"
                >{{ languageData.change }}</el-button
              >
            </div>
            <div
              v-if="loading"
              v-loading="loading"
              style="min-height: 100px"
              :element-loading-text="languageData.tip10"
              element-loading-background="rgba(0, 0, 0, 0)"
            />

            <div
              v-if="imageList.length != 0"
              class="addImage"
              style="overflow-y: auto; height: 88%; margin-top: 5px"
            >
              <div
                style="
                  display: flex;
                  width: 100%;
                  flex-flow: row wrap;
                  justify-content: left;
                  margin-top: 20px;
                "
              >
                <template v-for="(item, index) in imageList" :key="index">
                  <img
                    :src="item"
                    width="200"
                    height="115"
                    style="
                      margin-bottom: 20px;
                      margin-left: 15px;
                      margin-right: 15px;
                      object-fit: cover;
                    "
                    @click="goToAddImage"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    assistantName: { type: String, default: '' },
  },
emits: ['info'],
  data() {
    return {
      input: "",
      hoverClose: false,
      languageData: {},
      imageList: [],
      loading: false,
      page: 1,
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          $(".el-dialog").css("width", "52%");
          $(".el-dialog").css({
            height: "auto",
          });
        });
      }
    },
    assistantName(newVal) {
      if (newVal != "") {
        this.input = newVal;
        this.searchImage();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      $(".el-dialog").css("width", "52%");
      $(".el-dialog").css({
        height: "auto",
      });
    });
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handleClose() {
      this.imageList = [];
      this.input = "";
      this.page = 1;
      this.$emit("info", false);
      // this.$emit("clearList", []);
    },
    urlToBase64(url) {
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;
          // 将图片插入画布并开始绘制
          canvas.getContext("2d").drawImage(image, 0, 0);
          // result
          let result = canvas.toDataURL("image/png");
          resolve(result);
        };
        // CORS 策略，会存在跨域问题https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
        image.setAttribute("crossOrigin", "Anonymous");
        image.src = url;
        // 图片加载失败的错误处理
        image.onerror = () => {
          reject(new Error("urlToBase64 error"));
        };
      });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    //2,再将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date(); // 文件最后的修改日期
      theBlob.name = fileName; // 文件名
      return new File([theBlob], fileName, {
        type: theBlob.type,
        lastModified: Date.now(),
      });
    },
    uploadImage(fileParams) {
      // console.log(fileParams.file)
      var bucket = new window.AWS.S3({ params: { Bucket: "ccrb" } }); //选择桶
      var _this = this;
      if (fileParams) {
        var params = {
          Key: fileParams.name,
          ContentType: fileParams.type,
          Body: fileParams,
          "Access-Control-Allow-Credentials": "*",
          ACL: "public-read",
        }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
        var options = {
          partSize: 2048 * 1024 * 1024,
          queueSize: 2,
          leavePartsOnError: true,
        };
        bucket.upload(params, options).send(function (err, data) {
          if (err) {
            console.log(err);
          } else {
            // const imageList = [{ name: '', url: data.Location }]
            _this.handleClose();
            _this.$emit("setHeadUrl", data.Location);
          }
        });
      }
    },
    goToAddImage(e) {
      let that = this;
      that.urlToBase64(e.target.currentSrc).then((res) => {
        // 转化后的base64图片地址
        // console.log('base64', res)
        let blob = that.dataURLtoBlob(res);
        // 拿到文件名
        let fileName = uuidv4() + ".png";
        // 2，在转为 file类型
        let file1 = that.blobToFile(blob, fileName);
        that.uploadImage(file1);
      });
    },
    switchPage() {
      this.page = this.page + 1;
      this.searchImage();
    },
    searchImage() {
      var _this = this;
      _this.imageList = [];
      _this.loading = true;
      _this.$ajax
        .post("https://gpt.cocorobo.cn/search_image", {
          query: _this.input,
          page: _this.page,
          pagesize: 16,
        })
        .then(function (response) {
          var data = response.data.FunctionResponse.result;
          for (var i = 0; i < data.length; i++) {
            _this.imageList.push(data[i].thumbnail);
          }
          _this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
#addImage .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
  padding: 0;
}

#addImage .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

#addImage .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  background: var(--font-icon-white, #fff);
  padding: 8px 24px;
  width: calc(100% - 48px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  min-height: 74px;
}

#addImage .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

.addImage::-webkit-scrollbar {
  width: 4px;
}

.addImage::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(#606266, 60%);
}

.addImage::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#606266, 30%);
}
</style>
