<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
    <path
      d="M933.647059 0h-210.82353v90.352941h180.705883a30.117647 30.117647 0 0 1 30.117647 30.117647v180.705883h90.352941V90.352941a90.352941 90.352941 0 0 0-90.352941-90.352941zM361.411765 0h301.17647v90.352941H361.411765zM933.647059 361.411765h90.352941v301.17647h-90.352941zM361.411765 933.647059h301.17647v90.352941H361.411765zM0 361.411765h90.352941v301.17647H0zM90.352941 903.529412v-180.705883H0v210.82353a90.352941 90.352941 0 0 0 90.352941 90.352941h210.82353v-90.352941H120.470588a30.117647 30.117647 0 0 1-30.117647-30.117647zM933.647059 903.529412a30.117647 30.117647 0 0 1-30.117647 30.117647h-180.705883v90.352941h210.82353a90.352941 90.352941 0 0 0 90.352941-90.352941v-210.82353h-90.352941zM0 90.352941v210.82353h90.352941V120.470588a30.117647 30.117647 0 0 1 30.117647-30.117647h180.705883V0H90.352941a90.352941 90.352941 0 0 0-90.352941 90.352941z"
      p-id="1890">
    </path>
  </svg> -->

  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMwOyYjMTYx
OyYjMTM0OyYjMjMzOyYjMTI4OyYjMTM3OyI+CjxnIGlkPSJHcm91cCA4OTMiPgo8cGF0aCBpZD0i
VW5pb24iIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMS40MTA4
NSAzQzEuMTgzOTUgMyAxIDMuMTc5MDkgMSAzLjRWNC4wNjY2N0MxIDQuMjg3NTggMS4xODM5NSA0
LjQ2NjY3IDEuNDEwODUgNC40NjY2N0MxLjYzNzc2IDQuNDY2NjcgMS44MjE3MSA0LjI4NzU4IDEu
ODIxNzEgNC4wNjY2N1YzLjhIMi4xODEyMUMyLjQwODExIDMuOCAyLjU5MjA2IDMuNjIwOTEgMi41
OTIwNiAzLjRDMi41OTIwNiAzLjE3OTA5IDIuNDA4MTEgMyAyLjE4MTIxIDNIMS40MTA4NVpNMy43
MjE5MSAzQzMuNDk1IDMgMy4zMTEwNSAzLjE3OTA5IDMuMzExMDUgMy40QzMuMzExMDUgMy42MjA5
MSAzLjQ5NSAzLjggMy43MjE5MSAzLjhINS4yNjI2MUM1LjQ4OTUyIDMuOCA1LjY3MzQ2IDMuNjIw
OTEgNS42NzM0NiAzLjRDNS42NzM0NiAzLjE3OTA5IDUuNDg5NTIgMyA1LjI2MjYxIDNIMy43MjE5
MVpNNi44MDMzMSAzQzYuNTc2NCAzIDYuMzkyNDYgMy4xNzkwOSA2LjM5MjQ2IDMuNEM2LjM5MjQ2
IDMuNjIwOTEgNi41NzY0IDMuOCA2LjgwMzMxIDMuOEg4LjM0NDAxQzguNTcwOTIgMy44IDguNzU0
ODcgMy42MjA5MSA4Ljc1NDg3IDMuNEM4Ljc1NDg3IDMuMTc5MDkgOC41NzA5MiAzIDguMzQ0MDEg
M0g2LjgwMzMxWk05Ljg4NDcyIDNDOS42NTc4MSAzIDkuNDczODYgMy4xNzkwOSA5LjQ3Mzg2IDMu
NEM5LjQ3Mzg2IDMuNjIwOTEgOS42NTc4MSAzLjggOS44ODQ3MiAzLjhIMTEuNDI1NEMxMS42NTIz
IDMuOCAxMS44MzYzIDMuNjIwOTEgMTEuODM2MyAzLjRDMTEuODM2MyAzLjE3OTA5IDExLjY1MjMg
MyAxMS40MjU0IDNIOS44ODQ3MlpNMTIuOTY2MSAzQzEyLjczOTIgMyAxMi41NTUzIDMuMTc5MDkg
MTIuNTU1MyAzLjRDMTIuNTU1MyAzLjYyMDkxIDEyLjczOTIgMy44IDEyLjk2NjEgMy44SDEzLjMy
NTZWNC40QzEzLjMyNTYgNC42MjA5MSAxMy41MDk2IDQuOCAxMy43MzY1IDQuOEMxMy45NjM0IDQu
OCAxNC4xNDczIDQuNjIwOTEgMTQuMTQ3MyA0LjRWMy40QzE0LjE0NzMgMy4xNzkwOSAxMy45NjM0
IDMgMTMuNzM2NSAzSDEyLjk2NjFaTTEuODIxNzEgNS40QzEuODIxNzEgNS4xNzkwOSAxLjYzNzc2
IDUgMS40MTA4NSA1QzEuMTgzOTUgNSAxIDUuMTc5MDkgMSA1LjRWNi43MzMzM0MxIDYuOTU0MjUg
MS4xODM5NSA3LjEzMzMzIDEuNDEwODUgNy4xMzMzM0MxLjYzNzc2IDcuMTMzMzMgMS44MjE3MSA2
Ljk1NDI1IDEuODIxNzEgNi43MzMzM1Y1LjRaTTE0LjE0NzMgNi40QzE0LjE0NzMgNi4xNzkwOSAx
My45NjM0IDYgMTMuNzM2NSA2QzEzLjUwOTYgNiAxMy4zMjU2IDYuMTc5MDkgMTMuMzI1NiA2LjRW
Ny40QzEzLjMyNTYgNy42MjA5MSAxMy41MDk2IDcuOCAxMy43MzY1IDcuOEMxMy45NjM0IDcuOCAx
NC4xNDczIDcuNjIwOTEgMTQuMTQ3MyA3LjRWNi40Wk0xLjgyMTcxIDguMDY2NjdDMS44MjE3MSA3
Ljg0NTc1IDEuNjM3NzYgNy42NjY2NyAxLjQxMDg1IDcuNjY2NjdDMS4xODM5NSA3LjY2NjY3IDEg
Ny44NDU3NSAxIDguMDY2NjdWOS40QzEgOS42MjA5MSAxLjE4Mzk1IDkuOCAxLjQxMDg1IDkuOEMx
LjYzNzc2IDkuOCAxLjgyMTcxIDkuNjIwOTEgMS44MjE3MSA5LjRWOC4wNjY2N1pNMS44MjE3MSAx
MC43MzMzQzEuODIxNzEgMTAuNTEyNCAxLjYzNzc2IDEwLjMzMzMgMS40MTA4NSAxMC4zMzMzQzEu
MTgzOTUgMTAuMzMzMyAxIDEwLjUxMjQgMSAxMC43MzMzVjExLjRDMSAxMS42MjA5IDEuMTgzOTUg
MTEuOCAxLjQxMDg1IDExLjhIMi4xODEyMUMyLjQwODExIDExLjggMi41OTIwNiAxMS42MjA5IDIu
NTkyMDYgMTEuNEMyLjU5MjA2IDExLjE3OTEgMi40MDgxMSAxMSAyLjE4MTIxIDExSDEuODIxNzFW
MTAuNzMzM1pNMy43MjE5MSAxMUMzLjQ5NSAxMSAzLjMxMTA1IDExLjE3OTEgMy4zMTEwNSAxMS40
QzMuMzExMDUgMTEuNjIwOSAzLjQ5NSAxMS44IDMuNzIxOTEgMTEuOEg1LjI2MjYxQzUuNDg5NTIg
MTEuOCA1LjY3MzQ2IDExLjYyMDkgNS42NzM0NiAxMS40QzUuNjczNDYgMTEuMTc5MSA1LjQ4OTUy
IDExIDUuMjYyNjEgMTFIMy43MjE5MVpNNi44MDMzMSAxMUM2LjU3NjQgMTEgNi4zOTI0NiAxMS4x
NzkxIDYuMzkyNDYgMTEuNEM2LjM5MjQ2IDExLjYyMDkgNi41NzY0IDExLjggNi44MDMzMSAxMS44
SDcuNTczNjZDNy44MDA1NyAxMS44IDcuOTg0NTIgMTEuNjIwOSA3Ljk4NDUyIDExLjRDNy45ODQ1
MiAxMS4xNzkxIDcuODAwNTcgMTEgNy41NzM2NiAxMUg2LjgwMzMxWk05LjUzMzMzIDUuODMyMTVD
OS40MDE1MyA1LjczNDExIDkuMjIyMTEgNS43MjI4NSA5LjA3ODQ3IDUuODAzNTlDOC45MzQ4MyA1
Ljg4NDMzIDguODU1MTQgNi4wNDEyNCA4Ljg3NjQ1IDYuMjAxMzhMOS43MzQwNCAxMi42NDc1Qzku
NzU3NTYgMTIuODI0MyA5Ljg5ODM3IDEyLjk2NDYgMTAuMDc5MyAxMi45OTE1QzEwLjI2MDMgMTMu
MDE4NSAxMC40Mzc4IDEyLjkyNTYgMTAuNTE0NSAxMi43NjM4TDExLjcxMDUgMTAuMjQxOUwxNC41
NTE4IDEwLjQ5NDVDMTQuNzM0MSAxMC41MTA3IDE0LjkwNTQgMTAuNDA3NSAxNC45NzE5IDEwLjI0
MTRDMTUuMDM4NCAxMC4wNzU0IDE0Ljk4NCA5Ljg4NjUxIDE0LjgzODUgOS43NzgzTDkuNTMzMzMg
NS44MzIxNVpNMTEuMDg2IDkuNjUwMkwxMC4zNjU5IDExLjE2ODdMOS44MTgwMSA3LjA1MDY3TDEz
LjIwNzIgOS41NzE2M0wxMS40OTY0IDkuNDE5NTNDMTEuMzIzMiA5LjQwNDE0IDExLjE1ODkgOS40
OTY1MiAxMS4wODYgOS42NTAyWiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC45Ii8+Cjwv
Zz4KPC9nPgo8L3N2Zz4K"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
