<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex_x chat_room_content" :class="is_chatroom_full_screen ? 'full_screen' : ''"
    :style="isEnglish ? 'width: 850px;' : 'width: 660px;'">
    <div v-show="promptsList" ref="promptsList" class="promptsList flex_x" :style="isEnglish
      ? 'width:260px;height:' + promptsListHeigth + 'px;'
      : 'width:210px;height:' + promptsListHeigth + 'px;'
      ">
      <div v-if="allGroup.length != 0" class="icon_select">
        <div style="margin-bottom: 10px; cursor: pointer; display: none;" @click="handle_swich_icon(1)">
          <svg v-if="icon_active == 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            fill="none">
            <path
              d="M10.289 20.8383H4.71558V3.22031H13.9664V7.65703C13.9664 8.12344 14.3437 8.50078 14.8101 8.50078H19.2468V14.175C19.2468 14.6414 19.6242 15.0187 20.0906 15.0187C20.557 15.0187 20.9343 14.6414 20.9343 14.175V7.65703V7.61484C20.9343 7.59609 20.932 7.57734 20.9296 7.55859C20.9296 7.55625 20.9296 7.55156 20.9273 7.54922C20.9226 7.50938 20.9156 7.47188 20.9039 7.43438V7.43203C20.8921 7.39219 20.8804 7.35469 20.864 7.31719V7.31484C20.857 7.29609 20.8476 7.27969 20.8382 7.26094V7.25859L20.8101 7.20938C20.8078 7.20703 20.8078 7.20469 20.8054 7.20234C20.796 7.18828 20.7867 7.17188 20.775 7.15781C20.775 7.15547 20.7726 7.15547 20.7726 7.15313C20.7609 7.13906 20.7492 7.12266 20.7374 7.10859C20.7351 7.10625 20.7328 7.10156 20.7281 7.09922C20.7164 7.08516 20.7023 7.07109 20.6906 7.05703L15.4054 1.77891C15.3914 1.76484 15.3796 1.75313 15.3656 1.74141C15.3632 1.73906 15.3585 1.73672 15.3562 1.73203C15.3421 1.72031 15.3281 1.70859 15.3117 1.69688C15.3093 1.69453 15.3093 1.69453 15.307 1.69453C15.2929 1.68516 15.2789 1.67344 15.2625 1.66406C15.2601 1.66172 15.2578 1.66172 15.2554 1.65938C15.239 1.65 15.2226 1.63828 15.2039 1.63125H15.2015C15.1851 1.62187 15.1664 1.6125 15.1476 1.60547H15.1453C15.1078 1.58906 15.0703 1.575 15.0304 1.56563C14.9929 1.55625 14.9554 1.54688 14.9156 1.54219C14.9132 1.54219 14.9085 1.54219 14.9062 1.53984C14.8875 1.5375 14.8687 1.53516 14.85 1.53516H4.34058C3.61636 1.53516 3.02808 2.12344 3.02808 2.84766V21.2156C3.02808 21.9398 3.61636 22.5281 4.34058 22.5281H10.289C10.7554 22.5281 11.1328 22.1508 11.1328 21.6844C11.1328 21.2156 10.7554 20.8383 10.289 20.8383ZM15.6515 6.81328V4.41328L18.0515 6.81328H15.6515Z"
              fill="#3681FC" />
            <path
              d="M22.1531 20.268L18.0047 17.3078C17.8008 17.1625 17.5195 17.3078 17.5195 17.5586V19.675H13.5937C13.1273 19.675 12.75 20.0523 12.75 20.5187C12.75 20.9851 13.1273 21.3625 13.5937 21.3625H17.5195V23.4789C17.5195 23.7297 17.8031 23.875 18.0047 23.7297L22.1531 20.7695C22.3266 20.6476 22.3266 20.3922 22.1531 20.268Z"
              fill="#3681FC" />
            <path
              d="M10.3381 10.5H11.7208L14.5589 16.5H13.2594L12.5837 14.9958H9.47526L8.79951 16.5H7.5L10.3381 10.5ZM9.83912 14.1891H12.2198L11.0555 11.5588H11.0139L9.83912 14.1891Z"
              fill="#3681FC" />
            <path d="M15.2837 10.5H16.5V16.5H15.2837V10.5Z" fill="#3681FC" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M10.289 20.8383H4.71558V3.22031H13.9664V7.65703C13.9664 8.12344 14.3437 8.50078 14.8101 8.50078H19.2468V14.175C19.2468 14.6414 19.6242 15.0187 20.0906 15.0187C20.557 15.0187 20.9343 14.6414 20.9343 14.175V7.65703V7.61484C20.9343 7.59609 20.932 7.57734 20.9296 7.55859C20.9296 7.55625 20.9296 7.55156 20.9273 7.54922C20.9226 7.50938 20.9156 7.47188 20.9039 7.43438V7.43203C20.8921 7.39219 20.8804 7.35469 20.864 7.31719V7.31484C20.857 7.29609 20.8476 7.27969 20.8382 7.26094V7.25859L20.8101 7.20938C20.8078 7.20703 20.8078 7.20469 20.8054 7.20234C20.796 7.18828 20.7867 7.17188 20.775 7.15781C20.775 7.15547 20.7726 7.15547 20.7726 7.15313C20.7609 7.13906 20.7492 7.12266 20.7374 7.10859C20.7351 7.10625 20.7328 7.10156 20.7281 7.09922C20.7164 7.08516 20.7023 7.07109 20.6906 7.05703L15.4054 1.77891C15.3914 1.76484 15.3796 1.75313 15.3656 1.74141C15.3632 1.73906 15.3585 1.73672 15.3562 1.73203C15.3421 1.72031 15.3281 1.70859 15.3117 1.69688C15.3093 1.69453 15.3093 1.69453 15.307 1.69453C15.2929 1.68516 15.2789 1.67344 15.2625 1.66406C15.2601 1.66172 15.2578 1.66172 15.2554 1.65938C15.239 1.65 15.2226 1.63828 15.2039 1.63125H15.2015C15.1851 1.62187 15.1664 1.6125 15.1476 1.60547H15.1453C15.1078 1.58906 15.0703 1.575 15.0304 1.56563C14.9929 1.55625 14.9554 1.54688 14.9156 1.54219C14.9132 1.54219 14.9085 1.54219 14.9062 1.53984C14.8875 1.5375 14.8687 1.53516 14.85 1.53516H4.34058C3.61636 1.53516 3.02808 2.12344 3.02808 2.84766V21.2156C3.02808 21.9398 3.61636 22.5281 4.34058 22.5281H10.289C10.7554 22.5281 11.1328 22.1508 11.1328 21.6844C11.1328 21.2156 10.7554 20.8383 10.289 20.8383ZM15.6515 6.81328V4.41328L18.0515 6.81328H15.6515Z"
              fill="black" fill-opacity="0.6" />
            <path
              d="M22.1531 20.268L18.0047 17.3078C17.8008 17.1625 17.5195 17.3078 17.5195 17.5586V19.675H13.5937C13.1273 19.675 12.75 20.0523 12.75 20.5187C12.75 20.9851 13.1273 21.3625 13.5937 21.3625H17.5195V23.4789C17.5195 23.7297 17.8031 23.875 18.0047 23.7297L22.1531 20.7695C22.3266 20.6476 22.3266 20.3922 22.1531 20.268Z"
              fill="black" fill-opacity="0.6" />
            <path
              d="M10.3381 10.5H11.7208L14.5589 16.5H13.2594L12.5837 14.9958H9.47526L8.79951 16.5H7.5L10.3381 10.5ZM9.83912 14.1891H12.2198L11.0555 11.5588H11.0139L9.83912 14.1891Z"
              fill="black" fill-opacity="0.6" />
            <path d="M15.2837 10.5H16.5V16.5H15.2837V10.5Z" fill="black" fill-opacity="0.6" />
          </svg>
        </div>
        <div style="margin-bottom: 10px; cursor: pointer" @click="handle_swich_icon(2)">
          <svg v-if="icon_active == 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
            fill="none">
            <path
              d="M11.4515 12.2391C8.47725 12.2391 6.05615 9.88361 6.05615 6.98907C6.05615 4.09454 8.47725 1.73907 11.4515 1.73907C14.4257 1.73907 16.8468 4.09454 16.8468 6.98907C16.8468 9.88361 14.4257 12.2391 11.4515 12.2391ZM11.4515 3.83907C9.63506 3.83907 8.15615 5.25236 8.15615 6.98907C8.15615 8.72579 9.63506 10.1391 11.4515 10.1391C13.2679 10.1391 14.7468 8.72579 14.7468 6.98907C14.7468 5.25236 13.2679 3.83907 11.4515 3.83907ZM15.1265 16.9641H21.4265V19.0641H15.1265V16.9641ZM15.1265 20.1141H21.4265V22.2141H15.1265V20.1141Z"
              fill="#3681FC" />
            <path
              d="M4.62661 22.2141H2.52661V14.8641C2.52661 13.9945 3.23208 13.2891 4.10161 13.2891H18.8016C19.6711 13.2891 20.3766 13.9945 20.3766 14.8641V15.9141H18.2766V15.3891H4.62661V22.2141Z"
              fill="#3681FC" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M11.4515 12.2391C8.47725 12.2391 6.05615 9.88361 6.05615 6.98907C6.05615 4.09454 8.47725 1.73907 11.4515 1.73907C14.4257 1.73907 16.8468 4.09454 16.8468 6.98907C16.8468 9.88361 14.4257 12.2391 11.4515 12.2391ZM11.4515 3.83907C9.63506 3.83907 8.15615 5.25236 8.15615 6.98907C8.15615 8.72579 9.63506 10.1391 11.4515 10.1391C13.2679 10.1391 14.7468 8.72579 14.7468 6.98907C14.7468 5.25236 13.2679 3.83907 11.4515 3.83907ZM15.1265 16.9641H21.4265V19.0641H15.1265V16.9641ZM15.1265 20.1141H21.4265V22.2141H15.1265V20.1141Z"
              fill="black" fill-opacity="0.6" />
            <path
              d="M4.62661 22.2141H2.52661V14.8641C2.52661 13.9945 3.23208 13.2891 4.10161 13.2891H18.8016C19.6711 13.2891 20.3766 13.9945 20.3766 14.8641V15.9141H18.2766V15.3891H4.62661V22.2141Z"
              fill="black" fill-opacity="0.6" />
          </svg>
        </div>
      </div>
      <div v-if="icon_active == 1" class="flex_y_center" style="
          flex: 1;
          border-left: 1px solid #eaeaea;
          border-right: 1px solid #eaeaea;
        ">
        <div style="text-align: center; font-weight: bolder; line-height: 40px">
          {{ languageData.call_word }}
        </div>
        <div style="width: 100%; overflow-y: scroll; flex: 1">
          <el-collapse v-model="activeName" accordion>
            <template v-for="(item, index) in languageData.promptsList" :key="index">
              <el-collapse-item :title="item.label" :name="index + 1">
                <template v-for="(items, i) in item.prompt" :key="i">
                  <div class="prompt" style="cursor: pointer" @click="selectProblem(items.label)">
                    {{ i + 1 }}.{{ items.label }}
                  </div>
                </template>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
      </div>
      <div v-else style="
          flex: 1;
          border-left: 1px solid #eaeaea;
          border-right: 1px solid #eaeaea;
        ">
        <div style="text-align: center; font-weight: bolder; line-height: 40px">
          {{ languageData.group_list }}
        </div>
        <div v-for="(item, index) of allGroup" :key="item.id" class="group_item" @click="swich_group(item.id)">
          <div :class="item.id == group_active_id ? 'group_active' : 'no_group_active'
            ">
            {{ item.name }}
          </div>
          <div class="group_number">
            {{ index == 0 ? "1" : item.group_member.length }}人
          </div>
        </div>
      </div>
    </div>
    <div id="chat_room" ref="chat_room" class="chat_room flex_y">
      <div class="top">
        <div class="flex_x">
          <i class="iconfont icon-caidanshouqi" :title="languageData.open_Collapse" @click="collapse_swich"></i>
          <div style="margin-left: 20px">
            {{ languageData.chat_room }}
          </div>
        </div>
        <div class="flex_x_center">
          <el-switch v-model="isHistory" active-text="" :inactive-text="languageData.lxdh"
            @change="change_isHistory_swich"></el-switch>
          <el-switch v-model="chatroom_message_swich" active-text=""
            :inactive-text="languageData.chatroom_message_close" @change="change_chatroom_message_swich"></el-switch>
          <div :title="languageData.export" style="margin: 0px 5px; cursor: pointer" @click="exportHistory">
            <svg id="Layer_1" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20" height="20" viewBox="0 0 20 20"
              enable-background="new 0 0 20 20" xml:space="preserve">
              <image id="image0" width="20" height="20" x="0" y="0" href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
                  ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjI5OyYjMTc1
                  OyYjMTg4OyYjMjI5OyYjMTM1OyYjMTg2OyI+CjxwYXRoIGlkPSJWZWN0b3IgNDMgKFN0cm9rZSki
                  IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiA4QzIgNy43MjM4
                  NiAyLjIyMzg2IDcuNSAyLjUgNy41SDRDNC4yNzYxNCA3LjUgNC41IDcuNzIzODYgNC41IDhDNC41
                  IDguMjc2MTQgNC4yNzYxNCA4LjUgNCA4LjVIM1YxM0gxM1Y4LjVIMTJDMTEuNzIzOSA4LjUgMTEu
                  NSA4LjI3NjE0IDExLjUgOEMxMS41IDcuNzIzODYgMTEuNzIzOSA3LjUgMTIgNy41SDEzLjVDMTMu
                  Nzc2MSA3LjUgMTQgNy43MjM4NiAxNCA4VjEzLjVDMTQgMTMuNzc2MSAxMy43NzYxIDE0IDEzLjUg
                  MTRIMi41QzIuMjIzODYgMTQgMiAxMy43NzYxIDIgMTMuNVY4WiIgZmlsbD0iYmxhY2siIGZpbGwt
                  b3BhY2l0eT0iMC45Ii8+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMC44NzUgNS41TDggMkw1LjEy
                  NSA1LjVINy41VjExLjVDNy41IDExLjc3NjEgNy43MjM4NiAxMiA4IDEyQzguMjc2MTQgMTIgOC41
                  IDExLjc3NjEgOC41IDExLjVWNS41SDEwLjg3NVoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9
                  IjAuOSIvPgo8L2c+Cjwvc3ZnPgo=" />
            </svg>
          </div>
          <div :title="languageData.clear_screen" style="margin: 0px 5px; cursor: pointer" @click="clear">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M16.2222 10H15.6L17 17.2C17 17.642 16.6519 18 16.2222 18H3.77778C3.34806 18 3 17.642 3 17.2L4.4 10H3.77778C3.34806 10 3 9.642 3 9.2V8.4C3 7.958 3.34806 7.6 3.77778 7.6H8.44444V3.6C8.44444 2.7164 9.14094 2 10 2C10.8591 2 11.5556 2.7164 11.5556 3.6V7.6H16.2222C16.6519 7.6 17 7.958 17 8.4V9.2C17 9.642 16.6519 10 16.2222 10ZM3.77778 17.2H6.136C6.08233 17.1172 6.05706 17.0148 6.07494 16.9092L6.54783 13.3516C6.58517 13.1344 6.78661 12.9888 6.99817 13.0272C7.20972 13.0656 7.35089 13.2732 7.31394 13.4908L6.84106 17.048C6.83133 17.1044 6.80994 17.1552 6.78117 17.2H16.2222L14.6667 10H5.33333L3.77778 17.2ZM10.7778 3.6C10.7778 3.158 10.4297 2.8 10 2.8C9.57028 2.8 9.22222 3.158 9.22222 3.6V7.6H10.7778V3.6ZM16.2222 8.4H3.77778V9.2H16.2222V8.4Z"
                fill="black" fill-opacity="0.6" />
            </svg>
          </div>
          <div :title="languageData.fullScreen" style="margin: 0px 5px; cursor: pointer" @click="chat_room_full">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15.3775 8.03764L15.3775 5.39707L13.5611 7.21346L12.6529 8.12166L11.8724 7.34112L12.7806 6.43292L14.597 4.61652L11.9564 4.61652V3.51265L15.9814 3.51265C16.2575 3.51265 16.4814 3.73651 16.4814 4.01265L16.4814 8.03764L15.3775 8.03764Z"
                fill="black" fill-opacity="0.6" />
              <path
                d="M11.9563 15.3775L14.5968 15.3775L12.7804 13.5611L11.8722 12.6529L12.6528 11.8724L13.561 12.7806L15.3774 14.597L15.3774 11.9564L16.4812 11.9564L16.4812 15.9814C16.4812 16.2575 16.2574 16.4814 15.9812 16.4814L11.9563 16.4814L11.9563 15.3775Z"
                fill="black" fill-opacity="0.6" />
              <path
                d="M4.61636 11.9563L4.61636 14.5968L6.43276 12.7804L7.34096 11.8722L8.1215 12.6528L7.21331 13.561L5.39691 15.3774L8.03748 15.3774V16.4812L4.01249 16.4812C3.73635 16.4812 3.51249 16.2574 3.51249 15.9812L3.51249 11.9563L4.61636 11.9563Z"
                fill="black" fill-opacity="0.6" />
              <path
                d="M8.03764 4.61636L5.39707 4.61636L7.21346 6.43276L8.12166 7.34096L7.34112 8.1215L6.43292 7.21331L4.61652 5.39691L4.61652 8.03748L3.51265 8.03748L3.51265 4.01249C3.51265 3.73635 3.73651 3.5125 4.01265 3.51249L8.03764 3.5125L8.03764 4.61636Z"
                fill="black" fill-opacity="0.6" />
            </svg>
          </div>
          <i class="el-icon-close" :title="languageData.close_chat" style="cursor: pointer; font-weight: bold"
            @click="colse"></i>
        </div>
      </div>
      <div id="content" ref="content" v-loading="content_loading" class="content"
        :element-loading-text="languageData.loading" element-loading-background="#fafafa">
        <div v-for="(item, index) of chat_data" :key="index" class="content_item" @click="p_card($event)">
          <!-- 自己聊天 -->
          <div v-if="item.userId == user.userId" class="flex_x content_item_self">
            <div class="item_content" v-html="item.content"></div>
            <div :title="item.userName" class="user_name_self">
              {{ item.userName.slice(0, 1) }}
            </div>
          </div>
          <!-- 别人聊天 -->
          <div v-else id="content_item_other" class="content_item_other">
            <!-- ai聊天loading -->
            <div v-if="item.ai" class="flex_x">
              <div class="user_name_other">AI</div>
              <div v-loading="loading" :element-loading-text="languageData.ai_analyze" class="loading_div"></div>
            </div>
            <!-- 用户聊天 -->
            <div v-else class="flex_x">
              <div v-if="item.userName == 'AI'" :title="item.userName" class="user_name_other"
                style="background-color: #a9d0f5">
                AI
              </div>
              <div v-else :title="item.userName" class="user_name_other">
                {{ item.userName.slice(0, 1) }}
              </div>
              <!-- 思维导图 -->
              <div v-if="index == item.index && item.is_mind_map" class="flex_x mind_map"
                :class="svg_dialog == item.index ? 'svg_dialog' : ''">
                <div v-if="svg_dialog == item.index" class="svg_dialog_colse" @click="look_mind_map(item.index)">
                  <el-icon size="16">
                    <CircleCloseFilled />
                  </el-icon>
                </div>
                <svg :id="'markmap' + item.index" style="width: 100%; height: 100%; background: #e0ebfe"
                  viewBox="0 0 500 500"></svg>
                <div class="mind_map_icon">
                  <i class="iconfont icon-quanping" style="margin-right: 7px" @click="look_mind_map(item.index)"></i>
                  <i class="iconfont icon-xiazai" @click="download_mind_map('markmap' + item.index)"></i>
                </div>
              </div>
              <!-- 普通聊天 -->
              <div v-else :id="'geneCard_' + index" class="item_content item_content_other" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-show="isShowTipResult" style="position: absolute;width: 80%;right: 30px;bottom: 95px;background: #f3f7f3;padding: 10px;border-radius: 5px;border: 1px solid #cdcdcd;max-height: 300px;
        overflow-y: auto;" v-html="tipResult"></div>
      <div v-if="!isTip" style="width:35px;height:35px;position: absolute;bottom:60px;right:0;cursor: pointer;"
        @click="changeTip">
        <svg t="1732183962525" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="12646" data-spm-anchor-id="a313x.search_index.0.i40.4ea23a81vFpOLH" width="32" height="32">
          <path
            d="M158.969786 345.90143zM350.188819 666.981806c-77.205609-39.873798-137.773303-107.405457-168.693147-189.473042 30.917844 82.067585 91.485537 149.599243 168.693147 189.473042 15.167923 7.83396 27.75986 19.197903 37.125812 32.767834-9.365953-13.569931-21.955889-24.933874-37.125812-32.767834zM400.092566 724.935513zM590.187604 424.653032c-9.295953 1.89199-15.149923 6.561967-23.023883 12.865935-11.983939 9.585952-26.903864 21.517891-55.163721 21.517891s-43.179782-11.92994-55.163721-21.517891c-7.87396-6.305968-13.725931-10.973944-23.023883-12.865935l60.247695 331.364324c0.191999 1.061995 0.269999 2.113989 0.271998 3.155984h35.335822c0-1.041995 0.08-2.093989 0.271998-3.155984l60.247695-331.364324zM171.835721 259.169869z"
            fill="#cdcdcd" p-id="12647" data-spm-anchor-id="a313x.search_index.0.i42.4ea23a81vFpOLH" class="selected">
          </path>
          <path
            d="M493.246095 0.491178a360.408177 360.408177 0 0 1 40.335796 0.221998C415.488488 11.365123 320.19897 163.734352 317.838982 345.90143c-1.807991 139.605294 49.071752 261.178679 124.117372 320.936377l-53.25973-292.920518c-1.741991-9.585952 4.621977-18.775905 14.213928-20.517897 9.541952-1.87999 18.785905 4.603977 20.525896 14.207928l3.799981 20.903895c25.979869 0.951995 40.175797 12.247938 51.647739 21.423891 10.379947 8.311958 17.233913 13.79393 33.111832 13.793931s22.733885-5.481972 33.111833-13.793931c11.471942-9.175954 25.66787-20.473896 51.647738-21.423891l3.799981-20.903895c1.741991-9.603951 10.913945-16.067919 20.525896-14.207928a17.647911 17.647911 0 0 1 14.213928 20.517897l-70.047645 385.258051h52.675733v1.067994c0-39.127802 21.395892-75.411619 56.145716-93.395527 113.467426-58.723703 191.027034-177.161104 191.027034-313.740413C865.102214 151.876412 696.777065-10.034769 493.246095 0.491178zM400.288565 725.56751l0 0z"
            fill="#e6e6e6" p-id="12648" data-spm-anchor-id="a313x.search_index.0.i38.4ea23a81vFpOLH" class=""></path>
          <path
            d="M456.836279 437.516967c11.983939 9.585952 26.903864 21.517891 55.163721 21.517891s43.179782-11.92994 55.163721-21.517891c7.87396-6.305968 13.725931-10.973944 23.023883-12.865935l6.571967-36.143817c-25.979869 0.951995-40.175797 12.247938-51.647738 21.423891-10.379947 8.311958-17.233913 13.79393-33.111833 13.793931s-22.733885-5.481972-33.111833-13.793931c-11.471942-9.175954-25.66787-20.473896-51.647738-21.423891l6.571967 36.143817c9.293953 1.89199 15.149923 6.561967 23.023883 12.865935z"
            fill="#cdcdcd" p-id="12649" data-spm-anchor-id="a313x.search_index.0.i44.4ea23a81vFpOLH" class="selected">
          </path>
          <path
            d="M406.068536 759.17334h52.675733l-16.787915-92.335533c-75.04562-59.755698-125.925363-181.327083-124.117372-320.936377C320.19897 163.734352 415.488488 11.365123 533.581891 0.713176a359.924179 359.924179 0 0 0-40.335796-0.221998c-0.094 0.004-0.189999 0.006-0.283999 0.011999C342.404858 8.405137 215.615499 113.712605 173.657712 252.891901a352.620216 352.620216 0 0 0-1.821991 6.277968c-0.357998 1.267994-0.725996 2.531987-1.069995 3.803981a345.588252 345.588252 0 0 0-11.79794 82.92558 355.578201 355.578201 0 0 0 5.277973 68.469654 350.298228 350.298228 0 0 0 17.249913 63.14168c30.917844 82.065585 91.487537 149.597243 168.693147 189.473042 15.167923 7.83396 27.75986 19.195903 37.125812 32.767834 0.101999 0.147999 0.189999 0.303998 0.289998 0.451998a105.691465 105.691465 0 0 1 12.485937 24.731875c0.072 0.207999 0.125999 0.423998 0.195999 0.631997a107.209458 107.209458 0 0 1 5.779971 34.685824v-1.081994h0.002z"
            fill="#dbdbdb" p-id="12650" data-spm-anchor-id="a313x.search_index.0.i39.4ea23a81vFpOLH" class=""></path>
          <path
            d="M476.690179 759.17334h17.64191c0-1.041995-0.08-2.093989-0.271998-3.155984l-60.247695-331.364324-6.571967-36.143817-3.799981-20.903895c-1.741991-9.603951-10.983944-16.085919-20.525896-14.207928a17.647911 17.647911 0 0 0-14.213928 20.517897l53.25973 292.920518 16.787915 92.335533h17.94391v0.002zM529.939909 756.019356a17.86391 17.86391 0 0 0-0.271998 3.155984h35.58582l70.047645-385.258051c1.741991-9.585952-4.621977-18.775905-14.213928-20.517897-9.611951-1.861991-18.785905 4.603977-20.525896 14.207928l-3.799981 20.903895-6.571967 36.143817-60.249695 331.364324z"
            fill="#cdcdcd" p-id="12651" data-spm-anchor-id="a313x.search_index.0.i41.4ea23a81vFpOLH" class="selected">
          </path>
          <path
            d="M476.690179 988.690179c0 19.501901 15.80792 35.309821 35.309821 35.309821 19.501901 0 35.309821-15.80792 35.309821-35.309821v-17.655911h-70.619642v17.655911z"
            fill="#96A5B9" p-id="12652"></path>
          <path
            d="M476.690179 872.818765l138.945297-19.8479a17.87791 17.87791 0 0 1 2.295988-0.163999v-35.475821l-141.241285 20.177898v35.309822zM406.330535 918.060536c0.048 0.407998 0.098 0.815996 0.157999 1.221994-0.058-0.405998-0.107999-0.813996-0.157999-1.221994zM425.586437 945.484397zM407.680528 924.386504zM565.253731 759.17334h-88.563552v43.025782l138.945297-19.8479a17.87791 17.87791 0 0 1 2.295988-0.163999V759.17334h-52.677733zM476.690179 908.128586v5.769971a35.309821 35.309821 0 0 0 19.519901 31.58384l51.099741 25.551871 51.099742-25.551871a35.305821 35.305821 0 0 0 19.519901-31.58384v-25.947869l-141.239285 20.177898z"
            fill="#BEC8DC" p-id="12653"></path>
          <path
            d="M476.690179 759.17334h-70.621643v53.115731l70.621643-10.089949zM476.690179 837.508943l-68.325655 9.761951a17.87791 17.87791 0 0 1-2.295988 0.163999v35.475821l70.621643-10.087949v-35.313822zM476.690179 913.902557v-5.771971l-68.325655 9.761951a17.579911 17.579911 0 0 1-2.029989 0.167999c0.048 0.407998 0.098 0.815996 0.157999 1.221994a35.355821 35.355821 0 0 0 1.185994 5.107974 35.307821 35.307821 0 0 0 13.633931 18.571906 35.219822 35.219822 0 0 0 4.271978 2.525987l51.099742 25.551871h70.619642l-51.099741-25.551871a35.325821 35.325821 0 0 1-19.513901-31.58584z"
            fill="#AAB6CA" p-id="12654"></path>
          <path
            d="M615.635476 852.970865l-138.945297 19.8479-70.619643 10.087949-2.647987 0.379998a17.475912 17.475912 0 0 0-15.005924 17.301912c0 9.94595 8.237958 17.73191 17.91991 17.469912a17.579911 17.579911 0 0 0 2.029989-0.167999l68.325655-9.761951 141.241285-20.177898 2.647987-0.379998a17.475912 17.475912 0 0 0 15.005924-17.301912c0-9.85595-8.089959-17.577911-17.655911-17.465912a18.283908 18.283908 0 0 0-2.295988 0.167999zM615.635476 782.349222l-138.945297 19.8479-70.619643 10.087949-2.647987 0.379998a17.475912 17.475912 0 0 0-15.005924 17.301913c0 9.85595 8.089959 17.577911 17.655911 17.465911a17.279913 17.279913 0 0 0 2.295988-0.163999l68.325655-9.761951 141.241285-20.177898 2.647987-0.379998a17.475912 17.475912 0 0 0 15.005924-17.301912c0-9.85595-8.089959-17.577911-17.655911-17.465912-0.759996 0.014-1.525992 0.058-2.297988 0.167999z"
            fill="#96A5B9" p-id="12655"></path>
        </svg>
      </div>
      <div v-if="isTip" style="width:35px;height:35px;position: absolute;bottom:60px;right:0;cursor: pointer;"
        @click="changeTip">
        <svg t="1732183902060" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="12192" width="32" height="32">
          <path
            d="M158.969786 345.90143zM350.188819 666.981806c-77.205609-39.873798-137.773303-107.405457-168.693147-189.473042 30.917844 82.067585 91.485537 149.599243 168.693147 189.473042 15.167923 7.83396 27.75986 19.197903 37.125812 32.767834-9.365953-13.569931-21.955889-24.933874-37.125812-32.767834zM400.092566 724.935513zM590.187604 424.653032c-9.295953 1.89199-15.149923 6.561967-23.023883 12.865935-11.983939 9.585952-26.903864 21.517891-55.163721 21.517891s-43.179782-11.92994-55.163721-21.517891c-7.87396-6.305968-13.725931-10.973944-23.023883-12.865935l60.247695 331.364324c0.191999 1.061995 0.269999 2.113989 0.271998 3.155984h35.335822c0-1.041995 0.08-2.093989 0.271998-3.155984l60.247695-331.364324zM171.835721 259.169869z"
            fill="#FFCD73" p-id="12193"></path>
          <path
            d="M493.246095 0.491178a360.408177 360.408177 0 0 1 40.335796 0.221998C415.488488 11.365123 320.19897 163.734352 317.838982 345.90143c-1.807991 139.605294 49.071752 261.178679 124.117372 320.936377l-53.25973-292.920518c-1.741991-9.585952 4.621977-18.775905 14.213928-20.517897 9.541952-1.87999 18.785905 4.603977 20.525896 14.207928l3.799981 20.903895c25.979869 0.951995 40.175797 12.247938 51.647739 21.423891 10.379947 8.311958 17.233913 13.79393 33.111832 13.793931s22.733885-5.481972 33.111833-13.793931c11.471942-9.175954 25.66787-20.473896 51.647738-21.423891l3.799981-20.903895c1.741991-9.603951 10.913945-16.067919 20.525896-14.207928a17.647911 17.647911 0 0 1 14.213928 20.517897l-70.047645 385.258051h52.675733v1.067994c0-39.127802 21.395892-75.411619 56.145716-93.395527 113.467426-58.723703 191.027034-177.161104 191.027034-313.740413C865.102214 151.876412 696.777065-10.034769 493.246095 0.491178zM400.288565 725.56751l0 0z"
            fill="#FFCD73" p-id="12194"></path>
          <path
            d="M456.836279 437.516967c11.983939 9.585952 26.903864 21.517891 55.163721 21.517891s43.179782-11.92994 55.163721-21.517891c7.87396-6.305968 13.725931-10.973944 23.023883-12.865935l6.571967-36.143817c-25.979869 0.951995-40.175797 12.247938-51.647738 21.423891-10.379947 8.311958-17.233913 13.79393-33.111833 13.793931s-22.733885-5.481972-33.111833-13.793931c-11.471942-9.175954-25.66787-20.473896-51.647738-21.423891l6.571967 36.143817c9.293953 1.89199 15.149923 6.561967 23.023883 12.865935z"
            fill="#FFFFFF" p-id="12195"></path>
          <path
            d="M406.068536 759.17334h52.675733l-16.787915-92.335533c-75.04562-59.755698-125.925363-181.327083-124.117372-320.936377C320.19897 163.734352 415.488488 11.365123 533.581891 0.713176a359.924179 359.924179 0 0 0-40.335796-0.221998c-0.094 0.004-0.189999 0.006-0.283999 0.011999C342.404858 8.405137 215.615499 113.712605 173.657712 252.891901a352.620216 352.620216 0 0 0-1.821991 6.277968c-0.357998 1.267994-0.725996 2.531987-1.069995 3.803981a345.588252 345.588252 0 0 0-11.79794 82.92558 355.578201 355.578201 0 0 0 5.277973 68.469654 350.298228 350.298228 0 0 0 17.249913 63.14168c30.917844 82.065585 91.487537 149.597243 168.693147 189.473042 15.167923 7.83396 27.75986 19.195903 37.125812 32.767834 0.101999 0.147999 0.189999 0.303998 0.289998 0.451998a105.691465 105.691465 0 0 1 12.485937 24.731875c0.072 0.207999 0.125999 0.423998 0.195999 0.631997a107.209458 107.209458 0 0 1 5.779971 34.685824v-1.081994h0.002z"
            fill="#FFB969" p-id="12196"></path>
          <path
            d="M476.690179 759.17334h17.64191c0-1.041995-0.08-2.093989-0.271998-3.155984l-60.247695-331.364324-6.571967-36.143817-3.799981-20.903895c-1.741991-9.603951-10.983944-16.085919-20.525896-14.207928a17.647911 17.647911 0 0 0-14.213928 20.517897l53.25973 292.920518 16.787915 92.335533h17.94391v0.002zM529.939909 756.019356a17.86391 17.86391 0 0 0-0.271998 3.155984h35.58582l70.047645-385.258051c1.741991-9.585952-4.621977-18.775905-14.213928-20.517897-9.611951-1.861991-18.785905 4.603977-20.525896 14.207928l-3.799981 20.903895-6.571967 36.143817-60.249695 331.364324z"
            fill="#FFE8C0" p-id="12197"></path>
          <path
            d="M476.690179 988.690179c0 19.501901 15.80792 35.309821 35.309821 35.309821 19.501901 0 35.309821-15.80792 35.309821-35.309821v-17.655911h-70.619642v17.655911z"
            fill="#96A5B9" p-id="12198"></path>
          <path
            d="M476.690179 872.818765l138.945297-19.8479a17.87791 17.87791 0 0 1 2.295988-0.163999v-35.475821l-141.241285 20.177898v35.309822zM406.330535 918.060536c0.048 0.407998 0.098 0.815996 0.157999 1.221994-0.058-0.405998-0.107999-0.813996-0.157999-1.221994zM425.586437 945.484397zM407.680528 924.386504zM565.253731 759.17334h-88.563552v43.025782l138.945297-19.8479a17.87791 17.87791 0 0 1 2.295988-0.163999V759.17334h-52.677733zM476.690179 908.128586v5.769971a35.309821 35.309821 0 0 0 19.519901 31.58384l51.099741 25.551871 51.099742-25.551871a35.305821 35.305821 0 0 0 19.519901-31.58384v-25.947869l-141.239285 20.177898z"
            fill="#BEC8DC" p-id="12199"></path>
          <path
            d="M476.690179 759.17334h-70.621643v53.115731l70.621643-10.089949zM476.690179 837.508943l-68.325655 9.761951a17.87791 17.87791 0 0 1-2.295988 0.163999v35.475821l70.621643-10.087949v-35.313822zM476.690179 913.902557v-5.771971l-68.325655 9.761951a17.579911 17.579911 0 0 1-2.029989 0.167999c0.048 0.407998 0.098 0.815996 0.157999 1.221994a35.355821 35.355821 0 0 0 1.185994 5.107974 35.307821 35.307821 0 0 0 13.633931 18.571906 35.219822 35.219822 0 0 0 4.271978 2.525987l51.099742 25.551871h70.619642l-51.099741-25.551871a35.325821 35.325821 0 0 1-19.513901-31.58584z"
            fill="#AAB6CA" p-id="12200"></path>
          <path
            d="M615.635476 852.970865l-138.945297 19.8479-70.619643 10.087949-2.647987 0.379998a17.475912 17.475912 0 0 0-15.005924 17.301912c0 9.94595 8.237958 17.73191 17.91991 17.469912a17.579911 17.579911 0 0 0 2.029989-0.167999l68.325655-9.761951 141.241285-20.177898 2.647987-0.379998a17.475912 17.475912 0 0 0 15.005924-17.301912c0-9.85595-8.089959-17.577911-17.655911-17.465912a18.283908 18.283908 0 0 0-2.295988 0.167999zM615.635476 782.349222l-138.945297 19.8479-70.619643 10.087949-2.647987 0.379998a17.475912 17.475912 0 0 0-15.005924 17.301913c0 9.85595 8.089959 17.577911 17.655911 17.465911a17.279913 17.279913 0 0 0 2.295988-0.163999l68.325655-9.761951 141.241285-20.177898 2.647987-0.379998a17.475912 17.475912 0 0 0 15.005924-17.301912c0-9.85595-8.089959-17.577911-17.655911-17.465912-0.759996 0.014-1.525992 0.058-2.297988 0.167999z"
            fill="#96A5B9" p-id="12201"></path>
        </svg>
      </div> -->
      <div class="input flex_x">
        <el-input ref="chat_input" v-model="chat_content" type="textarea" :autosize="{ minRows: 2, maxRows: 10 }"
          :placeholder="languageData.aite_ai_desc" :clearable="true" :autofocus="true"
          @input="handle_chat_content_change" @keydown.enter="send_message">
        </el-input>
        <img src="../../assets/chat/send.png" class="send_icon" @click="send_message" />
      </div>
      <div v-if="aite && isHistory" class="aite">
        <div v-for="(item, index) of users" :key="index" class="aite_item" :class="index == aite_index ? 'active' : ''"
          @click="handle_aite(item.userName)">
          <div v-if="index < 2">@{{ item.userName }}</div>
        </div>
      </div>
      <div v-if="aite && !isHistory" class="aite">
        <div v-for="(item, index) of users" :key="index" class="aite_item" :class="index == aite_index ? 'active' : ''"
          @click="handle_aite(item.userName)">
          <div>@{{ item.userName }}</div>
        </div>
      </div>
      <div v-if="aite_card" class="aite">
        <div v-for="(item, index) of all_nodes" :key="index" class="aite_item"
          :class="index == aite_index ? 'active' : ''">
          <div v-if="item.type == 'icon-theme'" class="aite_card"
            @click="handle_aite_card(item.properties.topic, item.id)">
            @{{ languageData.start }}：{{ item.properties.topic }}
          </div>
          <!-- 文本 -->
          <div v-else-if="
            item.type == 'icon-card' && item.properties.radio == 'content'
          " class="aite_card" @click="
            handle_aite_card(removeHTMLTags(item.properties.content), item.id)
            ">
            @{{ languageData.card }}：{{
              removeHTMLTags(item.properties.content)
            }}
          </div>
          <!-- 文档 -->
          <div v-else-if="
            item.type == 'icon-card' && item.properties.radio == 'fileList'
          " class="aite_card" @click="handle_aite_file(item)">
            @{{ languageData.card }}：{{ item.properties.fileList[0].name }}
          </div>
          <div v-else-if="item.type == 'icon-stage'" class="aite_card"
            @click="handle_aite_card(item.properties.stageName, item.id)">
            @{{ languageData.stage }}：{{ item.properties.stageName }}
          </div>
          <div v-else-if="item.type == 'icon-task'" class="aite_card" @click="
            handle_aite_card(
              removeHTMLTags(item.properties.taskContent),
              item.id
            )
            ">
            @{{ languageData.task }}：{{
              removeHTMLTags(item.properties.taskContent)
            }}
          </div>
          <div v-else-if="item.type == 'icon-select'" class="aite_card"
            @click="handle_aite_card(item.properties.selectContent, item.id)">
            @{{ languageData.select }}：{{ item.properties.selectContent }}
          </div>
          <div v-else-if="item.type == 'icon-AI' || item.type == 'icon-markdown'" class="aite_card"
            @click="handle_aite_card(item.properties.aiContent, item.id)">
            @{{ languageData.aiCard }}：{{ item.properties.aiContent }}
          </div>
          <div v-else-if="item.type == 'icon-new-AI'" class="aite_card"
            @click="handle_aite_card(item.properties.aiContent, item.id)">
            @{{ languageData.aiCard }}：{{ item.properties.aiContent }}
          </div>
        </div>
      </div>
      <div v-if="aite_ai || aite_claude" class="aite">
        <div v-if="user.organizeid != '568ee2eb-e7e7-11ed-8271-005056b86db5'" class="aite_item" @click="handle_aite(languageData.aidh)">
          {{ languageData.aidh }}
        </div>
        <div v-if="aite_ai && user.organizeid != '568ee2eb-e7e7-11ed-8271-005056b86db5'" class="aite_item" @click="handle_aite(languageData.cardfx)">
          {{ languageData.cardfx }}
        </div>
        <div v-if="aite_ai && user.organizeid != '568ee2eb-e7e7-11ed-8271-005056b86db5'" class="aite_item" @click="handle_aite(languageData.userxwfx)">
          {{ languageData.userxwfx }}
        </div>
        <div v-if="aite_ai" class="aite_item" @click="handle_aite(languageData.agent)">
          {{ languageData.agent }}
        </div>
      </div>
      <div v-if="analyse_user" class="aite">
        <div v-for="(item, index) of $store.state.groupUsers" :key="index" class="aite_item"
          :class="index == aite_index ? 'active' : ''" @click="handle_analyse_user(item)">
          <div>@{{ item.username }}</div>
        </div>
      </div>
      <div v-if="aite_ai_agent" class="aite">
        <div v-if="ai_agent_loading" id="agentList" v-loading="ai_agent_loading"
          :element-loading-text="languageData.loading" class="loading_div"></div>
        <div v-for="(item, index) of agentList" v-else :key="index" class="aite_item"
          :class="index == aite_index ? 'active' : ''" @click="handle_agent(item)">
          <div>@{{ item.assistantName }}</div>
        </div>
      </div>
    </div>
    <div id="export-1">
      <el-dialog :model-value="dialogVisible" width="20%" :destroy-on-close="true" :before-close="handleClose"
        :modal-append-to-body="false" :close-on-click-modal="false" :show-close="false">
        <template #header>
          <div style="width: 100%; position: relative">
            <span class="el-dialog__title">{{ languageData.export }}</span>
            <button type="button" class="close" style="top: 4px; right: 0">
              <img v-if="hoverClose" src="../../assets/close_pre.png" width="16" height="16" @mouseout="blurClose"
                @click="handleClose" />
              <img v-else src="../../assets/close_nor.png" width="16" height="16" @mouseover="focusClose" />
            </button>
          </div>
        </template>
        <span style="
            display: flex;
            height: 100%;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          ">
          <div v-if="isCanExport"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-evenly;
              width: 100%;
            ">
            {{ languageData.data }}
            <el-radio v-model="radio1" value="1" label="1" style="margin-bottom: 0; margin-right: 10px">{{
              languageData.chatData }}</el-radio>
            <el-radio v-model="radio1" value="3" label="3" style="margin-bottom: 0">{{ languageData.monitorData
              }}</el-radio>
          </div>
          <div style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-evenly;
              width: 100%;
            ">
            {{ languageData.fileFormat }}
            <el-radio v-model="radio" value="1" label="1" style="margin-bottom: 0; margin-right: 10px">{{
              languageData.tip105 }}</el-radio>
            <el-radio v-model="radio" value="3" label="3" style="margin-bottom: 0">{{ languageData.jsonFiles
              }}</el-radio>
          </div>
        </span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">{{ languageData.close }}</el-button>
            <el-button type="primary" @click="confirmExport">{{
              languageData.confirm
            }}</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import MarkdownIt from "markdown-it";
import { Transformer } from "markmap-lib";
import { ElNotification as Notification } from "element-plus";
import { removeHTMLTags, add_8h, formatDateTime } from "../../tools/utils.js";
import { v4 as uuidv4 } from "uuid";
import LogicFlow from "@logicflow/core";
export default {
  name: "ChatRoom",
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    badge_number: { type: Number, default: undefined },
    lf: LogicFlow,
    allGroup: { type: Array, default: undefined },
  },
  emits: ["p_card", "close", "badge_number", 'geneMarkdownCard'],
  data() {
    return {
      content_loading: true,
      chat_content: "",
      monitorData: [],
      chat_data: [],
      chat_data_all: [],
      user: {
        userId: "asaqwqwqwsd",
        userName: "james",
      },
      isHistory: false,
      promptsList: false,
      languageData: {},
      activeName: "1",
      question_type: 0,
      source: null,
      listener_sse_timer: null,
      base_url: "https://gpt.cocorobo.cn",
      claude3_url: "https://claude3.cocorobo.cn",
      users: [
        {
          userId: "000",
          userName: "GPT AI",
        },
        // {
        //   userId: "111",
        //   userName: "Claude3 AI",
        // },
      ],
      sinfo_id: "", //源信息用户的id
      userIds: [],
      aite: false,
      aite_ai: false,
      aite_claude: false,
      is_claude: false,
      aite_card: false,
      analyse_user: false,
      aite_ai_agent: false,
      ai_agent_loading: false,
      aite_index: 0,
      aite_name: "",
      ai_source: null,
      loading: false,
      svg_dialog: "lyt",
      is_chatroom_full_screen: false,
      chatroom_message_swich: true,
      badge_number_copy: this.badge_number,
      all_nodes: [],
      agentList: [],
      shareAgentList: [],
      assistant_id: "",
      fild_url: "",
      fild_name: "",
      is_fild: false,
      card_id: "", //点击聊天室卡片的id
      file_id: "",
      icon_active: 2,
      group_active_id: this.$store.state.group_active_id,
      promptsListHeigth: 0,
      isEnglish: false,
      dialogVisible: false,
      radio1: "1",
      radio: "1",
      hoverClose: false,
      agentHistory: "",
      isTip: false,
      isTipTimeInterval: null,
      historyData: "",
      isShowTipResult: false,
      tipResult: "",
      isCanExport: false,
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          $(".el-dialog").css("width", "20%");
          $(".el-dialog").css({
            height: "auto",
          });
        });
      }
    },
    badge_number_copy(newval) {
      this.$emit("badge_number", newval, this.chatroom_message_swich);
      this.$nextTick(() => {
        this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
      });
    },
    "$store.state.group_member_active"(newVal) {
      let that = this;
      this.userIds = ["asaqwqwqwsd"];
      this.users = [
        {
          userId: "000",
          userName: "GPT AI",
        },
        // {
        //   userId: "111",
        //   userName: "Claude3 AI",
        // },
      ];
      this.users = this.users.concat(newVal);
      newVal.forEach((item) => {
        that.userIds.push(item.userId);
      });
    },
    aite_name(newVal) {
      console.log(newVal);
    },
    "$store.state.group_active_id"(newVal) {
      this.group_active_id = newVal;
      this.get_history(newVal);
      this.get_message(newVal);
      // let that = this;
      // clearInterval(that.isTipTimeInterval)
      // that.getData(newVal)
      // setInterval(function () {
      //   that.getData(newVal);
      // }, 180000)
    },
  },
  mounted() {
    //console.log(this.allGroup)
    this.promptsListHeigth = this.$refs.chat_room.offsetHeight - 21;
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.isEnglish = false;
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.isEnglish = false;
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.isEnglish = true;
      this.languageData = this.enLanguage;
    } else {
      this.isEnglish = false;
      this.languageData = this.zhHansLanguage;
    }
    let gid = window.location.href.split("&gid=")[1];
    //获取用户信息
    if (
      window.location.href.includes("localhost") ||
      window.location.href.includes("192.168")
    ) {
      this.user.userId = "asaqwqwqwsd";
      this.user.organizeid = "45facc0a-1211-11ec-80ad-005056b86db5"
      if(gid == "") {
        this.isCanExport = true
      }
      else {
        this.isCanExport = false
      }
    } else {
      if (top.US.userInfo) {
        this.user.userId = top.US.userInfo.userid;
        this.user.userName = top.US.userInfo.username;
        this.user.organizeid = top.US.userInfo.organizeid
        if(gid == "" || top?.US?.userInfo.role == 1) {
          this.isCanExport = true
        }
        else {
          this.isCanExport = false
        }
      }
    }
    this.get_userIds();
    this.get_message(this.$store.state.group_active_id);
    this.get_history(this.$store.state.group_active_id);
    let that = this;
    // that.getData(that.$store.state.group_active_id)
    // setInterval(function () {
    //   that.getData(that.$store.state.group_active_id);
    // }, 180000)
    that.$nextTick(() => {
      $(document).on("click", function (e) {
        that.bindClick(e)
      })
    })
  },
  methods: {
    changeTip() {
      let that = this;
      if(that.tipResult != "") {
        clearInterval(that.isTipTimeInterval)
        that.isShowTipResult = !that.isShowTipResult
        that.isTip = false;
      }
    },
    bindClick(e) {
      let that = this;
      const tagName = e.target.dataset.tagname;
      if (tagName === 'geneCard') {
        let index = e.target.dataset.index
        that.$nextTick(() => {
          if ($("#geneCard_" + index)) {
            that.geneCard((that.chat_data[index].content).split("<div style='display:flex;align-items: center;'>")[0]);
          }
        });
      }
    },
    geneCard(text) {
      this.$emit("geneMarkdownCard", text)
    },
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handleClose() {
      this.radio = "1";
      this.radio1 = "1";
      this.dialogVisible = false;
      if (!this.is_chatroom_full_screen) {
        $(".DiagramRBToolbarDiv").css("z-index", "10")
      }
    },
    confirmExport() {
      this.export_to_excel();
    },
    collapse_swich() {
      this.promptsList = !this.promptsList;
      let chat_room = document.getElementById("chat_room");
      if (this.promptsList) {
        chat_room.style.borderLeft = "none";
        chat_room.style.borderTopLeftRadius = "0";
        chat_room.style.borderBottomLeftRadius = "0";
      } else {
        chat_room.style.borderLeft = "1px solid #ccccce";
        chat_room.style.borderTopLeftRadius = "5px";
        chat_room.style.borderBottomLeftRadius = "5px";
      }
    },
    swich_group(id) {
      this.chat_content = "";
      this.group_active_id = id;
      this.$store.commit("set_group_active_id", id);
      this.content_loading = true;
      this.get_history(id);
    },
    handle_swich_icon(value) {
      this.icon_active = value;
    },
    removeHTMLTags,
    init_badge_number_copy() {
      this.badge_number_copy = 0;
      this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
      this.$nextTick(() => {
        this.promptsListHeigth = this.$refs.chat_room.offsetHeight - 21;
      });
    },
    change_chatroom_message_swich(value) {
      this.chatroom_message_swich = value;
      this.$emit(
        "badge_number",
        this.badge_number_copy,
        this.chatroom_message_swich
      );
    },
    change_isHistory_swich(value) {
      this.isHistory = value;
    },
    exportHistory() {
      $(".DiagramRBToolbarDiv").css("z-index", "1032")
      this.dialogVisible = true
    },
    chat_room_full() {
      if (this.is_chatroom_full_screen) {
        $(".DiagramRBToolbarDiv").css("z-index", "10")
      }
      else {
        $(".DiagramRBToolbarDiv").css("z-index", "1032")
      }
      this.is_chatroom_full_screen = !this.is_chatroom_full_screen;
      this.$nextTick(() => {
        this.promptsListHeigth = this.$refs.chat_room.offsetHeight - 21;
      });
    },
    getData(roomId) {
      let that = this;
      that.historyData = "";
      var config = {
        method: "post",
        url: this.base_url + "/get_chat_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          groupId: roomId,
        },
      };
      that.$ajax(config)
        .then((res) => {
          //console.log(res)
          let chatHistory = [];
          try {
            chatHistory = JSON.parse(res.data.FunctionResponse.result);
          } catch {
            chatHistory = res.data.FunctionResponse.result;
          }
          if (chatHistory.length > 0) {
            //先颠倒顺序，再循环
            chatHistory.forEach((item) => {
              if (item.username != "AI" && !item.problem.includes("color:#007bff;") && !item.problem.includes(that.languageData.userxwfx)) {
                that.historyData += item.username + ":" + item.problem + "\n\n"
              }
            });
            that.listen(that.historyData)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_history(roomId) {
      let that = this;
      this.chat_data = [];
      var config = {
        method: "post",
        url: this.base_url + "/get_chat_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          groupId: roomId,
        },
      };
      this.$ajax(config)
        .then((res) => {
          //console.log(res)
          that.content_loading = false;
          let chatHistory = [];
          try {
            chatHistory = JSON.parse(res.data.FunctionResponse.result);
          } catch {
            chatHistory = res.data.FunctionResponse.result;
          }
          if (chatHistory.length > 0) {
            //先颠倒顺序，再循环
            chatHistory.forEach((item, i) => {
              that.chat_data.push({
                userName: item.username,
                userId: item.userid,
                content: item.filename
                  ? item.problem +
                  "<div style='color:rgb(113, 124, 141);font-size: 14px;text-align: right;'>来源：" +
                  item.filename +
                  "</div><div style='display:flex;align-items: center;'>" +
                  "<svg data-tagname=\"geneCard\" data-index=\"" + i.toString() + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
                  add_8h(item.createtime) +
                  "</div></div>"
                  : item.problem +
                  "<div style='display:flex;align-items: center;'><svg data-tagname=\"geneCard\" data-index=\"" + i.toString() + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
                  add_8h(item.createtime) +
                  "</div></div>",
                ai: false,
                index: that.chat_data.length,
                is_mind_map: item.isMindMap ? true : false,
              });
            });
            that.$nextTick(() => {
              //处理思维导图
              that.chat_data.forEach((item) => {
                if (item.is_mind_map) {
                  let markdown = item.content;
                  if (markdown.includes("```")) {
                    markdown = markdown.split("```")[1];
                    if (markdown.includes("markdown")) {
                      markdown = markdown.split("markdown")[1];
                    }
                  }
                  that.handle_mind_map(markdown, `#markmap${item.index}`);
                }
              });
              that.$refs.content.scrollTop = that.$refs.content.scrollHeight;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMonitorData(roomId) {
      let that = this;
      this.monitorData = [];
      var config = {
        method: "post",
        url: this.base_url + "/get_chat_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          groupId: roomId,
        },
      };
      await this.$ajax(config)
        .then((res) => {
          let chatHistory = [];
          try {
            chatHistory = JSON.parse(res.data.FunctionResponse.result);
          } catch {
            chatHistory = res.data.FunctionResponse.result;
          }
          if (chatHistory.length > 0) {
            //先颠倒顺序，再循环
            chatHistory.forEach((item) => {
              that.monitorData.push({
                userName: item.username,
                userId: item.userid,
                content: item.problem +
                  "<div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
                  add_8h(item.createtime) +
                  "</div>",
                ai: false,
                index: that.chat_data.length,
                is_mind_map: item.isMindMap ? true : false,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_userIds() {
      let that = this;
      that.userIds = ["asaqwqwqwsd"];
      if (localStorage.user) {
        that.users = that.users.concat(JSON.parse(localStorage.user));
        JSON.parse(localStorage.user).forEach((item) => {
          that.userIds.push(item.userId);
        });
      }
    },
    get_message(roomId) {
      let that = this;
      // 创建EventSource
      if (this.source) {
        this.source.close();
      }
      this.source = new EventSource(
        this.base_url + "/get_message/" + roomId + "/" + this.user.userId
      );
      // 监听流数据的返回
      this.source.onmessage = function (e) {
        let eData = JSON.parse(e.data);
        that.agentHistory += eData.username + ":" + eData.content + "\n\n"
        if (eData.content.includes(that.user.userName)) {
          Notification({
            title: eData.username + "@你",
            dangerouslyUseHTMLString: true,
            message: eData.content,
            position: "bottom-right",
            onClick: that.open,
          });
        }
        if (eData.is_mind_map) {
          that.chat_data.push({
            userName: eData.username,
            userId: eData.userId,
            content: eData.content,
            ai: false,
            is_mind_map: eData.is_mind_map,
            index: that.chat_data.length,
          });
          that.$nextTick(() => {
            let markdown = eData.content;
            if (markdown.includes("```")) {
              markdown = markdown.split("```")[1];
              if (markdown.includes("markdown")) {
                markdown = markdown.split("markdown")[1];
              }
            }
            that.handle_mind_map(markdown, `#markmap${that.chat_data.length}`);
          });
        } else {
          that.chat_data.push({
            userName: eData.username,
            userId: eData.userId,
            content: eData.filename
              ? eData.content +
              "<div style='display:flex;align-items: center;'><svg data-tagname=\"geneCard\" data-index=\"" + that.chat_data.length.toString() + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
              formatDateTime(new Date().getTime()) +
              "</div></div><div style='color:rgb(113, 124, 141);font-size: 14px;text-align: right;'>来源：" +
              eData.filename +
              "</div>"
              : eData.content +
              "<div style='display:flex;align-items: center;'><svg data-tagname=\"geneCard\" data-index=\"" + that.chat_data.length.toString() + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
              formatDateTime(new Date().getTime()) +
              "</div></div>",
            ai: false,
            is_mind_map: eData.is_mind_map,
            index: that.chat_data.length,
          });
        }
        that.badge_number_copy++;
      };
      //长时间没有东西返回sse会自动断开，这里检测他是否自动断开，如果断开那重连
      this.source.onopen = function () {
        console.log("聊天室重连了");
        that.listener_sse_timer = setInterval(that.listener_sse(), 20000);
      };
    },
    listener_sse() {
      if (this.source.readyState != 1) {
        console.log("聊天室断开了");
        clearInterval(this.listener_sse_timer);
        this.get_message(localStorage.roomId);
      }
    },
    async send_message(e) {
      e.preventDefault();
      let that = this;
      let temp = "";
      if (this.chat_content == "") {
        this.$message({
          message: that.languageData.searchImagesDesc,
          type: "warning",
        });
        return;
      }
      if (this.chat_content.includes(this.aite_name) && this.aite_name != "") {
        if (this.chat_content.includes(this.languageData.agent)) {
          temp = this.languageData.agent;
          this.chat_content = this.chat_content.replace(
            this.chat_content.split(this.languageData.tip118)[0],
            `<span style='color:#007bff;'>${this.aite_name}</span>`
          );
        } else {
          if (this.aite_name.includes(this.languageData.cardfx)) {
            this.chat_content = this.chat_content.replace(
              this.aite_name,
              `<span style='color:#007bff;cursor: pointer;' id=${this.card_id}>${this.aite_name}</span>`
            );
          } else {
            this.chat_content = this.chat_content.replace(
              this.aite_name,
              `<span style='color:#007bff;'>${this.aite_name}</span>`
            );
          }
        }
      }
      // console.log(this.chat_content);
      //追加聊天列表(用户输入)
      that.chat_data.push({
        userName: that.user.userName,
        userId: that.user.userId,
        content:
          this.chat_content +
          "<div style='display:flex;align-items: center;'><svg data-tagname=\"geneCard\" data-index=\"" + that.chat_data.length.toString() + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
          formatDateTime(new Date().getTime()) +
          "</div></div>",
        ai: false,
        is_mind_map: false,
        index: that.chat_data.length,
      });
      if (!this.chat_content.includes(that.languageData.discussion_ends)) {
        this.agentHistory += that.user.userName + ":" + this.chat_content + "\n\n"
      }
      this.$nextTick(() => {
        this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
      });
      //协同的接口
      this.sse_chat_message(this.chat_content, that.user.userName, false, "");
      //存库的接口
      this.save_chat_message(
        this.chat_content,
        that.user.userName,
        that.user.userId,
        0,
        "",
        this.$store.state.group_active_id
      );
      //连续提问
      if (this.isHistory) {
        this.chat_content += `@<span style='color:#007bff;'>GPT AI${this.languageData.aidh}</span>`;
      }
      //处理@ai
      if (
        this.chat_content.includes(
          `@<span style='color:#007bff;'>GPT AI${this.languageData.aidh}</span>`
        ) ||
        this.chat_content.includes(
          `@<span style='color:#007bff;'>Claude3 AI${this.languageData.aidh}</span>`
        ) ||
        this.chat_content.includes(
          `<span style='color:#007bff;cursor: pointer;' id=${this.card_id}>${this.aite_name}</span>`
        ) ||
        this.chat_content.includes("@GPT AI") ||
        this.chat_content.includes("@Claude3 AI")
      ) {
        let uid = uuidv4();
        this.chat_content = this.chat_content.replace(
          `@<span style='color:#007bff;'>GPT AI${this.languageData.aidh}</span>`,
          ""
        );
        this.chat_content = this.chat_content.replace(
          `@<span style='color:#007bff;'>Claude3 AI${this.languageData.aidh}</span>`,
          ""
        );
        this.chat_content = this.chat_content.replace(`@GPT AI`, "");
        this.chat_content = this.chat_content.replace(`@Claude3 AI`, "");
        //追加列表AI数据
        that.chat_data.push({
          userName: "AI",
          userId: "AI",
          content: this.chat_content,
          ai: true,
          filename: "",
        });
        that.loading = true;
        if (this.is_fild) {
          //卡片文档
          this.chat_content = this.chat_content.replace(
            `<span style='color:#007bff;cursor: pointer;' id=${this.card_id}>${this.aite_name}</span>`,
            ""
          );
        } else {
          //卡片非文档
          this.chat_content = this.chat_content.replace(
            `<span style='color:#007bff;cursor: pointer;' id=${this.card_id}>${this.aite_name}</span>`,
            this.aite_name
          );
        }
        var config = {};
        if (this.is_fild) {
          config = {
            method: "post",
            url: this.base_url + "/ask_question_new_excel",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              question: this.chat_content,
              userId: uid,
              groupId: this.$store.state.cid,
              userName: this.user.userName,
              type: "document",
              file_id: this.file_id,
              file_name: this.fild_name,
              file_url: this.fild_url,
              mind_map_question: this.chat_content,
              domain_flag: true,
            },
          };
        } else {
          if (this.is_claude) {
            config = {
              method: "post",
              url: this.claude3_url + "/chat",
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                message: {
                  max_tokens: 4096,
                  messages: [{ role: "user", content: this.chat_content }],
                  anthropic_version: "bedrock-2023-05-31",
                },
                uid: uid,
              },
            };
          } else {
            config = {
              method: "post",
              url: this.base_url + "/ask_question_new_excel",
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                question: this.chat_content,
                userId: uid,
                groupId: "123",
                userName: this.user.userName,
                type: "chat",
                isHistory: this.isHistory,
                file_id: "",
                mind_map_question: this.chat_content,
              },
            };
          }
        }
        this.is_fild = false;
        this.$ajax(config)
          .then((res) => {
            that.aite_name = "";
            that.sinfo_id = "";
            if (res.data.FunctionResponse?.type == "mind_map") {
              //思维导图
              that.loading = false;
              let markdown = res.data.FunctionResponse.result;
              if (markdown.includes("```")) {
                markdown = markdown.split("```")[1];
                if (markdown.includes("markdown")) {
                  markdown = markdown.split("markdown")[1];
                }
              }
              that.chat_data.at(-1).ai = false;
              that.chat_data.at(-1).content = markdown;
              that.chat_data.at(-1).is_mind_map = true;
              that.chat_data.at(-1).index = that.chat_data.length - 1;
              that.sse_chat_message(markdown, "AI", true, "");
              that.save_chat_message(markdown, "AI", "AI", 1, "", that.$store.state.group_active_id);
              that.$nextTick(() => {
                that.handle_mind_map(
                  markdown,
                  `#markmap${that.chat_data.length - 1}`
                );
                that.$refs.content.scrollTop = that.$refs.content.scrollHeight;
              });
              return;
            }
          })
          .catch((err) => {
            console.log(err);
            that.loading = false;
            that.aite_name = "";
          });
        that.askOwn(uid);
      } else if (temp == this.languageData.agent) {
        this.chat_content = this.chat_content.replace(
          `<span style='color:#007bff;'>${this.aite_name}</span>${this.languageData.tip118}`,
          ""
        );
        // console.log(this.chat_content)
        if (that.chat_content.includes(that.languageData.discuss)) {
          that.agentHistory = ""
          that.chat_content = that.languageData.forget + "," + that.chat_content
        }
        let chat_content = that.chat_content
        if (that.chat_content.includes(that.languageData.discussion_ends)) {
          // console.log(data.reverse())
          chat_content = that.agentHistory + chat_content
        }
        //追加列表AI数据
        that.chat_data.push({
          userName: "AI",
          userId: this.assistant_id,
          content: this.chat_content,
          ai: true,
          filename: "",
        });
        that.loading = true;
        let uid = uuidv4();
        let config = {
          method: "post",
          url: "https://gpt4.cocorobo.cn/ai_agent_park_chat_new",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            assistant_id: this.assistant_id,
            message: chat_content,
            userId: this.user.userId,
            session_name: "协同建构",
            isCocoNote: 1,
            uid: uid,
          },
        };
        this.$ajax(config)
          .then(() => {

          })
          .catch((err) => {
            console.log(err);
            that.loading = false;
            that.assistant_id = "";
            that.aite_name = "";
          });
        that.askAssistant(uid);
      }
      this.chat_content = "";
    },
    listen(text) {
      let that = this;
      const md = new MarkdownIt({
        html: true,
      });
      let id = that.$store.state.group_active_id;
      let uid = uuidv4();
      let config = {
        method: "post",
        url: "https://llm.cocorobo.cn/agent/ai_agent_park_chat",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          assistant_id: "d62b3064ce504c969a7528dca415adfa",
          file_ids: [],
          isImage: false,
          message: this.languageData.according + text + "," + this.languageData.accordingTip + this.user.userName + this.languageData.accordingTip2,
          userId: this.user.userId,
          session_name: that.$store.state.group_active_id + "-note",
          isCocoNote: 1,
          sound_url: "",
          uid: uid,
          model: "glm-4-flash"
        },
      };
      this.$ajax(config)
        .then((res) => {
          clearInterval(that.isTipTimeInterval)
          that.tipResult = md.render(res.data.FunctionResponse.message)
          if (that.$store.state.group_active_id == id) {
            that.save_chat_message(that.tipResult, that.user.userName, that.user.userId, 0, "", that.$store.state.group_active_id + "-AI");
          }
          that.isTipTimeInterval = setInterval(function () {
            if (!that.isShowTipResult) {
              that.isTip = !that.isTip;
            }
          }, 2000)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    p_card(e) {
      if (e.target.id) {
        this.$emit("p_card", e.target.id);
      }
    },
    handle_mind_map(markdown, id) {
      const transformer = new Transformer();
      // 1. transform markdown
      const { root } = transformer.transform(markdown);
      const { styles, scripts } = transformer.getAssets();
      const { Markmap, loadCSS, loadJS } = window.markmap;

      // 1. load assets
      if (styles) loadCSS(styles);
      if (scripts) loadJS(scripts, { getMarkmap: () => window.markmap });

      // 2. create markmap
      Markmap.create(id, null, root);
    },
    download_mind_map(id) {
      const down = document.querySelector(`#${id}`);
      const xmlDom = new XMLSerializer().serializeToString(down); //将设置好颜色的div转为xml

      const xml = `<?xml version="1.0" standalone="no"?>\r\n${xmlDom}`; //生成xml文件

      const src = `data:image/svg+xml;chartset=utf-8,${encodeURIComponent(
        xml
      )}`; //生成src
      const img = new Image();
      img.src = src;
      img.style = "display:none";
      document.body.appendChild(img);
      const canvas = document.createElement("canvas");
      const canWidth = window.innerWidth,
        canHeight = window.innerHeight;
      canvas.width = canWidth;
      canvas.height = canHeight;

      const context = canvas.getContext("2d");

      img.onload = () => {
        context.drawImage(img, 0, 0, canWidth, canHeight);
        const a = document.createElement("a");
        a.download = `${id}.jpg`;
        a.href = canvas.toDataURL("image/jpeg", 1);
        a.click();
      };
    },
    look_mind_map(index) {
      if (this.svg_dialog == index) {
        this.svg_dialog = "lyt";
        return;
      }
      this.svg_dialog = index;
    },
    askOwn(userid) {
      let that = this;
      if (this.ai_source) {
        this.ai_source.close();
      }
      if (this.is_claude) {
        // 创建EventSource
        this.ai_source = new EventSource(
          this.claude3_url + "/streamChat/" + userid
        );
        // 存储获取的流数据（追加）
        let alltext = "";
        // 存储打字效果数据
        let newalltext = "";
        // 初始化md
        const md = new MarkdownIt({
          html: true,
        });
        // 监听流数据的返回
        this.ai_source.onmessage = function (e) {
          that.loading = false;
          that.sinfo_id = "";
          that.chat_data.at(-1).ai = false;
          let eData = e.data;
          if (eData.replace("'", "").replace("'", "") == "[DONE]") {
            newalltext = newalltext.replace("_", "");
            //需要replace替换两次
            newalltext = newalltext.replace("_", "");
            // 在显示数组中存储当前数据
            let index = (that.chat_data.length - 1).toString();
            that.chat_data.at(-1).content = newalltext +
              "<div style='display:flex;align-items: center;'><svg data-tagname=\"geneCard\" data-index=\"" + index + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
              formatDateTime(new Date().getTime()) +
              "</div></div>";
            if (eData.filename) {
              that.chat_data.at(-1).content +=
                "<div style='color:rgb(113, 124, 141);font-size: 14px;text-align: right;'>来源：" +
                eData.filename +
                "</div>";
              that.sse_chat_message(newalltext, "AI", false, eData.filename);
              that.save_chat_message(newalltext, "AI", "AI", 0, eData.filename, that.$store.state.group_active_id);
            } else {
              that.sse_chat_message(newalltext, "AI", false, "");
              that.save_chat_message(newalltext, "AI", "AI", 0, "", that.$store.state.group_active_id);
            }
            //重置数据
            that.ai_source.close();
            alltext = "";
            newalltext = "";
            return;
          } else {
            let content = eData.replace("'", "").replace("'", "");
            if (alltext == "") {
              alltext = content.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
            } else {
              alltext += content;
            }
            // 处理流数据
            newalltext = alltext + "_";
            newalltext = newalltext.replace(/\\n/g, "\n"); // \\n
            newalltext = newalltext.replace(/\\/g, "");
            if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
            // 转化返回的回复流数据
            newalltext = md.render(newalltext);
            // 在显示数组中存储当前数据
            that.chat_data.at(-1).content = newalltext;
            that.$nextTick(() => {
              that.$refs.content.scrollTop = that.$refs.content.scrollHeight;
            });
          }
        };
      } else {
        // 创建EventSource
        this.ai_source = new EventSource(this.base_url + "/ask_own/" + userid);
        // 存储获取的流数据（追加）
        let alltext = "";
        // 存储打字效果数据
        let newalltext = "";
        // 初始化md
        const md = new MarkdownIt({
          html: true,
        });
        // 监听流数据的返回
        this.ai_source.onmessage = function (e) {
          that.loading = false;
          that.sinfo_id = "";
          that.chat_data.at(-1).ai = false;
          let eData = JSON.parse(e.data);
          if (eData.content.replace("'", "").replace("'", "") == "[DONE]") {
            newalltext = newalltext.replace("_", "");
            //需要replace替换两次
            newalltext = newalltext.replace("_", "");
            // 在显示数组中存储当前数据
            let index = (that.chat_data.length - 1).toString();
            that.chat_data.at(-1).content = newalltext +
              "<div style='display:flex;align-items: center;'><svg data-tagname=\"geneCard\" data-index=\"" + index + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
              formatDateTime(new Date().getTime()) +
              "</div></div>";
            if (eData.filename) {
              that.chat_data.at(-1).content +=
                "<div style='color:rgb(113, 124, 141);font-size: 14px;text-align: right;'>来源：" +
                eData.filename +
                "</div>";
              that.sse_chat_message(newalltext, "AI", false, eData.filename);
              that.save_chat_message(newalltext, "AI", "AI", 0, eData.filename, that.$store.state.group_active_id);
            } else {
              that.sse_chat_message(newalltext, "AI", false, "");
              that.save_chat_message(newalltext, "AI", "AI", 0, "", that.$store.state.group_active_id);
            }
            //重置数据
            that.ai_source.close();
            alltext = "";
            newalltext = "";
            return;
          } else {
            let content = "";
            if (eData.type == "a") {
              content = eData.content.replace("'", "").replace("'", "");
            }
            if (alltext == "") {
              alltext = content.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
            } else {
              alltext += content;
            }
            // 处理流数据
            newalltext = alltext + "_";
            newalltext = newalltext.replace(/\\n/g, "\n"); // \\n
            newalltext = newalltext.replace(/\\/g, "");
            if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
            // 转化返回的回复流数据
            newalltext = md.render(newalltext);
            // 在显示数组中存储当前数据
            that.chat_data.at(-1).content = newalltext;
            that.$nextTick(() => {
              that.$refs.content.scrollTop = that.$refs.content.scrollHeight;
            });
          }
        };
      }
    },
    askAssistant(uid) {
      let that = this;
      if (this.ai_source) {
        this.ai_source.close();
      }
      this.ai_source = new EventSource("https://gpt4.cocorobo.cn/question/" + uid);
      let alltext = "";
      let newalltext = "";
      const md = new MarkdownIt({
        html: true,
      });
      //上传新文档的时候问题先隐藏掉
      this.ai_source.onmessage = function (e) {
        that.loading = false;
        that.sinfo_id = "";
        that.chat_data.at(-1).ai = false;
        let eData = JSON.parse(e.data);
        if (eData.content.replace("'", "").replace("'", "") == "[DONE]") {
          newalltext = newalltext.replace("_", "");
          newalltext = newalltext.replace("_", "");
          let index = (that.chat_data.length - 1).toString();
          that.chat_data.at(-1).content = newalltext +
            "<div style='display:flex;align-items: center;'><svg data-tagname=\"geneCard\" data-index=\"" + index + "\" style=\"transform: rotate(90deg);cursor:pointer;\" t=\"1732008718447\" class=\"icon geneCardSvg\" viewBox=\"0 0 1024 1024\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" p-id=\"14594\" width=\"14\" height=\"14\"><title>" + that.languageData.accept + "</title><path d=\"M686.592 356.864V261.12c64.512-38.912 104.448-109.056 104.448-184.832V47.104c0-11.264-4.608-22.016-12.288-29.696S760.32 5.12 749.056 5.12H274.944c-11.264 0-22.016 4.608-29.696 12.288S232.96 35.84 232.96 47.104v29.184c0 76.288 40.448 145.92 104.448 184.832v96.256C192 423.424 95.744 571.392 95.744 732.16c0 11.264 4.608 21.504 12.288 29.696 7.68 7.68 18.432 12.288 29.696 12.288h339.456v200.704c0 23.04 18.944 41.984 41.984 41.984 11.264 0 22.016-4.608 29.696-12.288s12.288-18.432 12.288-29.696v-200.704h325.12c11.264 0 22.016-4.096 29.696-12.288 7.68-7.68 12.288-18.432 12.288-29.696-0.512-160.768-96.256-308.224-241.664-375.296z m-166.912 348.16H161.792c16.384-128 100.864-236.544 224.768-283.136 16.896-6.656 28.672-23.04 28.672-41.472v-158.72c0-17.408-10.24-33.792-26.624-40.96-47.104-20.992-78.848-65.024-83.456-115.712h413.696c-5.12 50.688-36.864 95.232-83.968 115.712-15.872 7.168-26.624 23.04-26.624 40.448v158.208c0 18.432 11.264 34.816 28.672 41.472 124.416 47.104 208.896 155.648 224.768 283.136l-342.016 1.024z\" fill=\"#currentColor\" p-id=\"14595\"></path></svg><div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);width:calc(100% - 16px);'>" +
            formatDateTime(new Date().getTime()) +
            "</div></div>";
          that.sse_chat_message(newalltext, "AI", false, "");
          that.save_chat_message(newalltext, "AI", "AI", 0, "", that.$store.state.group_active_id);
          //重置数据
          that.ai_source.close();
          alltext = "";
          newalltext = "";
          return;
        } else {
          let a = eData.content.replace("'", "").replace("'", "");
          // console.log(e.data)
          that.prevChatResult = that.prevChatResult + a;
          if (alltext == "") {
            alltext = a.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
          } else {
            alltext += a;
          }
          newalltext = alltext + "_";
          newalltext = newalltext.replace(/\\n/g, "\n");
          newalltext = newalltext.replace(/\\/g, "");
          if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
          newalltext = md.render(newalltext);
          that.chat_data.at(-1).content = newalltext;
          that.$nextTick(() => {
            that.$refs.content.scrollTop = that.$refs.content.scrollHeight;
          });
        }
      };
    },
    sse_chat_message(chat_content, userName, is_mind_map, filename) {
      var config = {
        method: "post",
        url: this.base_url + "/send_message",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          content: chat_content,
          userId: this.user.userId,
          userIds: this.userIds,
          groupId: this.$store.state.group_active_id,
          userName: userName,
          type: 0,
          is_mind_map,
          filename: filename,
        },
      };
      this.$ajax(config)
        .then(() => { })
        .catch((err) => {
          alert(err);
        });
    },
    save_chat_message(chat_content, userName, userId, is_mind_map, filename, groupId) {
      let config = {
        method: "post",
        url: this.base_url + "/save_chat_message",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userId,
          userName,
          groupId: groupId,
          problem: chat_content,
          is_mind_map,
          filename,
        },
      };
      this.$ajax(config).then(() => { });
    },
    colse() {
      this.$emit("close", true);
    },
    open() {
      this.$emit("close", false);
    },
    clear() {
      this.chat_data = [];
      localStorage.setItem(
        "user",
        '[{"userId":"1cf9dc4b-d95f-11ea-af4c-52540005ab01","userName":"潘炜桐"},{"userId":"11ea-af4c-52540005ab01","userName":"炜桐"}]'
      );
    },
    selectProblem(value) {
      this.chat_content = "@GPT AI(对话)：" + value;
      //this.send_message();
    },
    getAIAgent() {
      let that = this;
      var config = {
        method: "POST",
        url: "https://gpt4.cocorobo.cn/get_ai_agent_assistant_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userId:
            window.location.href.indexOf("localhost") != -1 ||
              window.location.href.indexOf("192.168") != -1
              ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
              : window.parent.US.userInfo.userid,
        },
      };
      this.$ajax(config)
        .then((res) => {
          that.agentList =
            res.data.FunctionResponse.result.length != 0
              ? JSON.parse(res.data.FunctionResponse.result)
              : [];
          that.getShareAIAgent();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getShareAIAgent() {
      let that = this;
      var config = {
        method: "POST",
        url: "https://gpt4.cocorobo.cn/get_ai_agent_assistant_share_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userId:
            window.location.href.indexOf("localhost") != -1 ||
              window.location.href.indexOf("192.168") != -1
              ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
              : window.parent.US.userInfo.userid,
          organizeid:
            window.location.href.indexOf("localhost") != -1 ||
              window.location.href.indexOf("192.168") != -1
              ? "45facc0a-1211-11ec-80ad-005056b86db5"
              : window.parent.US.userInfo.organizeid,
        },
      };
      this.$ajax(config)
        .then((res) => {
          that.shareAgentList =
            res.data.FunctionResponse.result.length != 0
              ? JSON.parse(res.data.FunctionResponse.result)
              : [];
          for (let i = 0; i < that.shareAgentList.length; i++) {
            that.agentList.push(that.shareAgentList[i]);
          }
          this.ai_agent_loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handle_chat_content_change(value) {
      this.$nextTick(() => {
        const scrollElement = this.$refs.chat_input.$el.querySelector(
          ".el-textarea__inner"
        );
        scrollElement.scrollTop = scrollElement.scrollHeight;
      });
      let that = this;
      this.aite = false;
      this.aite_card = false;
      this.aite_ai = false;
      this.aite_claude = false;
      this.analyse_user = false;
      this.aite_ai_agent = false;
      if (value.at(-1) == "@") {
        this.aite = true;
        this.$refs.chat_input.blur();
      } else if (
        (value.substring(value.length - 7) == this.languageData.cardfx && !this.isEnglish) || (value.substring(value.length - 16) == this.languageData.cardfx && this.isEnglish)
      ) {
        if (!this.is_claude) {
          this.aite_name = `GPT AI${this.languageData.cardfx}`;
          this.aite_claude = false;
        }
        if (this.is_claude) {
          this.aite_name = `Claude3 AI${this.languageData.cardfx}`;
        }
        this.aite_ai = false;
        this.aite_card = true;
        that.all_nodes = [];
        this.lf.getGraphData().nodes.forEach((item) => {
          if (
            item.type != "icon-thinking" ||
            item.type != "icon-map" ||
            item.type != "icon-whiteboard"
          ) {
            that.all_nodes.push(item);
          }
        });
      } else if (value.substring(value.length - 7) == "@GPT AI") {
        this.aite_ai = true;
        this.is_claude = false;
      } else if (value.substring(value.length - 11) == "@Claude3 AI") {
        this.aite_claude = true;
        this.is_claude = true;
      } else if ((value.substring(value.length - 5) == this.languageData.aidh && !this.isEnglish) || (value.substring(value.length - 7) == this.languageData.aidh && this.isEnglish)) {
        if (!this.is_claude) {
          this.aite_name = `GPT AI${this.languageData.aidh}`;
          this.aite_claude = false;
          this.aite_ai = false;
        }
        if (this.is_claude) {
          this.aite_name = `Claude3 AI${this.languageData.aidh}`;
        }
      } else if (
        (value.substring(value.length - 9) == this.languageData.userxwfx && !this.isEnglish) || (value.substring(value.length - 26) == this.languageData.userxwfx && this.isEnglish)
      ) {
        this.analyse_user = true;
      } else if ((value.substring(value.length - 7) == this.languageData.agent && !this.isEnglish) || (value.substring(value.length - 10) == this.languageData.agent && this.isEnglish)) {
        this.aite_name = "";
        this.agentList = [];
        this.aite_ai_agent = true;
        this.ai_agent_loading = true;
        this.getAIAgent();
      }
    },
    handle_aite(value) {
      this.chat_content += value;
      this.aite_name += value;
      this.handle_chat_content_change(this.chat_content);
      this.aite = false;
      this.$refs.chat_input.focus();
    },
    handle_analyse_user(item) {
      this.aite_name = "";
      let that = this;
      this.chat_content += item.username;
      this.analyse_user = false;
      //该成员操作数
      let user_data = this.$store.state.select_CSCLDataByID.filter((item2) => {
        return item2.userid == item.userid;
      });
      //该小组的操作数
      let group_date = this.$store.state.select_CSCLDataByID.filter(
        (item_group_date) => {
          return item_group_date.cid == item.groupCid;
        }
      );
      //小组的添加卡片数
      let group_date_addnode = group_date.filter((item_group_date_addnode) => {
        return JSON.parse(item_group_date_addnode.text).type == "addNode";
      });
      let log_data = {
        updateNode: {
          time: 0,
          iconcard: 0,
          iconselect: 0,
          icontask: 0,
          iconAI: 0,
          iconMarkdown: 0,
          icontheme: 0,
          iconstage: 0,
          iconthinking: 0,
          iconwhiteboard: 0,
          iconmap: 0,
          iconcamera: 0,
          iconNewAI: 0,
        },
        addNode: {
          time: 0,
          iconcard: 0,
          iconselect: 0,
          icontask: 0,
          iconAI: 0,
          iconMarkdown: 0,
          icontheme: 0,
          iconstage: 0,
          iconthinking: 0,
          iconwhiteboard: 0,
          iconmap: 0,
          iconcamera: 0,
          iconNewAI: 0,
        },
        deleteNode: {
          time: 0,
          iconcard: 0,
          iconselect: 0,
          icontask: 0,
          iconAI: 0,
          iconMarkdown: 0,
          icontheme: 0,
          iconstage: 0,
          iconthinking: 0,
          iconwhiteboard: 0,
          iconmap: 0,
          iconcamera: 0,
          iconNewAI: 0,
        },
        addEdge: 0,
        deleteEdge: 0,
        updateEdge: 0,
      };
      user_data.forEach((item3) => {
        let text = JSON.parse(item3.text);
        switch (text.type) {
          case "updateNode":
            log_data.updateNode.time++;
            switch (text.data.nodes[0]?.type) {
              case "icon-card":
                log_data.updateNode.iconcard++;
                break;
              case "icon-select":
                log_data.updateNode.iconselect++;
                break;
              case "icon-task":
                log_data.updateNode.icontask++;
                break;
              case "icon-AI":
                log_data.updateNode.iconAI++;
                break;
              case "icon-markdown":
                log_data.updateNode.iconMarkdown++;
                break;
              case "icon-new-AI":
                log_data.updateNode.iconNewAI++;
                break;
              case "icon-theme":
                log_data.updateNode.icontheme++;
                break;
              case "icon-stage":
                log_data.updateNode.iconstage++;
                break;
              case "icon-thinking":
                log_data.updateNode.iconthinking++;
                break;
              case "icon-whiteboard":
                log_data.updateNode.iconwhiteboard++;
                break;
              case "icon-map":
                log_data.updateNode.iconmap++;
                break;
              case "icon-camera":
                log_data.updateNode.iconcamera++;
                break;
              default:
                break;
            }
            break;
          case "addNode":
            log_data.addNode.time++;
            switch (text.data.nodes[0]?.type) {
              case "icon-card":
                log_data.addNode.iconcard++;
                break;
              case "icon-select":
                log_data.addNode.iconselect++;
                break;
              case "icon-task":
                log_data.addNode.icontask++;
                break;
              case "icon-AI":
                log_data.addNode.iconAI++;
                break;
              case "icon-markdown":
                log_data.addNode.iconMarkdown++;
                break;
              case "icon-new-AI":
                log_data.addNode.iconNewAI++;
                break;
              case "icon-theme":
                log_data.addNode.icontheme++;
                break;
              case "icon-stage":
                log_data.addNode.iconstage++;
                break;
              case "icon-thinking":
                log_data.addNode.iconthinking++;
                break;
              case "icon-whiteboard":
                log_data.addNode.iconwhiteboard++;
                break;
              case "icon-map":
                log_data.addNode.iconmap++;
                break;
              case "icon-camera":
                log_data.addNode.iconcamera++;
                break;
              default:
                break;
            }
            break;
          case "deleteNode":
            log_data.deleteNode.time++;
            switch (text.data.nodes[0]?.type) {
              case "icon-card":
                log_data.deleteNode.iconcard++;
                break;
              case "icon-select":
                log_data.deleteNode.iconselect++;
                break;
              case "icon-task":
                log_data.deleteNode.icontask++;
                break;
              case "icon-AI":
                log_data.deleteNode.iconAI++;
                break;
              case "icon-markdown":
                log_data.deleteNode.iconMarkdown++;
                break;
              case "icon-new-AI":
                log_data.deleteNode.iconNewAI++;
                break;
              case "icon-theme":
                log_data.deleteNode.icontheme++;
                break;
              case "icon-stage":
                log_data.deleteNode.iconstage++;
                break;
              case "icon-thinking":
                log_data.deleteNode.iconthinking++;
                break;
              case "icon-whiteboard":
                log_data.deleteNode.iconwhiteboard++;
                break;
              case "icon-map":
                log_data.deleteNode.iconmap++;
                break;
              case "icon-camera":
                log_data.deleteNode.iconcamera++;
                break;
              default:
                break;
            }
            break;
          case "addEdge":
            log_data.addEdge++;
            break;
          case "deleteEdge":
            log_data.deleteEdge++;
            break;
          case "updateEdge":
            log_data.updateEdge++;
            break;
          default:
            break;
        }
      });
      this.chat_content = this.chat_content.replace("@GPT AI", "");
      this.chat_content = this.chat_content.replace("@Claude3 AI", "");
      that.chat_data.push({
        userName: that.user.userName,
        userId: that.user.userId,
        content:
          this.chat_content +
          "<div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);'>" +
          formatDateTime(new Date().getTime()) +
          "</div>",
        ai: false,
        is_mind_map: false,
        index: that.chat_data.length,
      });
      this.$nextTick(() => {
        this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
      });
      //协同的接口
      this.sse_chat_message(this.chat_content, that.user.userName, false, "");
      //存库的接口
      this.save_chat_message(
        this.chat_content,
        that.user.userName,
        that.user.userId,
        0,
        "", 
        that.$store.state.group_active_id
      );
      this.chat_content = "";
      this.loading = true;
      let user_number = 1;
      this.$store.state.group.forEach((arr, index_group) => {
        arr.group_member.forEach((arr2) => {
          if (arr2.userid == item.userid) {
            user_number =
              this.$store.state.group[index_group].group_member.length;
          }
        });
      });
      let mean_number = group_date.length / user_number; //小组的总数/小组成员数
      let add_mean_number = group_date_addnode.length / user_number; //添加卡片平均数
      let add_ap = ""; //贡献度
      let add_percentage =
        Math.round(
          (log_data.addNode.time / group_date_addnode.length) * 10000
        ) /
        100 +
        "%";
      if (log_data.addNode.time > add_mean_number * 2) {
        add_ap = `${this.languageData.contribution_degree}<strong style='color:#DF0101'>${this.languageData.add_ap1}</strong>，${this.languageData.contribution}${add_percentage}。`;
      }
      if (
        log_data.addNode.time <= add_mean_number * 2 &&
        log_data.addNode.time >= add_mean_number + 3
      ) {
        add_ap = `${this.languageData.contribution_degree}<strong style='color:#F5A9A9'>${this.languageData.add_ap2}</strong>，${this.languageData.contribution}${add_percentage}。`;
      }
      if (
        log_data.addNode.time < add_mean_number + 3 &&
        log_data.addNode.time > add_mean_number - 3
      ) {
        add_ap = `${this.languageData.contribution_degree}<strong style='color:#819FF7'>${this.languageData.add_ap3}</strong>，${this.languageData.contribution}${add_percentage}。`;
      }
      if (
        log_data.addNode.time <= add_mean_number - 3 &&
        log_data.addNode.time >= add_mean_number / 2
      ) {
        add_ap = `${this.languageData.contribution_degree}<strong style='color:#0000FF'>${this.languageData.add_ap4}</strong>，${this.languageData.contribution}${add_percentage}。`;
      }
      if (
        log_data.addNode.time < add_mean_number / 2 ||
        log_data.addNode.time == 0
      ) {
        add_ap = `${this.languageData.contribution_degree}<strong style='color:#0000FF'>${this.languageData.add_ap5}</strong>，${this.languageData.contribution}${add_percentage}。`;
      }

      let ap = ""; //活跃度
      if (user_data.length > mean_number * 2) {
        ap = `${this.languageData.ap}<strong style='color:#DF0101'>${this.languageData.dynamic1}</strong>`;
      }
      if (
        user_data.length <= mean_number * 2 &&
        user_data.length >= mean_number + 10
      ) {
        ap = `${this.languageData.ap}<strong style='color:#F5A9A9'>${this.languageData.dynamic2}</strong>`;
      }
      if (
        user_data.length < mean_number + 10 &&
        user_data.length > mean_number - 10
      ) {
        ap = `${this.languageData.ap}<strong style='color:#819FF7'>${this.languageData.dynamic3}</strong>`;
      }
      if (
        user_data.length <= mean_number - 10 &&
        user_data.length >= mean_number / 2
      ) {
        ap = `${this.languageData.ap}<strong style='color:#0000FF'>${this.languageData.dynamic4}</strong>`;
      }
      if (user_data.length < mean_number / 2 || user_data.length == 0) {
        ap = `${this.languageData.ap}<strong style='color:#0000FF'>${this.languageData.dynamic5}</strong>`;
      }

      let result =
        log_data.addNode.time == 0
          ? item.username +
          "：</br></br>" +
          this.languageData.add_node +
          log_data.addNode.time +
          this.languageData.zhang +
          "。</br></br>"
          : item.username +
          "：</br></br>" +
          this.languageData.add_node +
          log_data.addNode.time +
          this.languageData.zhang +
          "。" +
          this.languageData.among +
          this.languageData.card +
          log_data.addNode.iconcard +
          this.languageData.zhang +
          "；" +
          this.languageData.select +
          log_data.addNode.iconselect +
          this.languageData.zhang +
          "；" +
          this.languageData.task +
          log_data.addNode.icontask +
          this.languageData.zhang +
          "；" +
          this.languageData.aiCard +
          log_data.addNode.iconAI +
          this.languageData.zhang +
          "；" +
          this.languageData.start +
          log_data.addNode.icontheme +
          this.languageData.zhang +
          "；" +
          this.languageData.stage +
          log_data.addNode.iconstage +
          this.languageData.zhang +
          "；" +
          this.languageData.thinkingCard +
          log_data.addNode.iconthinking +
          this.languageData.zhang +
          "；" +
          this.languageData.whiteboard_card +
          log_data.addNode.iconwhiteboard +
          this.languageData.zhang +
          "；" +
          this.languageData.map_card +
          log_data.addNode.iconmap +
          this.languageData.zhang +
          "；" +
          this.languageData.camera_card +
          log_data.addNode.iconcamera +
          this.languageData.zhang +
          "。" +
          "</br></br>";
      result +=
        log_data.updateNode.time == 0
          ? this.languageData.operation_card +
          log_data.updateNode.time +
          this.languageData.zhang +
          "。</br></br>"
          : this.languageData.operation_card +
          log_data.updateNode.time +
          this.languageData.zhang +
          "。" +
          this.languageData.among +
          this.languageData.card +
          log_data.updateNode.iconcard +
          this.languageData.zhang +
          "；" +
          this.languageData.select +
          log_data.updateNode.iconselect +
          this.languageData.zhang +
          "；" +
          this.languageData.task +
          log_data.updateNode.icontask +
          this.languageData.zhang +
          "；" +
          this.languageData.aiCard +
          log_data.updateNode.iconAI +
          this.languageData.zhang +
          "；" +
          this.languageData.start +
          log_data.updateNode.icontheme +
          this.languageData.zhang +
          "；" +
          this.languageData.stage +
          log_data.updateNode.iconstage +
          this.languageData.zhang +
          "；" +
          this.languageData.thinkingCard +
          log_data.updateNode.iconthinking +
          this.languageData.zhang +
          "；" +
          this.languageData.whiteboard_card +
          log_data.updateNode.iconwhiteboard +
          this.languageData.zhang +
          "；" +
          this.languageData.map_card +
          log_data.updateNode.iconmap +
          this.languageData.zhang +
          "；" +
          this.languageData.camera_card +
          log_data.updateNode.iconcamera +
          this.languageData.zhang +
          "。" +
          "</br></br>";
      result +=
        log_data.deleteNode.time == 0
          ? this.languageData.delete_card +
          log_data.deleteNode.time +
          this.languageData.zhang +
          "。</br></br>"
          : this.languageData.delete_card +
          log_data.deleteNode.time +
          this.languageData.zhang +
          "。" +
          this.languageData.among +
          this.languageData.card +
          log_data.deleteNode.iconcard +
          this.languageData.zhang +
          "；" +
          this.languageData.select +
          log_data.deleteNode.iconselect +
          this.languageData.zhang +
          "；" +
          this.languageData.task +
          log_data.deleteNode.icontask +
          this.languageData.zhang +
          "；" +
          this.languageData.aiCard +
          log_data.deleteNode.iconAI +
          this.languageData.zhang +
          "；" +
          this.languageData.start +
          log_data.deleteNode.icontheme +
          this.languageData.zhang +
          "；" +
          this.languageData.stage +
          log_data.deleteNode.iconstage +
          this.languageData.zhang +
          "；" +
          this.languageData.thinkingCard +
          log_data.deleteNode.iconthinking +
          this.languageData.zhang +
          "；" +
          this.languageData.whiteboard_card +
          log_data.deleteNode.iconwhiteboard +
          this.languageData.zhang +
          "；" +
          this.languageData.map_card +
          log_data.deleteNode.iconmap +
          this.languageData.zhang +
          "；" +
          this.languageData.camera_card +
          log_data.deleteNode.iconcamera +
          this.languageData.zhang +
          "。" +
          "</br></br>";
      result +=
        this.languageData.add_edge +
        log_data.addEdge +
        this.languageData.article +
        "。" +
        "</br></br>" +
        this.languageData.updata_edge +
        log_data.updateEdge +
        this.languageData.article +
        "。" +
        "</br></br>" +
        this.languageData.delete_edge +
        log_data.deleteEdge +
        this.languageData.article +
        "。" +
        "</br></br>" +
        add_ap +
        "</br>" +
        ap;
      //把数据添加到列表
      that.chat_data.push({
        userName: "AI",
        userId: "AI",
        content:
          result +
          "<div style='font-size: 12px;text-align: right;color:rgb(113, 124, 141);'>" +
          formatDateTime(new Date().getTime()) +
          "</div>",
        ai: false,
        is_mind_map: false,
        index: that.chat_data.length,
      });
      this.loading = false;
      this.$nextTick(() => {
        this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
      });
      //协同的接口
      this.sse_chat_message(result, "AI", false, "");
      //存库的接口
      this.save_chat_message(result, "AI", "AI", 0, "", this.$store.state.group_active_id);
    },
    handle_aite_card(value, id) {
      this.card_id = id;
      this.chat_content += value;
      this.handle_chat_content_change(this.chat_content);
      this.aite_name += value;
      this.aite_card = false;
      this.$refs.chat_input.focus();
    },
    handle_aite_file(item) {
      this.card_id = item.id;
      this.chat_content += item.properties.fileList[0].name;
      this.fild_url = item.properties.fileList[0].url;
      this.fild_name = item.properties.fileList[0].name;
      this.is_fild = true;
      this.aite_name += item.properties.fileList[0].name;
      this.file_id = item.id;
      this.aite_card = false;
      this.$refs.chat_input.focus();
    },
    handle_agent(item) {
      this.chat_content +=
        " - " + item.assistantName + this.languageData.tip118;
      this.assistant_id = item.assistant_id;
      this.aite_name += item.assistantName;
      this.aite_ai_agent = false;
      this.$refs.chat_input.focus();
    },
    //导出为excel文件
    async export_to_excel() {
      if(this.radio1 == "1") {
        if (this.radio == "1") {
          let stringData = "";
          this.chat_data.forEach((item) => {
            let aiContent = item.content.replace(/<[^>]+>/g, "");
            stringData = stringData + item.userName + "：" + aiContent + "\n";
          });
          const blob = new Blob([stringData], {
            type: "text/plain;charset=utf-8",
          }); // 根据 blob生成 url链接
          const objectURL = URL.createObjectURL(blob); // 创建一个 a 标签Tag
          const aTag = document.createElement("a"); // 设置文件的下载地址
          aTag.href = objectURL; // 设置保存后的文件名称
          aTag.download = "聊天室数据.txt"; // 给 a 标签添加点击事件
          aTag.click(); // 释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。 // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
          URL.revokeObjectURL(objectURL);
          this.handleClose();
        } else {
          this.download("聊天记录.json", JSON.stringify(this.chat_data));
          this.handleClose();
        }
      }
      else {
        await this.getMonitorData(this.$store.state.group_active_id + "-AI")
        if (this.radio == "1") {
          let stringData = "";
          this.monitorData.forEach((item) => {
            let aiContent = item.content.replace(/<[^>]+>/g, "");
            stringData = stringData + item.userName + "：\n" + aiContent + "\n\n";
          });
          const blob = new Blob([stringData], {
            type: "text/plain;charset=utf-8",
          }); // 根据 blob生成 url链接
          const objectURL = URL.createObjectURL(blob); // 创建一个 a 标签Tag
          const aTag = document.createElement("a"); // 设置文件的下载地址
          aTag.href = objectURL; // 设置保存后的文件名称
          aTag.download = "聊天室数据.txt"; // 给 a 标签添加点击事件
          aTag.click(); // 释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。 // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
          URL.revokeObjectURL(objectURL);
          this.handleClose();
        } else {
          this.download("聊天记录.json", JSON.stringify(this.monitorData));
          this.handleClose();
        }
      }
    },
    download(filename, text) {
      window.sessionStorage.setItem(filename, text);
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>
<style>
:root {
  --input-height: auto;
}

#chat_room .el-input {
  height: 80%;
}

#chat_room .el-input__inner {
  height: 100%;
}

#content_item_other .el-loading-spinner {
  background: #f0f0f3;
}

.el-collapse-item__header {
  padding-left: 10px;
}

#export-1 .el-dialog {
  margin-top: 0 !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: none;
  box-shadow: none;
  padding: 0;
}

#export-1 .el-dialog__header {
  width: calc(100% - 48px);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  background: var(--font-icon-white, #fff);
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  padding: 8px 24px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

#export-1 .el-dialog__body {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  padding: 0;
  background: var(--font-icon-white, #fff);
  padding: 8px 24px;
  width: calc(100% - 48px);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  height: 8%;
}

#export-1 .el-dialog__footer {
  border-left: 1px solid var(--bg3, #e7e7e7);
  border-right: 1px solid var(--bg3, #e7e7e7);
  border-top: 1px solid var(--bg3, #e7e7e7);
  border-bottom: 1px solid var(--bg3, #e7e7e7);
  padding: 8px 24px;
  background: var(--font-icon-white, #fff);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: calc(100% - 48px);
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.0784313725490196);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.0392156862745098);
  box-shadow: 0 6px 30px 5px rgba(0, 0, 0, 0.050980392156862744);
  box-sizing: unset;
}

#export-1 .close {
  position: absolute;
  top: 0;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}

#agentList {
  width: 100%;
  overflow-y: auto;
}

#agentList .el-loading-spinner,
#content_item_other .circular {
  margin-top: 0;
  top: 0;
  height: 50px;
}

#agentList .el-loading-spinner .el-loading-text,
#content_item_other .el-loading-spinner .el-loading-text {
  margin: 0;
}

#agentList .circular,
#content_item_other .circular {
  width: 20px;
  height: 20px;
  margin: 0;
}

#content_item_other .el-loading-spinner {
  line-height: 24px;
}
</style>
<style scoped>
.icon_select {
  margin-right: 10px;
}

.aite_card {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 5px 0px;
  padding: 5px;
  border: 1px solid #ccccce;
  border-radius: 5px;
  box-shadow: 5px 6px 2px #e7ebef;
}

.mind_map {
  position: relative;
  width: 80%;
  height: 400px;
}

.mind_map_icon {
  position: absolute;
  bottom: 0;
  right: 15px;
  cursor: pointer;
}

.mind_map_icon i {
  font-size: 22px;
  background: rgb(224, 235, 254);
}

.svg_dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #e0ebfe;
}

.svg_dialog_colse {
  font-size: 30px !important;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

path {
  fill: 100%;
  stroke: 100%;
}

.loading_div {
  width: 200px;
  height: 50px;
}

.group_active {
  color: #0061ff;
}

.no_group_active {
  color: #000;
}

.group_item {
  position: relative;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.group_number {
  position: absolute;
  bottom: 0px;
  right: 8px;
  font-size: 10px;
  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
}

.aite {
  width: 80%;
  height: auto;
  max-height: calc(96% - 178px);
  overflow-y: auto;
  position: absolute;
  bottom: 70px;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #e7ebef;
  border-radius: 5px;
  background: #ffffff;
  font-size: 14px;
  line-height: 23px;
  box-shadow: 1px 2px 5px 5px #eaeaee;
}

.aite_item {
  padding: 2px 10px;
}

.aite_item:hover {
  cursor: pointer;
  color: #007bff;
}

.promptsList {
  padding: 10px 0px 10px 10px;
  background: #fff;
  width: 210px;
  border-radius: 5px 0px 0px 5px;
  border-bottom: 1px solid #ccccce;
  border-left: 1px solid #ccccce;
  border-top: 1px solid #ccccce;
}

.prompt {
  background: #e7f3ff;
  border-radius: 5px;
  color: #000 !important;
  padding: 10px;
  margin-bottom: 10px;
}

.full_screen {
  width: 51vw !important;
  height: 100vh !important;
  position: fixed;
  top: 0;
  right: 9px;
  z-index: 9999;
}

.chat_room_content {
  width: 660px;
  height: 65vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.chat_room {
  width: 66%;
  height: 96%;
  position: relative;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccccce;
}

.top {
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 0 5px;
}

.icon-caidanshouqi {
  font-size: 20px;
  cursor: pointer;
}

.icon-qingping {
  font-size: 20px;
  margin-right: 15px;
  cursor: pointer;
}

.content {
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  padding: 10px 0px;
  line-height: 32px;
  position: relative;
}

.content_item {
  width: 100%;
  margin-bottom: 15px;
}

.content_item_self {
  width: 100%;
  justify-content: end;
}

.user_name_self {
  width: 32px;
  height: 32px;
  margin: 0 5px;
  text-align: center;
  background-color: #007bff;
  border-radius: 50%;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.item_content {
  max-width: 80%;
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  line-height: 20px;
  background: #f3f7f3;
  word-wrap: break-word;
}

.item_content_other {
  background: #f3f7f3;
}

.item_content_other :deep() table {
  border-collapse: collapse;
}

.item_content_other :deep() table,
.item_content_other :deep() th,
.item_content_other :deep() td {
  border: 1px solid black;
}

.user_name_other {
  width: 32px;
  height: 32px;
  margin: 0 5px;
  text-align: center;
  background-color: #e67f36;
  border-radius: 50%;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.input {
  height: auto;
  position: relative;
  padding: 10px;
}

.send_icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 21px;
  right: 12px;
  cursor: pointer;
}

.content_item_self :deep() .geneCardSvg,
.item_content_other :deep() .geneCardSvg {
  fill: #717C8D;
}

.content_item_self :deep() .geneCardSvg:hover,
.item_content_other :deep() .geneCardSvg:hover {
  fill: #0061ff;
}
</style>
