import ThinkingNode from "./ThinkingNode";

// 左上角ICON为消息的节点
class CollaborativeDocumentNode extends ThinkingNode.view {
  getImageHref() {
    return require("@/assets/tools/13协同文档.png");
  }
}

export default {
  type: "icon-collaborative-document",
  view: CollaborativeDocumentNode,
  model: ThinkingNode.model,
};
