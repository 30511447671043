import { h } from "@logicflow/core";
import RectNode from "../basic/RectNode";

// 图片-基础节点
class ImageModel extends RectNode.model {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.editable = false; // 禁止节点文本编辑
    this.width = 100;
    this.height = 100;
  }
}

class ImageNode extends RectNode.view {
  getImageHref() {
    return;
  }
  getShape() {
    const { id, x, y, width, height } = this.props.model;
    const href = this.getImageHref();
    const opacity = this.props.model.getNodeStyle().opacity;
    const attrs = {
      id: "svgImage_" + id,
      x: x - (1 / 2) * width,
      y: y - (1 / 2) * height,
      width,
      height,
      href,
      opacity,
      // 根据宽高缩放
      preserveAspectRatio: "none meet",
    };
    return h("g", {}, [h("image", { ...attrs })]);
  }
}

export default {
  type: "image-node",
  view: ImageNode,
  model: ImageModel,
};
