export const enLanguage = {
  search: "Search",
  project: "Project",
  collect: "Collect Area",
  toolLibrary: "Card Library",
  materialLibrary: "Material Library",
  background: "Background",
  framework: "Framework",
  section: "Section",
  analysis: "Learning Analytics",
  box: "Box",
  myProject: "My Project",
  newProject: "New Project",
  createProject: "Create Project",
  createGroup: "Create Group",
  ungrouped: "Ungrouped",
  toolClass: "Control",
  start: "Theme Card",
  themeDesc: "Please enter a theme",
  stage: "Stage Card",
  stageName: "Stage",
  stageDesc: "Please enter a stage name",
  task: "Task Card",
  taskName: "Task",
  taskTitle: "Task Name",
  taskDesc: "Please enter a task name",
  taskContent: "Task Description",
  taskContentDesc: "Please enter a task description",
  resources: "Content",
  card: "Resource Card",
  select: "Option Card",
  group: "Subgroup",
  interactive: "Interactive",
  thinking: "Thinking",
  thinkingCard: "Thinking Card",
  evaluate: "Evaluate",
  ai: "AI",
  aiCard: "AI Card",
  fullScreen: "Full Screen",
  clear: "Clear",
  exitFullScreen: "Exit Full Screen",
  run: "Generate",
  projection: "Demonstrate",
  exitProjection: "Exit Demonstrate",
  previousPage: "Previous Page",
  nextPage: "Next Page",
  logout: "Drop out of group",
  straightLine: "Straight Line",
  polyline: "Polyline",
  filletLine: "Fillet Line",
  curve: "Curve",
  enableBoxSelection: "Enable Box Selection",
  Uncheck: "Uncheck",
  enlarge: "Enlarge",
  reduce: "Reduce",
  revoke: "Revoke",
  restore: "Restore",
  minimap: "Mini Map",
  import: "Import",
  export: "Export",
  pictureFiles: "Picture Files",
  jsonFiles: "Json Files",
  close: "Close",
  confirm: "Confirm",
  tip: "Please select one of the files to export",
  editMode: "Edit Mode",
  readOnlyMode: "Read Only Mode",
  tip1: "This operation will clear all elements in the canvas except for the start (Theme Card). Do you want to continue?",
  tip2: "Tip",
  cancel: "Cancel",
  tip3: "Clear successfully!",
  tip4: "Please start from the starting point (Theme Card) and sequentially connect all the card contents that need to be generate",
  tip5: "No Theme Card exists, please join",
  tip6: "There is a multiple-choice question card connection with no path set. Please carefully check and modify before generating",
  tip7: "Please start from the starting point (Theme Card) and sequentially connect all card contents that need to be demonstrated",
  tip8: "There is a multiple-choice question card with no path set. Please carefully check and modify before demonstrating",
  tip22: "Please select the starting point (theme card) before generating",
  tip125: "Please select the starting point (theme card) before demonstrating",
  tip24: "Generate scaffolding template here?",
  tip23: "Created successfully",
  Backspace: "Delete （Backspace or Delete）",
  clone: "Clone （Ctrl+C and Ctrl+V or Cmd+C and Cmd+V）",
  top: "Bring to font（Ctrl+Shift+] or Cmd+Shift+]）",
  bottom: "Send to back（Ctrl+Shift+[ or Cmd+Shift+[）",
  forward: "Bring Forward（Ctrl+X or Cmd+X）",
  backward: "Send backward（Ctrl+B or Cmd+B）",
  previewMode: "Viewing",
  tip9: "Please allow this webpage to use a microphone first",
  title: "Title",
  titleDesc: "Please input the title",
  titleColor: "Title Color",
  describe: "Describe",
  describeDesc: "Please enter a describe",
  notes: "Notes",
  notesDesc: "Please enter a note",
  icon: "Icon",
  upload: "Upload",
  uploadDesc: "Only jpg. or png. allowed.",
  addIcon: "Online Search",
  transparentIcon: "Transparent Icon",
  text: "Text",
  textDesc: "Please enter text",
  iframe: "Link",
  iframeDesc: "",
  embedCode: "Embed Video",
  embedCodeDesc: "Please input the embedded video code",
  picture: "Image",
  searchImages: "Search Images",
  searchImagesDesc: "Please enter content",
  change: "Change",
  tip10: "Searching desperately",
  video: "Video",
  CenterUpAndDown: "Center",
  selfAdaption: "self-adaption",
  voice: "Recording",
  startRecording: "Start recording",
  endRecording: "Stop and upload",
  RecordingTime: "Length",
  question: "Text",
  option: "Options",
  optionDesc: "Please enter options",
  material: "Material",
  selectTemplate: "Select Template",
  preset: "Preset",
  edit: "Edit",
  enterGroup: "Enter Group",
  groupName: "Group Name",
  enter: "Enter",
  tip11: "Group does not exist",
  topic: "Topic",
  enterTopic: "Enter a topic",
  gain: "Gain",
  description: "Description",
  tip12: "Whether to gain keywords？",
  tip13: "Yes",
  tip14: "Gain keywords again",
  tip15: "Whether to gain questions？",
  tip16: "Gain questions again",
  keyword: "Keyword",
  runResult: "Generate Result",
  directory: "Directory",
  nodeFiltering: "Node Filtering",
  all: "All",
  responsibleTasks: "Responsible Tasks",
  participatingTasks: "Participat Tasks",
  projectName: "Project Name",
  student: "Student",
  collaborate: "Collaborate",
  tip17: "This feature is currently not activated",
  tip18:
    "This operation will delete the selected card and associated connections. Do you want to continue?",
  question_new: "Interrogation",
  tip19: "Select Path",
  tip21: "Select Group",
  replace: "Replace Background",
  mindMap: "Set mindMap",
  promptsList: [
    {
      value: 0,
      label: "Intelligent stage",
      index: 0,
      prompt: [
        {
          value: 0,
          label:
            'Please help me design the course stage of "About scientific inquiry" in the form of paragraphs, which do not need to contain the course name, but only the stage name, and do not need to contain the task name and task description. For example, the first stage: raising questions, the second stage: background research, the third stage: Making hypotheses, the fourth stage: designing experiments for inquiry, the fifth stage: The sixth stage is to analyze data and form opinions',
        },
        {
          value: 1,
          label:
            'Please help me to design the course phase of "About English Writing" in the form of paragraphs. It is not necessary to include the course name, but only the stage name, and it is not necessary to include the task name and task description. For example, Stage 1: Brainstorming Stage 2: Framework writing Stage 3: Expression comparison Stage 4: Free creation Stage 5: Peer evaluation Stage 6: Brainstorming Stage Class summary',
        },
      ],
    },
    {
      value: 1,
      label: "Intelligent Task",
      index: 1,
      prompt: [
        {
          value: 0,
          label:
            'Please help me design a course activity with the theme of "Making Robots" in Markdown format. The attributes only include the task name and task descriptions, and each task requires a line break without outputting a title. For example, Task 1: XXX Task Description: XXX Task 2: XXX Task Description: XXX',
        },
        {
          value: 1,
          label:
            'Please help me design the teaching task of "isosceles triangle" in elementary school mathematics class in the form of Markdown. The task attributes include the task name and task description, and each task requires a new line and no output title. For example, Task 1: XXX; Task Description: XXX Task 2: XXX Task Description: XXX',
        },
        {
          value: 2,
          label:
            'Could you please help me design a task in the form of Markdown to "conduct a scientific inquiry on the factors related to the size of sliding friction?" The task attributes include the task name and description, and each task needs to be separated by a new line, For example, Task 1: Propose a scientific question Task description: "What may be the factors that affect the magnitude of sliding friction force on an object?" Task 2: Establish a scientific hypothesis Task description: "Refer to some relevant books or online literature, reason based on existing knowledge, and write reasonable and valid guesses." Task 3: Design and conduct scientific experiments Task description: The experimental design needs to clarify the experimental process and steps, including experimental materials, experimental and control groups, independent and dependent variables, and data to be recorded. After the design is completed, please carry out the experiment according to the plan. Task 4: Collect and analyze experimental data Task description: "Organize the data recorded in the experiment in the form of a table." Task 5: Summarize scientific conclusions Task description: Please carefully analyze the collected data and, if necessary, use statistical tests to derive conclusions and summarize the experimental results',
        },
        {
          value: 3,
          label:
            'Could you please help me design the task of "Swallow Settles Home and Reading Picture Composition" in the form of Markdown teaching? The task attributes include the task name and task description, and each task requires a new line between them, For example, Task 1: Observe the image Task description: "Look carefully at the image, understand the meaning, what kind of story does the image describe? Please pay attention to the small parts in the image." Task 2: Use imagination Task description: "Expand reasonable imagination, what do you think they will say? What do they do? Why do they say this, do this?" Task 3: Oral training Task description: Choose the appropriate tone and explain the attributes you see, the relationships between them, and your imagination. Task 4: Organize a written task description: "On the basis of language description, add details and your thoughts, and write a complete story in text. Pay attention to the article being segmented and using correct punctuation',
        },
        {
          value: 4,
          label:
            'Please help me design a teaching task in the form of Markdown to explore "the formation of the post-war capitalist world economic system through the discipline of history." The task attributes include the task name and description, and each task needs to be separated by a new line, For example, Task 1: Overall Perception Task Description: "How do you understand the post-war capitalist world system? Independently read to identify attributes related to the capitalist world economic system." Task 2: Basic Exploration Task Description: "Identify the connection between the International Monetary Fund and the Bretton Woods system." Task 3: In-depth Exploration Task Description: Task 4: Exploring and summarizing the background of the establishment of the Bretton Woods system, taking into account the position of the United States in the post-war capitalist economic system. Task 5: Expanding and extending the structure of the post-war capitalist world system. Task description: The collapse of the Bretton Woods system marked the life of gold for the US dollar. Do you think this is a symbol of American decline? Please answer the question from a dialectical perspective',
        },
        {
          value: 5,
          label:
            'Please help me design the task of "How to solve traffic congestion at the school entrance" using six hats in the form of Markdown. The task attributes include the task name and description, and each task requires a new line, For example, Task 1: State the problem Task Description: "What information do you need about the current situation to solve the traffic congestion problem at the school gate?" Task 2: Propose a solution Task Description: "What are your ideas and suggestions for solving this problem? What do you think are the possible solutions and action processes?" Task 3: Evaluate the advantages of the solution Task Description: Why do you think this plan is effective? Why do you think it is worth doing? Task 4: List the shortcomings of the plan Task description: "What do you think are the shortcomings of this plan? What problems will be encountered in implementation?" Task 5: Conduct intuitive judgment of the plan Task description: "From an intuitive perspective, how do you evaluate this plan?" Task 6: Summarize the statement and make a decision Task description: "1 What is the current progress of our discussion on solving this problem? If we want to continue with the next step, what else do we need to do',
        },
        {
          value: 6,
          label:
            'Please help me design a task in the form of Markdown to "organize the characteristics of paintings from different dynasties" using expert jigsaw puzzles. The task attributes include the task name and description, and each task needs to be separated by a new line. For example, Task 1: Student grouping, dividing the puzzle task description: The whole class is divided into several groups, and each group member claims each sub task/sub concept in the total task. Task 2: Collect the puzzle, Self exploration task description: Group members independently think and explore sub tasks/sub concepts. Task 3: Unified puzzle, exchange and share task description: Students who receive the same sub tasks/sub concepts form an expert group for communication and sharing. Task 4: Collaborate and exchange, form a puzzle. Task description: Each small expert returns to their group for communication, and each group member needs to explain their own responsible puzzle section. After overall communication and discussion among group members, form a solution that points to the overall task and complete the puzzle.',
        },
        {
          value: 7,
          label:
            'Could you please help me design a task in the form of Markdown that uses Socrates questioning method to carry out the "When can you feel friendship" task? The task attributes include the task name and task description, and each task needs a new line between them. For example, Task 1: What is this? Task Description: What exactly do you mean by "friendship"? Can you give an example to illustrate the concept you want to express? Task 2: Why? Task Description: Why do you think so? Why did you make such an assumption? Why do you believe this is true? Task 3: So what? Task Description: So what does this mean? So how do you prove or refute this hypothesis? Task 4: Is there any other possibility? Task Description: What other different perspectives may be? Can we approach this matter in other ways or methods? Is there any other possibility?',
        },
      ],
    },
    {
      value: 2,
      label: "Intelligent question making",
      index: 2,
      prompt: [
        {
          value: 0,
          label:
            "Please give me four multiple-choice questions about the history of the Qing Dynasty in the form of rich text. Each multiple-choice question has no less than 4 options. Answers are required, but no explanation is required. Please use Chinese for symbols and Traditional for language.",
        },
        {
          value: 1,
          label:
            "Please give me three multiple-choice questions about primary school mathematics unit conversion in the form of rich text. Each multiple-choice question has no less than 4 options. Answers are required, but no explanation is required. Please use Chinese for symbols and Traditional for language.",
        },
      ],
    },
    {
      value: 3,
      label: "Intelligent Q&A",
      index: 3,
      prompt: [
        {
          value: 0,
          label:
            "Please give me a question about the history of the Qing Dynasty in the form of Markdown. Please use Chinese symbols. For example, Q: In what year was the Qing Dynasty established? Founded by which ethnic group? What was the founder's last name? Answer: The Qing Dynasty was established in 1644 by the Manchus. The founder’s surname was Nurhaci.",
        },
      ],
    },
    {
      value: 12,
      label: "Interdisciplinary learning",
      index: 12,
      prompt: [
        {
          value: 0,
          label:
            'I need to design an interdisciplinary integrated course with the theme of "Designing healthy eating Recipes for a 3-day summer Camp". I need you to do the following: 1) Introduce the basic information of the course 2) List the 4 interdisciplinary concepts 3) break down the interdisciplinary concepts one by one into a series of disciplinary concepts. 4. Based on the concept groups listed, list a series of questions that students can explore',
        },
        {
          value: 1,
          label:
            "Subject core concepts are considered to have the most basic and important concepts in the discipline, and we can find the potential subject core concepts by identifying the high-frequency conceptual terms in the document. Now, the core concepts of the subject are summarized according to the objectives of each section in the document",
        },
        {
          value: 2,
          label:
            "A core concept is a concept that is considered to have the most fundamental and important importance in a subject, should have lasting importance across multiple grades or developmental stages, and be able to provide students with a foundation for understanding more complex or advanced concepts. Thus, we can find potential subject core concepts by identifying high-frequency conceptual terms in the document. Now, the core concepts of the subject are summarized according to the objectives of each section in the document",
        },
      ],
    },
    // {
    //     "value": 4,
    //     "label": "Project-based Learning",
    //     "index": 4,
    //     "prompt": [
    //         {
    //             "value": 0,
    //             "label": "Please give me some reference topics or problems that students can use to carry out project-based learning. Please tell me the problems that students will solve and what kind of learning activities students will experience."
    //         },
    //         {
    //             "value": 1,
    //             "label": "Please describe a project-based learning project about an ecological protection project. You need to describe how students solve this problem. You need to write at least four activities. These activities need to be arranged in a logical relationship."
    //         },
    //         {
    //             "value": 2,
    //             "label": 'Please decompose the project-based learning that drives the question "How to build a distinctive flower bed for a school?" into sub-problems. Divide it into at least 5 sub-problems, and write down the details of each link according to the sub-problems. Main activities.'
    //         }
    //     ]
    // },
    {
      value: 5,
      label: "Teaching Evaluation",
      index: 5,
      prompt: [
        {
          value: 0,
          label:
            "If it is necessary to evaluate students' community services, provide evaluation dimensions and performance descriptions of at least 3 levels.",
        },
        {
          value: 1,
          label:
            "Design an evaluation task table for grade 6 students about the wutong Mountain research report, and give a reference report process, which includes at least 8 steps, including maps, pictures and at least 300 words.",
        },
        {
          value: 2,
          label:
            "Create a classroom quiz for 5th grade students, consisting of 5 multiple-choice questions, to evaluate their understanding of the concept of solar energy. You need to provide the question and the correct answer.",
        },
      ],
    },
    {
      value: 6,
      label: "Instructional Design",
      index: 6,
      prompt: [
        {
          value: 0,
          label:
            'If fifth grade students need to experience the landscape writing technique of "changing scenery with each step", what suitable reading materials do you recommend? You need to give the name of the material and what part of the material it is.',
        },
        {
          value: 1,
          label:
            "Design a geography lesson for 8th grade students on the topic of human migration, and design at least 1 group activity in the lesson.",
        },
        {
          value: 2,
          label:
            "To design a course for fifth grade students that integrates the fields of science and information technology to solve life problems in the biological and environmental fields, you need to give a complete course framework and activities.",
        },
        {
          value: 3,
          label:
            "If students in grade 3 cannot understand the process of photosynthesis, I need you to help me design a teaching activity that supports their understanding. This needs to include the form of the activity, implementation materials, and a checklist.",
        },
        {
          value: 4,
          label:
            "Please give three questions for 8th grade students based on Bayes’ Theorem.",
        },
        {
          value: 5,
          label:
            "If eighth grade students need to understand the cultural development history of the Nile River Basin, what websites or reference books do you recommend?",
        },
      ],
    },
    {
      value: 7,
      label: "Class Management",
      index: 7,
      prompt: [
        {
          value: 0,
          label:
            "Create a set of class slogans for first grade students to ask everyone to pay attention to hygiene and protect the environment. The slogans need to be neatly contrasted and meet the understanding level of first grade students.",
        },
        {
          value: 1,
          label:
            "Design a set of class management rules and regulations for sixth grade students. The content needs to include study, discipline, hygiene, and ideological and moral aspects.",
        },
      ],
    },
    {
      value: 8,
      label: "Classroom Organization",
      index: 8,
      prompt: [
        {
          value: 0,
          label:
            'Please design a group division of labor table for the group activity of "Making Garbage Sorting Flyers". The members of each group should be 4-6 people.',
        },
        {
          value: 1,
          label:
            'Please design a group cooperation convention for the fifth-grade "Campus Plant Illustrated" club course. It must include group member information, group project goals, and completion dates. The total length should not exceed 300 words, and provide at least 3 parts for students to fill in themselves.',
        },
        {
          value: 2,
          label:
            "Please use Socratic questioning to guide fifth grade students to disassemble the driving problem: How to solve the problem of classroom blackboard reflection? This needs to include analysis and experimental investigation of the causes of reflection.",
        },
      ],
    },
    {
      value: 9,
      label: "Teacher Development",
      index: 9,
      prompt: [
        {
          value: 0,
          label:
            "Teachers need to understand the theoretical basis and basic concepts of project-based learning. You need to generate a reading list, which requires Chinese books or literature.",
        },
        {
          value: 1,
          label:
            "Designing a framework for teachers to conduct personal semester summaries needs to reflect progress in teaching, teaching research, and personal learning.",
        },
      ],
    },
    {
      value: 10,
      label: "Project-based Learning",
      index: 10,
      prompt: [
        {
          value: 0,
          label:
            'I want you to serve as a course designer. I will provide some requirements for the course design of project-based learning. These requirements may include grades, subjects, class hours, content, etc., and your job is to provide course content that meets the requirements. This may involve analyzing project-based learning topics and questions, matching content to grade levels and subjects, and designing activities. My first request was "I need some project-based learning topics related to Chinese subjects."',
        },
        {
          value: 1,
          label:
            'If you carry out project-based learning around the first theme you gave, "Creating Stories," please design a driving question for fifth grade students.',
        },
        {
          value: 2,
          label:
            "Please describe a project-based study on ecological protection. You need to describe how students solve this problem. You need to write at least four activities. These activities need to be arranged in a logical relationship.",
        },
        {
          value: 3,
          label:
            'Please design activities for the project-based learning where the driving question is "How to build a distinctive flower bed for the school?". The problem needs to be broken down into at least 5 activities, and the main tasks of each activity should be given in the order of implementation.',
        },
        {
          value: 4,
          label:
            'Based on the project-based learning activity design you gave that addresses the driving question of "How to create a compelling story that resonates with readers?", design learning goals that match the Chinese language subject of fifth grade primary school. You need to divide the learning goals into The three categories are students will know, students will understand and students will be able to. Please give the learning objectives in the form of serial numbers plus target content.',
        },
        {
          value: 5,
          label:
            'You need to give a final task. Students need to complete this task to reflect their achievement of the teaching goal of this project-based learning course: "How to create a compelling story that resonates with readers?" What is this final task?',
        },
        {
          value: 6,
          label:
            "I need to know how to evaluate students' completion of this final task. I need to know the dimensions of evaluation and the performance description of each dimension. It is best to output the evaluation scale you designed in the form of a table.",
        },
      ],
    },
    {
      value: 11,
      label: "Code Analysis",
      index: 11,
      prompt: [
        {
          value: 0,
          label: "What effect does this code achieve?",
        },
        {
          value: 1,
          label: "Please describe this code.",
        },
        {
          value: 2,
          label:
            "Based on this code, give me some suggestions for modification.",
        },
      ],
    },
  ],
  my: "Ny",
  collect1: "Collection 1",
  collect2: "Collection 2",
  share: "share",
  share1: "Share 1",
  share2: "Share 2",
  share4: "Shared by others",
  scaffold: "Scaffold",
  resou: "Resources",
  AI: "AI",
  scientific_inquiry: "Scientific Investigation",
  Turmin: "Turmin Method",
  SE: "5E Instructional Design",
  STEM: "STEM",
  SSE: "3SE Course Design Model",
  SEX: "5EX Model",
  generate: "Generate",
  generate2: "gain task",
  generate3: "gain Resources",
  prompts: "Please enter prompts",
  hide: "Hide Title",
  ai_analyze: "In AI analysis...",
  stageTip: "Stage:",
  taskTip: "Task:",
  chatroom_message_open: "Receive notification",
  chatroom_message_close: "Notice:",
  taskCourseTip: "Creating task-based course, please wait...",
  stageCourseTip: "Creating phased course, please wait...",
  stageProjectTip: "Creating phased project, please wait...",
  networkTip: "The network is connected and retrieving data. Please wait...",
  notNetworkTip:
    "The network connection is abnormal. You can continue editing while offline, and the content will automatically synchronize after connecting to the network.",
  whiteboard_card: "Whiteboard card",
  file: "File",
  uploadTip: "Only pdf, doc, docx, ppt, pptx,xls,xlsx files allowed",
  taskTip2: "Task",
  tip20:
    "The generated format is incorrect, please ensure that the content has the words' Task X: 'and' Task description: ', if not, please modify or get the generated again!",
  ArProse: "Argumentative prose",
  createErrorTip1:
    "There are multiple theme cards, please select one to create the course",
  createErrorTip2:
    "There is ambiguity, please modify the relevant online content before creating the course",
  createCourse: "Create Course",
  runTip2:
    "There is ambiguity, please modify the relevant online settings before generating",
  map_card: "Map card",
  pre_stage: "Previous Stage",
  next_page: "Next Stage",
  export_to: "Export As TXT",
  chat_room: "Chat Room",
  my_group: "My Group",
  close_chat: "Close Chat",
  open_Collapse: "Open/Collapse",
  clear_screen: "Clear Screen",
  pbl: "PBL",
  subject: "subject",
  map_mind: "mapMind",
  distribute_cards: "Send（Ctrl+Q or Cmd+Q）",
  createErrorTip3: "Unable to create non task and non stage courses",
  createErrorTip4:
    "There are multiple theme cards, please select one to create the project",
  createErrorTip5:
    "There is ambiguity, please modify the relevant online content before creating the project",
  createErrorTip6: "Unable to create non stage project",
  delete: "Delete（Backspace or Delete）",
  send: "Distribute（Ctrl+Q or Cmd+Q）",
  analyseTip: "Obtaining data for analysis, please wait...",
  whole: "Whole",
  student_new: "Personage",
  collaborate_new: "Group",
  achievement: "Achievement",
  timeMachine: "Time machine",
  personalImage: "Personal Portrait",
  personalProfile: "Behavior Profile",
  resourceContribution: "Resource Contribution",
  personnelRelations: "Cooperative relationship",
  heatRelations: "Activity heat map",
  contentTip: "The content is about to be released, please stay tuned......",
  selectTip: "Please select",
  setMap: "Set up a map",
  studentTip1: "Create Card",
  studentTip2: "Resource Sharing",
  studentTip3: "Edit",
  studentTip4: "Speech",
  studentTip5: "Create Template",
  studentTip6: "Connection",
  studentTip7: "Edit Other's Card Times",
  times: "Times",
  studentTip8: "Create Resources Times",
  camera_card: "camera",
  groupShare: "Contribution degree",
  confirmSend: "Confirm Distribution",
  sendSuccess: "Successfully Distributed",
  notSend: "Please select a group first",
  beautifyAll: "One click beautification",
  beautifyAllNew: "One click beautification（Ctrl+M）",
  homeworkCard: "Homework Card",
  homeworkName: "Homework",
  homeworkDesc: "Please enter a homework subject",
  homeworkTitle: "Homework Subject",
  homeworkContent: "Homework Description",
  pbl1: "PBL Observation Notes",
  pbl2: "PBL Introduce",
  pbl3: "PBL Cross Disciplinary Template",
  pbl4: "Team Member Introduction Template",
  pbl5: "POV Table",
  pbl6: "Cornell Note Taking Method",
  pbl7: "Socratic Questioning",
  six_thinking: "Six Thinking Hats",
  English_Creative_Writing: "English Creative Writing",
  dashboard: "Dashboard",
  dashboardTip1: "Total Activity",
  dashboardTip2: "Group Angagement",
  dashboardTip3: "Card Number",
  dashboardTip4: "Accumulated Card Number",
  dashboardTip5: "Reduce Quantity",
  dashboardTip6: "Increase",
  create: "Create",
  prd: "Project result distribution",
  lfc: "Login frequency chart",
  top6: "Contribution ranking",
  result_map: "Project outcome map",
  immersive: "Immersive",
  cards: "Cards",
  stageTitle: "Stage Name",
  stageContent: "Stage Description",
  stageContentDesc: "Please enter a stage description",
  restore_background: "Restore default background",
  select_group_value: "Please select group",
  group_achievement: "Group Ranking",
  global_relationship_echarts: "Global diagram",
  select_personage_value: "Please filter",
  Project_outcome: "Project result map",
  setCamera: "Click OK to take a photo",
  resourceClass: "Resource class diagram",
  image: "Image",
  videoAndAudio: "Audio/Video",
  content: "text",
  fileContent: "Document",
  urlAndIframe: "Web Page",
  icfc: "Individuals create frequency charts",
  refreshFrequency: "Refresh frequency",
  ofc: "Operation frequency chart",
  refresh: "refresh",
  card2: "card",
  loading: "Loading......",
  Loaded: "Loaded :",
  save: "Confirm",
  tip25: "Loading desperately",
  vectorgraph: "Vectorgraph",
  satellite_image: "Satellite Image",
  store: "Save",
  tip26: "Please enter the address",
  tip27: "Create",
  tip28: "Example",
  download: "Download",
  tip29: " added ",
  tip30: " Cards",
  tip31: " Removed",
  tip32: " Online",
  tip33: " Offline",
  tip34: " Performed Empty operation!",
  edit_Card: "Edit Card",
  call_word: "call-word",
  group_list: "Group list",
  global_view: "Global view",
  look_last_card: "View the last edited card",
  view_topic: "View inception topic",
  tip35: "Left click to select ",
  tip36: "obtaining the desired scaffolding resource card,",
  tip37: "the resource templates can be placed with mouse clicks ",
  tip38: "in the corresponding position!",
  tip39: "After clicking on the selection, follow a ",
  tip40: "template thumbnail with the mouse and click to place it ",
  tip41: "in the corresponding position on the canvas",
  errorTip:
    "Data is abnormal, please use the exception repair button to repair it",
  repair: "Abnormal Repair",
  errorTip1:
    "This repair operation will delete all online files. Do you want to continue?",
  tip42: "Repair Successful",
  save_map: "Map saving...",
  unable_to_connect_with_theme: "Does not support to connect theme card",
  tip43: "Does not support card connection to the card itself",
  Sun: "Sun",
  Mon: "Mon",
  Tues: "Tues",
  Wed: "Wed",
  Thur: "Thur",
  Fir: "Fir",
  Sat: "Sat",
  deleteGraps: "Delete",
  aidh: "(Chat):",
  cardfx: "(Card Analysis):",
  userxwfx: "(User behaviors Analysis):",
  contribution_degree: "The member's contribution is as follows:",
  contribution: "The contribution proportion is:",
  ap: "The member activity is shown as：",
  dynamic1: "Very active",
  dynamic2: "More active",
  dynamic3: "dynamic",
  dynamic4: "normal",
  dynamic5: "inertia",
  zhang: "sheet of ",
  add_node: "add card",
  delete_card: "delete card:",
  article: "article",
  add_edge: "add edge：",
  updata_edge: "updata edge：",
  delete_edge: "delete edge：",
  operation_record: "Operation Logs",
  sumbit_job: "Submit Homework",
  requirements: "Requirements",
  job_reception_area: "Submissions",
  jobTip: "Submitting homework, please wait...",
  tip44: "Submitted successfully",
  tip45: "Self-adaption",
  CPOTE: "C-POTE mode case",
  CPOTE2: "C-POTE model course design process",
  subject_icon: "Curriculum standard of compulsory education in each subject",
  educational_base: "Future Education Base Phase II summary",
  Project_learning_curriculum_design_framework:
    "Project learning curriculum design framework",
  lxdh: "Sequential chat:",
  unable_to_connect_with_other:
    "These two cards are already connected and do not support reconnection",
  kwl: "KWL Diagram",
  w: "W Diagram",
  x: "X-plot",
  y: "Y Graph",
  we: "Wayne's three circle chart",
  venn: "Wayne Diagram",
  fishbone_diagram: "Fishbone Diagram",
  launch: "Expand",
  closeItem: "Put Away",
  tip46: "Please provide '",
  tip47: "' Labeling",
  tip48: "Originator or Contributor",
  title48:
    "Give new meaning or definition to a topic or problem, or propose solutions.",
  tip49: "Information Explorer",
  title49:
    "Emphasize the importance of obtaining relevant facts through the background of information obtained from others",
  tip50: "Opinion Explorer",
  title50:
    "Obtain higher quality data types such as attitudes, evaluations, and emotions",
  tip51: "Information Provider",
  title51: "Provide experience or authoritative reporting.",
  tip52: "Opinion Provider",
  title52:
    "Provide opinions, opinions, additions, amendments, support or objections to suggestions.",
  tip53: "Scrutinizer",
  title53:
    "Provide explanations or reasonable explanations, or deduce opinions.",
  tip54: "Coordinator",
  title54:
    "Build bridges for various opinions and shuttle between groups, attempting to combine opinions with behavior.",
  tip55: "Guide",
  title55:
    "Indicate the intention of the opinion, guide the goal, or provide direction for discussion.",
  tip56: "Critics",
  title56:
    "Propose some standards to evaluate and consult on the implementation, logic, or procedural issues of group lectures.",
  tip57: "Refueler",
  title57:
    "Encourage group decision-making or action or stimulate the group to reach climax.",
  tip58: "Action Experts",
  title58:
    "Facilitate group action, assign tasks, control progress, and arrange affairs.",
  tip59: "Recorder",
  title59: "Draft and record proposals, publish conclusions.",
  tip60: "Save Label",
  tip61: "Double click on the card to activate the camera",
  tip62: " modified one ",
  tip63: "content is: ",
  tip64: "",
  tip65: "title is: ",
  tip66: "The modified content is: ",
  tip67: "The modified title is: ",
  tip68: " connected two cards",
  tip69: " delete Connection",
  tip70: " added one ",
  tip71: " Removed one ",
  tip72: " modified Connection",
  tip73: " moved one ",
  tip74: " ",
  tip75: "Select All",
  Editing: "Editing",
  myGroup: "My Group",
  among: "among,",
  add_ap1: "Very large",
  add_ap2: "relatively large",
  add_ap3: "Larger",
  add_ap4: "normal",
  add_ap5: "small",
  operation_card: "operation card:",
  character: "role tag",
  tip76: "Come and create your whiteboard",
  tip77: "Turn on the microphone",
  tip78: "Turn off microphone",
  ask_question: "Ask Question",
  answer_question: "Answer",
  sumbit_question: "Submit",
  sumbit_question_again: "Continue to ask a question",
  tip79: "Press '/' to display prompt words",
  tip80: "File Selection",
  tip81: "Generate Text",
  tip82: "Generate a image",
  tip83: "Generate audio",
  tip84: "Obtaining answer, please wait",
  tip85: "Parsing voice, please wait",
  tip86: "Image size cannot exceed 4MB",
  tip87: "Question cannot be empty",
  aite_ai_desc:
    "Please enter the content, enter @ select AI can evoke AI function",
  tip88: "Termination",
  share3: "Share",
  fzcg: "The sharing connection has been generated! The connection has been copied to the clipboard",
  fzsb: "Copy failed",
  tip89: "Enter demonstration mode?",
  tip90: "Enter",
  tip91: "Copy successful",
  shareContent: "Sharing content",
  tip92: "Only Demonstrate",
  tip93: "URL",
  tip94: "Copy URL",
  tip95: "Invitation code",
  tip96: "Copy Invitation code",
  tip97: "QR code",
  tip98: "Download QR code",
  tip99: "Invitation code?",
  tip100: "Need",
  tip101: "Not Need",
  tip102: "Regenerate",
  tip103:
    "The invitation code is incorrect. Please inquire to obtain the correct invitation code",
  tip104: "Select groups and file formats",
  tip105: "Text Files",
  tip106: "File Formats",
  pocket_monster: "Pocket Monster",
  pocket_monster_1: "Monster 1",
  pocket_monster_2: "Monster 2",
  move_key: "Move button",
  move_key_1: "Upward",
  move_key_2: "Downward",
  move_key_3: "Left",
  move_key_4: "Right",
  move_distance: "Moving Step Size",
  move_distance_x: "X-axis",
  move_distance_y: "Y-axis",
  tip107: "backgroundColor",
  tip108: "Background Gradient Color",
  tip109: "Text Color",
  tip110: "Fill Style",
  tip111:
    "Unable to obtain project cid. Please use the refresh button to refresh the page and click share again",
  tip112: "Create AI NPC",
  tip113: "Modify AI NPC",
  prologue: "Opening Remarks",
  isPublish: "Publishing Permissions",
  knowledge_base: "Exclusive Knowledge Base",
  upload_file: "Upload Files",
  upload_file_desc: "Instructions for uploading files",
  tip114: "Successfully saved",
  upload_image: "Upload images",
  select_local_file: "Select Local File",
  select_network_file: "Select Network File",
  sumbit_error_message:
    "Please ensure that the description, prompt, opening statement, and name are not empty",
  people: "Character",
  sendButton: "Send",
  tip115: "Content input in progress",
  tip116: "Press the Enter key to start the conversation",
  tip117: "Me",
  agent: "(AI Agent)",
  tip118: ":",
  tip119: "Whiteboard",
  tip120: "Set Background",
  tip121: "Hidden",
  tip122: "Not hidden",
  tip123: "Hide",
  tip124: "Press the Enter key to open",
  geneTask: "Generate Task Outline",
  geneTasktext: "Generating task outline, please wait...",
  geneResourse: "Generate Lesson Plans",
  geneResoursetext: "Generating lesson plan, please wait...",
  draw: "Brush",
  comment: "Comment",
  zan: "Like",
  publicGroup: "Public Group",
  role: "Purview",
  allStudentRead: "Allow students to view other groups (except for my group)",
  allStudentEdit: "Allow students to edit other groups (except for my group)",
  setRole: "Permission Settings",
  setRoleSuccess: "Permission setting successful",
  accept: "Accept",
  discuss: "Start Discussion",
  discussion_ends: "Close Discussion",
  markdownCard: "MarkDown Card",
  name_exist: "This name already exists, please change!",
  name_exist_1: "Agents cannot have the same name, remember to click 'Save' after modifying",
  prompt: "Prompt words",
  function_call: "Function Call",
  gen_picture: "Image Generation",
  web_search: "Web Search",
  gen_audio: "Audio Generation",
  Oral_scoring: "Oral Scoring",
  Set_topic: "Set Question",
  Solve_problems: "Solve Question",
  bing_search: "Bing Search",
  google_search: "Google Search",
  youtube_search: "YouTube Video Search",
  bilibili_search: "Bilibili Video Search",
  scrape: "Web Crawl",
  according: "Based on the above content:",
  accordingTip: "generate related",
  accordingTip2: "'s tip and reply in the second person tone and in the same language",
  forget: "Please forget the last conversation",
  data: "Data:",
  chatData: "Chat History",
  monitorData: "Monitoring History",
  fileFormat: "File Format:",
};
