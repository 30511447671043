import { LineEdge, LineEdgeModel } from "@logicflow/core";
import {
  getShapeStyleFuction,
  getTextStyleFunction,
} from "../getShapeStyleUtil";

// 直线
class Model extends LineEdgeModel {
  constructor(data, graphModel) {
    super(data, graphModel);
    this.strokeWidth = 1;
    this.text.editable = false; // 禁止节点文本编辑
  }
  getTextStyle() {
    const style = super.getTextStyle();
    return getTextStyleFunction(style, this.properties);
  }

  getEdgeStyle() {
    const attributes = super.getEdgeStyle();
    const properties = this.properties;
    const style = getShapeStyleFuction(attributes, properties);
    style.strokeWidth = 1;
    if (properties.isHidden == "2") {
      style.stroke = "#000000";
    } else if (properties.isHidden == "1") {
      style.stroke = "none";
    }
    return { ...style, fill: "none" };
  }
}
export default {
  type: "pro-line",
  view: LineEdge,
  model: Model,
};
