<template>
  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjI5OyYjMTc1
OyYjMTg4OyYjMjI5OyYjMTM1OyYjMTg2OyI+CjxwYXRoIGlkPSJWZWN0b3IgNDMgKFN0cm9rZSki
IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiA4QzIgOC4yNzYx
NCAyLjIyMzg2IDguNSAyLjUgOC41SDRDNC4yNzYxNCA4LjUgNC41IDguMjc2MTQgNC41IDhDNC41
IDcuNzIzODYgNC4yNzYxNCA3LjUgNCA3LjVIM1YzSDEzVjcuNUgxMkMxMS43MjM5IDcuNSAxMS41
IDcuNzIzODYgMTEuNSA4QzExLjUgOC4yNzYxNCAxMS43MjM5IDguNSAxMiA4LjVIMTMuNUMxMy43
NzYxIDguNSAxNCA4LjI3NjE0IDE0IDhWMi41QzE0IDIuMjIzODYgMTMuNzc2MSAyIDEzLjUgMkgy
LjVDMi4yMjM4NiAyIDIgMi4yMjM4NiAyIDIuNVY4WiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0
eT0iMC45Ii8+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMC44NzUgMTAuNUw4IDE0TDUuMTI1IDEw
LjVINy41VjQuNUM3LjUgNC4yMjM4NiA3LjcyMzg2IDQgOCA0QzguMjc2MTQgNCA4LjUgNC4yMjM4
NiA4LjUgNC41VjEwLjVIMTAuODc1WiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC45Ii8+
CjwvZz4KPC9zdmc+Cg=="
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
