import { createRouter, createWebHistory } from "vue-router";
import Share from "../components/Share.vue";
import Home from "../components/Home.vue";

// 防止跳转同一路径出异常
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

const router = new createRouter({
  history: createWebHistory(), // 可以使用HTML5历史记录，去掉URL中的#
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/share",
      name: "share",
      component: Share,
    },
  ],
});

export default router;
