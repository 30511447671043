<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
      <path
        d="M933.647059 0h-210.82353v90.352941h180.705883a30.117647 30.117647 0 0 1 30.117647 30.117647v180.705883h90.352941V90.352941a90.352941 90.352941 0 0 0-90.352941-90.352941zM361.411765 0h301.17647v90.352941H361.411765zM933.647059 361.411765h90.352941v301.17647h-90.352941zM361.411765 933.647059h301.17647v90.352941H361.411765zM0 361.411765h90.352941v301.17647H0zM90.352941 903.529412v-180.705883H0v210.82353a90.352941 90.352941 0 0 0 90.352941 90.352941h210.82353v-90.352941H120.470588a30.117647 30.117647 0 0 1-30.117647-30.117647zM933.647059 903.529412a30.117647 30.117647 0 0 1-30.117647 30.117647h-180.705883v90.352941h210.82353a90.352941 90.352941 0 0 0 90.352941-90.352941v-210.82353h-90.352941zM0 90.352941v210.82353h90.352941V120.470588a30.117647 30.117647 0 0 1 30.117647-30.117647h180.705883V0H90.352941a90.352941 90.352941 0 0 0-90.352941 90.352941z"
        p-id="1890">
      </path>
    </svg> -->

  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMxOyYjMTg4
OyYjMTY5OyYjMjMwOyYjMTQ4OyYjMTkwOyYjMjMyOyYjMTM1OyYjMTc5OyYjMjI4OyYjMTg0OyYj
MTM4OyYjMjI4OyYjMTg0OyYjMTI4OyYjMjI4OyYjMTg0OyYjMTcwOyYjMjI5OyYjMTQxOyYjMTYx
OyYjMjMxOyYjMTM3OyYjMTM1OyI+CjxnIGlkPSJHcm91cCA4OTUiPgo8cGF0aCBpZD0iVmVjdG9y
IDUyIChTdHJva2UpIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0i
TTYuMTQ2NDUgNS4xNDY0NUM2LjM0MTcxIDQuOTUxMTggNi42NTgyOSA0Ljk1MTE4IDYuODUzNTUg
NS4xNDY0NUw4Ljg1MzU1IDcuMTQ2NDVDOS4wNDg4MiA3LjM0MTcxIDkuMDQ4ODIgNy42NTgyOSA4
Ljg1MzU1IDcuODUzNTVMNi44NTM1NSA5Ljg1MzU1QzYuNjU4MjkgMTAuMDQ4OCA2LjM0MTcxIDEw
LjA0ODggNi4xNDY0NSA5Ljg1MzU1QzUuOTUxMTggOS42NTgyOSA1Ljk1MTE4IDkuMzQxNzEgNi4x
NDY0NSA5LjE0NjQ1TDcuMjkyODkgOEgxLjVDMS4yMjM4NiA4IDEgNy43NzYxNCAxIDcuNUMxIDcu
MjIzODYgMS4yMjM4NiA3IDEuNSA3SDcuMjkyODlMNi4xNDY0NSA1Ljg1MzU1QzUuOTUxMTggNS42
NTgyOSA1Ljk1MTE4IDUuMzQxNzEgNi4xNDY0NSA1LjE0NjQ1WiIgZmlsbD0iYmxhY2siIGZpbGwt
b3BhY2l0eT0iMC45Ii8+CjxwYXRoIGlkPSJFbGxpcHNlIDggKFN0cm9rZSkiIGZpbGwtcnVsZT0i
ZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOSAyLjVDNy41NjcyMSAyLjUgNi4yNzU4
MyAzLjEwMjAxIDUuMzYzNTggNC4wNjgzQzUuMTc0MDEgNC4yNjkxIDQuODU3NTYgNC4yNzgyIDQu
NjU2NzYgNC4wODg2NEM0LjQ1NTk2IDMuODk5MDcgNC40NDY4NiAzLjU4MjYyIDQuNjM2NDMgMy4z
ODE4MkM1LjcyOTcyIDIuMjIzNzcgNy4yODA3NCAxLjUgOSAxLjVDMTIuMzEzNyAxLjUgMTUgNC4x
ODYyOSAxNSA3LjVDMTUgMTAuODEzNyAxMi4zMTM3IDEzLjUgOSAxMy41QzcuMjgwNzQgMTMuNSA1
LjcyOTcyIDEyLjc3NjIgNC42MzY0MyAxMS42MTgyQzQuNDQ2ODYgMTEuNDE3NCA0LjQ1NTk2IDEx
LjEwMDkgNC42NTY3NiAxMC45MTE0QzQuODU3NTYgMTAuNzIxOCA1LjE3NDAxIDEwLjczMDkgNS4z
NjM1OCAxMC45MzE3QzYuMjc1ODMgMTEuODk4IDcuNTY3MjEgMTIuNSA5IDEyLjVDMTEuNzYxNCAx
Mi41IDE0IDEwLjI2MTQgMTQgNy41QzE0IDQuNzM4NTggMTEuNzYxNCAyLjUgOSAyLjVaIiBmaWxs
PSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjkiLz4KPC9nPgo8L2c+Cjwvc3ZnPgo="
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
