<template>
  <!-- <svg class="icon" viewBox="0 0 1024 1024" :width="size" :height="size">
      <path d="M967.111111 967.111111h-113.777778v-113.777778c0-221.866667-176.355556-398.222222-398.222222-398.222222H113.777778V341.333333h341.333333c284.444444 0 512 227.555556 512 512v113.777778z" fill="#0D1733" p-id="1011">
      </path>
      <path d="M409.6 762.311111L51.2 398.222222 409.6 39.822222l85.333333 79.644445-284.444444 278.755555 284.444444 284.444445z" fill="#0D1733" p-id="1012">
      </path>
    </svg> -->

  <svg
    id="Layer_1"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    enable-background="new 0 0 20 20"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="20"
      height="20"
      x="0"
      y="0"
      href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjMwOyYjMTI5
OyYjMTYyOyYjMjI5OyYjMTY0OyYjMTQxOyYjMjMyOyYjMTMxOyYjMTQwOyYjMjMwOyYjMTUzOyYj
MTc1OyI+CjxwYXRoIGlkPSJVbmlvbiIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2
ZW5vZGQiIGQ9Ik0zLjQxMTc2IDJDMy4xMzMzIDIgMi45MDc1NiAyLjIyNTc0IDIuOTA3NTYgMi41
MDQyVjQuMDE2ODFDMi45MDc1NiA0LjI5NTI3IDMuMTMzMyA0LjUyMTAxIDMuNDExNzYgNC41MjEw
MUMzLjY5MDIyIDQuNTIxMDEgMy45MTU5NiA0LjI5NTI3IDMuOTE1OTYgNC4wMTY4MVYzLjAwODRI
MTIuOTkxNlYxMS4wNzU2SDguOTU3OTdDOC42Nzk1MSAxMS4wNzU2IDguNDUzNzcgMTEuMzAxNCA4
LjQ1Mzc3IDExLjU3OThDOC40NTM3NyAxMS44NTgzIDguNjc5NTEgMTIuMDg0IDguOTU3OTcgMTIu
MDg0SDEzLjQ5NThDMTMuNzc0MyAxMi4wODQgMTQgMTEuODU4MyAxNCAxMS41Nzk4VjIuNTA0MkMx
NCAyLjIyNTc0IDEzLjc3NDMgMiAxMy40OTU4IDJIMy40MTE3NlpNMy45MTU5NiA5LjA1ODgyQzMu
OTE1OTYgOC43ODAzNiAzLjY5MDIyIDguNTU0NjIgMy40MTE3NiA4LjU1NDYyQzMuMTMzMyA4LjU1
NDYyIDIuOTA3NTYgOC43ODAzNiAyLjkwNzU2IDkuMDU4ODJWMTEuNTc5OEMyLjkwNzU2IDExLjg1
ODMgMy4xMzMzIDEyLjA4NCAzLjQxMTc2IDEyLjA4NEgzLjkxNTk2QzQuMTk0NDIgMTIuMDg0IDQu
NDIwMTYgMTEuODU4MyA0LjQyMDE2IDExLjU3OThDNC40MjAxNiAxMS4zMDE0IDQuMTk0NDIgMTEu
MDc1NiAzLjkxNTk2IDExLjA3NTZWOS4wNTg4MlpNMi40MDMzNiA1LjEyNjA2QzIuMTgwNTkgNS4x
MjYwNiAyIDUuMzA2NjUgMiA1LjUyOTQyVjcuNTQ2MjJDMiA3Ljc2ODk5IDIuMTgwNTkgNy45NDk1
OCAyLjQwMzM2IDcuOTQ5NThIMTAuOTc0OEMxMS4xOTc2IDcuOTQ5NTggMTEuMzc4MSA3Ljc2ODk5
IDExLjM3ODEgNy41NDYyMlY2Ljk0MTE4SDExLjU3OThWOC4yMjM5NUw2LjMyODQ2IDkuMTY3NUM2
LjEzOTkyIDkuMjA1MjEgNi4wMDQyIDkuMzcwNzUgNi4wMDQyIDkuNTYzMDNWMTAuMTY4MUg1Ljkw
MzM2QzUuNjgwNTkgMTAuMTY4MSA1LjUgMTAuMzQ4NyA1LjUgMTAuNTcxNFYxMy41OTY2QzUuNSAx
My44MTk0IDUuNjgwNTkgMTQgNS45MDMzNiAxNEg2LjkxMTc2QzcuMTM0NTMgMTQgNy4zMTUxMyAx
My44MTk0IDcuMzE1MTMgMTMuNTk2NlYxMC41NzE0QzcuMzE1MTMgMTAuMzQ4NyA3LjEzNDUzIDEw
LjE2ODEgNi45MTE3NiAxMC4xNjgxSDYuODEwOTJWOS44OTM3MUwxMi4wNjIzIDguOTUwMTVDMTIu
MjUwOCA4LjkxMjQ1IDEyLjM4NjUgOC43NDY5IDEyLjM4NjUgOC41NTQ2M1Y2LjUzNzgyQzEyLjM4
NjUgNi4zMTUwNSAxMi4yMDYgNi4xMzQ0NiAxMS45ODMyIDYuMTM0NDZIMTEuMzc4MVY1LjUyOTQy
QzExLjM3ODEgNS4zMDY2NSAxMS4xOTc2IDUuMTI2MDYgMTAuOTc0OCA1LjEyNjA2SDIuNDAzMzZa
TTYuMzA2NzIgMTMuMTkzM1YxMC45NzQ4SDYuNTA4NFYxMy4xOTMzSDYuMzA2NzJaTTIuODA2NzIg
Ny4xNDI4NlY1LjkzMjc4SDEwLjU3MTRWNy4xNDI4NkgyLjgwNjcyWiIgZmlsbD0iYmxhY2siIGZp
bGwtb3BhY2l0eT0iMC45Ii8+CjwvZz4KPC9zdmc+Cg=="
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "24",
    },
  },
};
</script>
