<template>
    <svg id="Layer_1" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024" p-id="1550" :width="size"
        :height="size">
        <path
            d="M570.5984 156.0192a281.8048 281.8048 0 0 0-24.1792-10.3424l8.9472-23.9872c8.9344 3.3408 17.8304 7.1424 26.4064 11.2896l-11.1744 23.04z"
            fill="#525252" p-id="1832"></path>
        <path
            d="M448 716.8C278.6176 716.8 140.8 578.9824 140.8 409.6S278.6176 102.4 448 102.4c27.6096 0 54.9888 3.6608 81.3568 10.8928l-6.7712 24.6912A281.8816 281.8816 0 0 0 448 128C292.7232 128 166.4 254.3232 166.4 409.6s126.3232 281.6 281.6 281.6l1.8048-0.0128c-0.384-5.312-0.576-10.3552-0.576-15.2192 0-130.7392 106.368-237.1072 237.0944-237.1072 13.44 0 27.2128 1.2672 41.088 3.7504 1.4592-11.648 2.1888-22.528 2.1888-33.0112 0-98.688-50.2784-188.48-134.5024-240.1792l13.4016-21.8112C700.3648 203.9936 755.2 301.9392 755.2 409.6c0 15.7568-1.4592 32.256-4.48 50.4064a12.7744 12.7744 0 0 1-15.5776 10.368 212.4544 212.4544 0 0 0-48.8192-5.9008c-116.6208 0-211.4944 94.8864-211.4944 211.5072 0 7.7696 0.5632 16.1792 1.728 25.6896a12.8 12.8 0 0 1-12.032 14.3232l-4.7744 0.2944c-3.904 0.256-7.7824 0.512-11.7504 0.512z"
            fill="#525252" p-id="1833"></path>
        <path
            d="M601.6 396.8c-42.3552 0-76.8-34.4448-76.8-76.8s34.4448-76.8 76.8-76.8 76.8 34.4448 76.8 76.8-34.4448 76.8-76.8 76.8z m0-128c-28.2368 0-51.2 22.9632-51.2 51.2s22.9632 51.2 51.2 51.2 51.2-22.9632 51.2-51.2-22.9632-51.2-51.2-51.2zM435.2 307.2c-42.3552 0-76.8-34.4448-76.8-76.8s34.4448-76.8 76.8-76.8 76.8 34.4448 76.8 76.8-34.4448 76.8-76.8 76.8z m0-128c-28.2368 0-51.2 22.9632-51.2 51.2s22.9632 51.2 51.2 51.2 51.2-22.9632 51.2-51.2-22.9632-51.2-51.2-51.2zM279.7696 448c-42.3552 0-76.8-34.4448-76.8-76.8s34.4448-76.8 76.8-76.8v25.6c-28.2368 0-51.2 22.9632-51.2 51.2s22.9632 51.2 51.2 51.2a51.2512 51.2512 0 0 0 50.1632-61.5168l25.0752-5.184A76.8768 76.8768 0 0 1 279.7696 448z"
            fill="#525252" p-id="1834"></path>
        <path
            d="M321.1136 340.9664a51.4176 51.4176 0 0 0-21.4144-16.9472l9.9712-23.5904a76.928 76.928 0 0 1 32.0896 25.4208l-20.6464 15.1168zM448 473.6c-35.2896 0-64-28.7104-64-64s28.7104-64 64-64 64 28.7104 64 64-28.7104 64-64 64z m0-102.4c-21.1712 0-38.4 17.2288-38.4 38.4s17.2288 38.4 38.4 38.4 38.4-17.2288 38.4-38.4-17.2288-38.4-38.4-38.4zM345.6 627.2c-42.3552 0-76.8-34.4448-76.8-76.8s34.4448-76.8 76.8-76.8 76.8 34.4448 76.8 76.8-34.4448 76.8-76.8 76.8z m0-128c-28.2368 0-51.2 22.9632-51.2 51.2s22.9632 51.2 51.2 51.2 51.2-22.9632 51.2-51.2-22.9632-51.2-51.2-51.2z"
            fill="#525252" p-id="1835"></path>
        <path d="M806.4 281.6h76.8v25.6h-76.8z" fill="#525252" p-id="1836"></path>
        <path
            d="M844.2496 793.6c-13.8496 0-28.3392-8.704-39.872-112.7808-7.296-65.8304-11.328-153.2544-11.328-246.1696 0-112.2688 5.8624-233.8048 14.2464-295.5776 0.8704-6.336 6.2976-11.072 12.6976-11.072h48.5248a12.8 12.8 0 0 1 12.6848 11.072c8.384 61.7728 14.2464 183.3088 14.2464 295.5776 0 92.9152-4.0192 180.3392-11.328 246.1696C872.576 784.896 858.0992 793.6 844.2496 793.6z m-12.9792-640c-7.5008 63.488-12.6208 176.2176-12.6208 281.0496 0 173.888 13.6448 290.6624 25.6 325.3888 11.9552-34.7392 25.6-151.5008 25.6-325.3888 0-104.832-5.12-217.5616-12.6208-281.0496h-25.9584z"
            fill="#525252" p-id="1837"></path>
        <path
            d="M870.4 153.6h-51.2a12.8 12.8 0 0 1-12.6976-11.2128l-12.8-102.4A12.8128 12.8128 0 0 1 806.4 25.6h76.2496a12.8256 12.8256 0 0 1 12.7104 14.3104l-12.2496 102.4A12.8 12.8 0 0 1 870.4 153.6z m-39.8976-25.6h28.5312l9.1904-76.8h-47.3216l9.6 76.8z"
            fill="#525252" p-id="1838"></path>
    </svg>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: "24",
        },
    },
};
</script>
