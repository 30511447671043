import { h } from "@logicflow/core";
import RectNode from "../basic/RectNode";

class DrawNodeModel extends RectNode.model {
  initNodeData(data) {
    super.initNodeData(data);
    this.text.editable = false; // 禁止节点文本编辑
    this.width = data.width
    this.height = data.height
    this.x = data.x + data.width * 1 / 2
    this.y = data.y + data.height * 1 / 2
    this.properties = data.properties
  }
  getData() {
    return {
      ...super.getData(),
      x: this.x - this.width * 1 / 2,
      y: this.y - this.height * 1 / 2,
      width: this.width,
      height: this.height,
    }
  }
}

class DrawNodeView extends RectNode.view {
  getImageHref() {
    return this.props.model.properties.href
  }
  getShape() {
    const { id, x, y, width, height } = this.props.model;
    const href = this.getImageHref();
    const opacity = this.props.model.getNodeStyle().opacity;
    const attrs = {
      id: "svgImage_" + id,
      x: x - width * 1 / 2,
      y: y - height * 1 / 2,
      width,
      height,
      href,
      opacity,
      // 根据宽高缩放
      preserveAspectRatio: "none meet",
    };
    return h("g", {}, [h("image", { ...attrs })]);
  }
}

export default {
  type: "draw-node",
  view: DrawNodeView,
  model: DrawNodeModel,
};
