import { MiniMap } from "@logicflow/extension";

export default class MyMiniMap extends MiniMap {
    setOption(options) {
        const {
            showEdge,
            isShowHeader,
            isShowCloseIcon,
            leftPosition,
            topPosition,
            rightPosition,
            bottomPosition,
            headerTitle,
        } = options
        this.showEdge = showEdge
        this.isShowHeader = isShowHeader
        this.isShowCloseIcon = isShowCloseIcon
        this.leftPosition = leftPosition
        this.rightPosition = rightPosition
        this.topPosition = topPosition
        this.bottomPosition = bottomPosition
        this.headerTitle = headerTitle
    }
}
MyMiniMap.pluginName = "miniMap";