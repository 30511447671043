<template>
  <div class="centent">
    <div
      v-for="item of $store.state.group"
      :key="item.id"
      class="flex_x_center"
      style="width: 50%"
    >
      <div :id="'Radar_' + item.id" class="Radar flex_x_center" />
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Character",
  props: {},
  data() {
    return {};
  },
  watch: {
    "$store.state.group"() {
      this.init_radar();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_radar();
    });
  },
  methods: {
    init_radar() {
      this.$store.state.group.forEach((group) => {
        const group_member_lenght = group.group_member.lenght;
        let [
          tip48,
          tip49,
          tip50,
          tip51,
          tip52,
          tip53,
          tip54,
          tip55,
          tip56,
          tip57,
          tip58,
          tip59,
        ] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        group.character.forEach((character) => {
          if (character.name.includes(this.language.tip48)) {
            tip48++;
          }
          if (character.name.includes(this.language.tip49)) {
            tip49++;
          }
          if (character.name.includes(this.language.tip50)) {
            tip50++;
          }
          if (character.name.includes(this.language.tip51)) {
            tip51++;
          }
          if (character.name.includes(this.language.tip52)) {
            tip52++;
          }
          if (character.name.includes(this.language.tip53)) {
            tip53++;
          }
          if (character.name.includes(this.language.tip54)) {
            tip54++;
          }
          if (character.name.includes(this.language.tip55)) {
            tip55++;
          }
          if (character.name.includes(this.language.tip56)) {
            tip56++;
          }
          if (character.name.includes(this.language.tip57)) {
            tip57++;
          }
          if (character.name.includes(this.language.tip58)) {
            tip58++;
          }
          if (character.name.includes(this.language.tip59)) {
            tip59++;
          }
        });
        const chartDom = document.getElementById("Radar_" + group.id);
        const myChart = echarts.init(chartDom);
        let option;
        option = {
          title: {
            text: group.name,
          },
          tooltip: {
            show: true,
            position: "inside",
          },
          radar: {
            indicator: [
              { name: this.language.tip48, max: group_member_lenght },
              { name: this.language.tip49, max: group_member_lenght },
              { name: this.language.tip50, max: group_member_lenght },
              { name: this.language.tip51, max: group_member_lenght },
              { name: this.language.tip52, max: group_member_lenght },
              { name: this.language.tip53, max: group_member_lenght },
              { name: this.language.tip54, max: group_member_lenght },
              { name: this.language.tip55, max: group_member_lenght },
              { name: this.language.tip56, max: group_member_lenght },
              { name: this.language.tip57, max: group_member_lenght },
              { name: this.language.tip58, max: group_member_lenght },
              { name: this.language.tip59, max: group_member_lenght },
            ],
          },
          series: [
            {
              type: "radar",
              tooltip: {
                trigger: "item",
              },
              areaStyle: {},
              data: [
                {
                  value: [
                    tip48,
                    tip49,
                    tip50,
                    tip51,
                    tip52,
                    tip53,
                    tip54,
                    tip55,
                    tip56,
                    tip57,
                    tip58,
                    tip59,
                  ],
                  name: this.language.character,
                },
              ],
            },
          ],
        };

        option && myChart.setOption(option);
      });
    },
  },
};
</script>
<style scoped>
.centent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.Radar {
  width: 400px;
  height: 410px;
}
</style>
