import EvaluationNode from "./EvaluationNode";

// 左上角ICON为消息的节点
class MultipleChoiceNode extends EvaluationNode.view {
  getImageHref() {
    return require("@/assets/tools/选择填空.png");
  }
}

export default {
  type: "icon-multiple-choice",
  view: MultipleChoiceNode,
  model: EvaluationNode.model,
};
