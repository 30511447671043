import { LineEdgeModel, LineEdge, h } from "@logicflow/core";

class CustomEdgeModel extends LineEdgeModel {
  constructor(data, graphModel) {
    super(data, graphModel);
    this.strokeWidth = 2;
  }
  getEdgeStyle() {
    const edgeStyle = super.getEdgeStyle();
    //可以自己设置线的显示样式，甚至隐藏掉原本的线，自己用react绘制
    edgeStyle.strokeDasharray = "4 4";
    edgeStyle.stroke = "#000000";
    edgeStyle.strokeWidth = 2;
    // edgeStyle.stroke = '#000000'
    return edgeStyle;
  }
}

class CustomEdgeView extends LineEdge {
  getEdge() {
    const { model } = this.props;
    const edgeStyle = model.getEdgeStyle();
    const { startPoint, endPoint } = model;
    const lineData = {
      x1: startPoint.x,
      y1: startPoint.y,
      x2: endPoint.x,
      y2: endPoint.y,
    };

    return h("g", {}, [h("line", { ...lineData, ...edgeStyle })]);
  }

  getArrow() {
    return h("g", {}, []);
  }

  getAppend() {
    return h("g", {}, []);
  }
}

export default {
  type: "pro-customDottedLine",
  view: CustomEdgeView,
  model: CustomEdgeModel,
};
