import { h } from "@logicflow/core";
import { dynamicGroup } from "@logicflow/extension";
import { isArray } from 'lodash-es'
import { sendUpdate, add_Operation } from "../../../tools/send.js";

// let isResizeNode = false

class MyGroupNode extends dynamicGroup.view {
  componentDidMount() {
    // super.componentDidMount()

    const { model: curGroup, graphModel } = this.props
    const { eventCenter } = graphModel

    // 在 group 移动时，对组内的所有子节点也进行对应的移动计算
    eventCenter.on('node:mousemove', ({ deltaX, deltaY, data }) => {
      if (data.id === curGroup.id) {
        const { model: curGroup, graphModel } = this.props
        const nodeIds = this.getNodesInGroup(curGroup, graphModel)
        graphModel.moveNodes(nodeIds, deltaX, deltaY, true)
      }
    })

    // 在 group 缩放时，对组内的所有子节点也进行对应的缩放计算
    // eventCenter.on('node:resize', ({ deltaX, deltaY, index, model, preData }) => {
    //   const { transformWithContainer, isRestrict } = this.props.model
    //   if (!transformWithContainer || isRestrict) {
    //     // isRestrict限制模式下，当前model resize时不能小于占地面积
    //     // 由于parent:resize=>child:resize计算复杂，需要根据child:resize的判定结果来递归判断parent能否resize
    //     // 不符合目前 parent:resize成功后emit事件 -> 触发child:resize 的代码交互模式
    //     // 因此isRestrict限制模式下不支持联动(parent:resize=>child:resize)
    //     return
    //   }
    //   if (model.id === curGroup.id) {
    //     console.log(deltaX, deltaY, index)
    //     isResizeNode = true
    //     if (document.getElementById("svgImage_" + curGroup.id)) {
    //       document.getElementById("svgImage_" + curGroup.id).style.pointerEvents = "none";
    //     }
    //     model.setProperties({
    //       ...model.properties,
    //       nodeSize: {
    //         width: model.properties.width,
    //         height: model.properties.height
    //       }
    //     })

    //     const { properties } = preData
    //     const { width: groupWidth, height: groupHeight } = properties || {}
    //     forEach(Array.from(curGroup.children), (childId) => {
    //       const child = graphModel.getNodeModelById(childId)
    //       if (child) {
    //         // 根据比例去控制缩放dx和dy
    //         const childDx = (child.width / groupWidth) * deltaX
    //         const childDy = (child.height / groupHeight) * deltaY

    //         // child.rotate = model.rotate
    //         handleResize({
    //           deltaX: childDx,
    //           deltaY: childDy,
    //           index,
    //           nodeModel: child,
    //           graphModel,
    //           cancelCallback: () => {},
    //         })
    //       }
    //     })
    //     // isResizeNode = true
    //     // return
    //   }
    // })
    
    // eventCenter.on('node:mouseleave', ({ data }) => {
    //   if (data.id === curGroup.id) {
    //     console.log(curGroup.id)
    //     if (isResizeNode) {
    //       if (document.getElementById("svgImage_" + curGroup.id)) {
    //         document.getElementById("svgImage_" + curGroup.id).style.pointerEvents = "auto";
    //       }
    //       let newNodes = [];
    //       let nodeModel = graphModel.getNodeModelById(curGroup.id);
    //       let nodeData = nodeModel.getData();
    //       // console.log(nodeData)
    //       newNodes.push(nodeData);
    //       let newEdges = [];
    //       let anchors = nodeModel.anchors;
    //       let outgoingEdgeModel = graphModel.getNodeOutgoingEdge(curGroup.id);
    //       for (let i = 0; i < outgoingEdgeModel.length; i++) {
    //         for (let j = 0; j < anchors.length; j++) {
    //           if (outgoingEdgeModel[i].sourceAnchorId == anchors[j].id) {
    //             let edge = graphModel.getEdgeModelById(outgoingEdgeModel[i].id);
    //             edge.startPoint.x = anchors[j].x;
    //             edge.startPoint.y = anchors[j].y;
    //             edge = graphModel.getEdgeModelById(outgoingEdgeModel[i].id);
    //             let edgeData = edge.getData();
    //             newEdges.push(edgeData);
    //             break;
    //           }
    //         }
    //       }
    //       let incomingEdgeModel = graphModel.getNodeIncomingEdge(curGroup.id);
    //       for (let i = 0; i < incomingEdgeModel.length; i++) {
    //         for (let j = 0; j < anchors.length; j++) {
    //           if (incomingEdgeModel[i].targetAnchorId == anchors[j].id) {
    //             let edge = graphModel.getEdgeModelById(incomingEdgeModel[i].id);
    //             edge.endPoint.x = anchors[j].x;
    //             edge.endPoint.y = anchors[j].y;
    //             edge = graphModel.getEdgeModelById(incomingEdgeModel[i].id);
    //             let edgeData = edge.getData();
    //             newEdges.push(edgeData);
    //             break;
    //           }
    //         }
    //       }
    //       forEach(Array.from(curGroup.children), (childId) => {
    //         const child = graphModel.getNodeModelById(childId)
    //         if (child) {
    //           let nodeData = child.getData();
    //           // console.log(nodeData)
    //           newNodes.push(nodeData);
    //           let anchors = child.anchors;
    //           let outgoingEdgeModel = graphModel.getNodeOutgoingEdge(childId);
    //           for (let i = 0; i < outgoingEdgeModel.length; i++) {
    //             for (let j = 0; j < anchors.length; j++) {
    //               if (outgoingEdgeModel[i].sourceAnchorId == anchors[j].id) {
    //                 let edge = graphModel.getEdgeModelById(outgoingEdgeModel[i].id);
    //                 edge.startPoint.x = anchors[j].x;
    //                 edge.startPoint.y = anchors[j].y;
    //                 edge = graphModel.getEdgeModelById(outgoingEdgeModel[i].id);
    //                 let edgeData = edge.getData();
    //                 newEdges.push(edgeData);
    //                 break;
    //               }
    //             }
    //           }
    //           let incomingEdgeModel = graphModel.getNodeIncomingEdge(childId);
    //           for (let i = 0; i < incomingEdgeModel.length; i++) {
    //             for (let j = 0; j < anchors.length; j++) {
    //               if (incomingEdgeModel[i].targetAnchorId == anchors[j].id) {
    //                 let edge = graphModel.getEdgeModelById(incomingEdgeModel[i].id);
    //                 edge.endPoint.x = anchors[j].x;
    //                 edge.endPoint.y = anchors[j].y;
    //                 edge = graphModel.getEdgeModelById(incomingEdgeModel[i].id);
    //                 let edgeData = edge.getData();
    //                 newEdges.push(edgeData);
    //                 break;
    //               }
    //             }
    //           }
    //         }
    //       })
    //       sendUpdate(newNodes, newEdges, "update", 0);
    //       isResizeNode = false;
    //     }
    //   }
    // })
  }
  getShape() {
    const { id, x, y, width, height, properties } = this.props.model;
    const style = this.props.model.getNodeStyle();
    const rectAttrs = {
      ...style,
      strokeWidth: 1,
      rx: 5,
      ry: 5,
      x: x - (1 / 2) * width,
      y: y - (1 / 2) * height,
      width,
      height,
    };
    const textAttrs = {
      x: x - (1 / 2) * width + 15,
      y: y - (1 / 2) * height + 20,
      // width: 50,
      // height: 50,
      stroke: "#2995FF",
    };
    if (properties.imageList && properties.imageList.length != 0) {
      const imgAttrs = {
        ...style,
        id: "svgImage_" + id,
        strokeWidth: 1,
        rx: 5,
        ry: 5,
        x: x - (1 / 2) * width,
        y: y - (1 / 2) * height,
        width,
        height,
        href: properties.imageList[0].url,
        preserveAspectRatio: "none meet",
      }
      return h("g", {}, [
        h("image", { ...imgAttrs }),
        h("text", { ...textAttrs }, properties.groupTitle),
      ]);
    }

    // const aRectAttrs = {
    //     width: 18,
    //     height: 12,
    //     rx: 2,
    //     ry: 2,
    //     strokeWidth: 1,
    //     fill: "#F4F5F6",
    //     stroke: "#818281",
    //     cursor: "pointer",
    //     x: x - 1 / 2 * width + 15,
    //     y: y - 1 / 2 * height + 10,
    // }
    // const x1 = x - 1 / 2 * width + 24;
    // const y1 = y - 1 / 2 * height + 16;
    // const pointList = [
    //     [x1 - 1 / 2 * 8, y1 - 1 / 6 * 8],
    //     [x1 - 1 / 6 * 8, y1 - 1 / 6 * 8],
    //     [x1 - 1 / 6 * 8, y1 - 1 / 2 * 8],
    //     [x1 + 1 / 6 * 8, y1 - 1 / 2 * 8],
    //     [x1 + 1 / 6 * 8, y1 - 1 / 6 * 8],
    //     [x1 + 1 / 2 * 8, y1 - 1 / 6 * 8],
    //     [x1 + 1 / 2 * 8, y1 + 1 / 6 * 8],
    //     [x1 + 1 / 6 * 8, y1 + 1 / 6 * 8],
    //     [x1 + 1 / 6 * 8, y1 + 1 / 2 * 8],
    //     [x1 - 1 / 6 * 8, y1 + 1 / 2 * 8],
    //     [x1 - 1 / 6 * 8, y1 + 1 / 6 * 8],
    //     [x1 - 1 / 2 * 8, y1 + 1 / 6 * 8],
    // ]
    // const points = pointList.map(item => {
    //     return `${item[0]},${item[1]}`
    // })
    // const path = {
    //     ...style,
    //     x: x1,
    //     y: y1,
    //     width: 8,
    //     height: 8,
    //     fill: "#818281",
    //     stroke: "#818281",
    //     cursor: "pointer",
    //     strokeWidth: 1,
    //     strokeDasharray: "none",
    //     points: points.join(' '),
    // }
    // const gRectAttrs = {
    //     id: "fold_" + id
    // }
    return h("g", {}, [
      h("rect", { ...rectAttrs }),
      // h('g', { ...gRectAttrs }, [
      //     h('rect', { ...aRectAttrs }),
      //     h('polygon', { ...path },),
      // ]
      // ),
      h("text", { ...textAttrs }, properties.groupTitle),
      // this.getFoldIcon(),
    ]);
  }
}
class MyGroupModel extends dynamicGroup.model {
  initNodeData(data) {
    super.initNodeData(data);
    // console.log(data)
    let children = []
    if (isArray(data.children)) {
      children = data.children
    }
    // 初始化组的子节点
    this.children = new Set(children)
    const width =
      data.properties.nodeSize && data.properties.nodeSize.width
        ? data.properties.nodeSize.width
        : 1000;
    const height =
      data.properties.nodeSize && data.properties.nodeSize.height
        ? data.properties.nodeSize.height
        : 600;
    this.width = width;
    this.height = height;
    this.collapsedWidth = 50;
    this.collapsedHeight = 50;
    this.isGroup = true;
    // this.zIndex = -10000;
    // this.radius = 50;
    this.text.editable = false; // 禁止节点文本编辑
    this.isRestrict = false;
    this.transformWithContainer = true;
    this.resizable = true;
    this.collapsible = true;
    this.gridSize = 1;
    this.minWidth = 100;
    this.minHeight = 100;
    this.autoToFront = false;
    this.isCollapsed = data.properties.isCollapsed ? data.properties.isCollapsed : false;
    if (this.isCollapsed) {
      this.toggleCollapse(true)
    }
    this.properties = data.properties
  }
  setAttributes() {
    if (
      (this.properties.iconRadio == true &&
        this.properties.nodeSize &&
        this.properties.nodeSize.height &&
        parseInt(this.properties.nodeSize.height) <= 100) ||
      (this.properties.iconRadio == true &&
        this.properties.nodeSize &&
        this.properties.nodeSize.width &&
        parseInt(this.properties.nodeSize.width) <= 100)
    ) {
      this.width = 100;
      this.height = 100;
    }
  }

  setToBottom() {
    this.zIndex = 0;
  }

  getNodeStyle() {
    const style = super.getNodeStyle();
    // style.stroke = '#AEAFAE';
    style.fill = "#EBEBEB";
    style.strokeDasharray = "3 3";
    style.strokeWidth = 1;
    return style;
  }

  addChild(id) {
    super.addChild(id);
    let data = this.getData();
    let newNodes = [];
    let newEdges = [];
    newNodes.push(data)
    sendUpdate(newNodes, [], "update", 0);
    let newData = {
      nodes: newNodes,
      edges: newEdges,
      backgroundUrl: localStorage.getItem("background"),
    };
    add_Operation(newData, "updateNode");
  }

  removeChild(id) {
    super.removeChild(id);
    let data = this.getData();
    let newNodes = [];
    let newEdges = [];
    newNodes.push(data)
    sendUpdate(newNodes, [], "update", 0);
    let newData = {
      nodes: newNodes,
      edges: newEdges,
      backgroundUrl: localStorage.getItem("background"),
    };
    add_Operation(newData, "updateNode");
  }
}

export default {
  type: "my-group",
  view: MyGroupNode,
  model: MyGroupModel,
};